/******
 *
 */

// import { Data } from 'react-csv/components/CommonPropTypes'
import { RzLbFileIn } from '../rzcmnui'
import { SettleDashItem } from 'screen/settle/SettleMainScreen'
import { SalesDashItem, WashPodDashItem } from 'screen/sales/SalesMainScreenSac'
import { AuthLevelType, AuthRoleType, UserRoleType } from 'config/type'
import { Data } from 'react-csv/components/CommonPropTypes'

export class CmoFileRef {
  // id?:number;
  id?: string
  type?: string
  name?: string
  size?: number
  refType?: string
  refId?: string

  tgtType?: string
  tgtId?: string

  url?: string
  stat?: string
  fileType?: string

  seq?: string
  //ownerId?: string;
  //ownerName?: string;
}

export enum CmoWashOptEnum {
  WASHER = 'washer',
  AIRGUN = 'airgun',
  MATWASHER = 'matwasher',
  SINK = 'sink',
  LIGHTMNT = 'lightmnt',
  RESTAREA = 'restarea',
  CONVSHOP = 'convshop',
  CAFE = 'cafe',
  SHOP = 'shop',
}

/******
 *
 */

export enum CmoSnsType {
  NAVER = 'NAVER',
  KAKAO = 'KAKAO',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export interface CmoSes {
  username?: string
  token?: string
  refreshToken?: string

  name?: string
  email?: string
  photo?: string
}

export enum UserTypeEnum {
  USER = 'USER',
  PARTNER = 'PARTNER',
  ADMIN = 'ADMIN',
}

export enum UserStatEnum {
  ACTIVE = 'ACTIVE',
  SLEEP = 'SLEEP',
  BLOCK = 'BLOCK',
  PURGED = 'PURGED',
}

// favor
export class BbsFavorIn {
  id?: string
  type?: string
  bid?: string
  code?: string
  aid?: string
  rid?: string
  refType?: string
  refId?: string
  isFavor?: boolean
  ownerId?: string
  ownerName?: string
}

export class CmoRsc {
  ownerId?: string
  ownerName?: string
  createdAt?: Date
  stat?: string
}
/** 사용자  */
export class CmoUserSetting {
  id?: string
  photo?: string

  isNoticeNotiOn?: boolean
  isEventNotiOn?: boolean
  isShopNotiOn?: boolean
  isPayNotiOn?: boolean
  isWashNotiOn?: boolean
  isAdOn?: boolean
  isLocOn?: boolean
}

/** 사용자  */
export class CmoUser extends CmoRsc {
  id?: string
  username?: string
  type?: UserTypeEnum
  name?: string
  password?: string

  email?: string
  phone?: string
  photo?: string

  birthDate?: Date
  addrCode?: string
  addrText?: string
  addrDetail?: string

  shopId?: string
  shopTitle?: string

  regBy?: string

  roleCodes?: string[]

  setting?: CmoUserSetting
  cpns?: CmoCpn[]
  cpnsTotal?: string

  pays?: CmoPay[]
  cashes?: CmoPrepaidCash[]
  cars?: CmoCar[]
  reviews?: CmoBbsArticle[]
  cards?: CmoCreditCard[]
  shop?: CmoShop //

  /* 23.12.05 추가 */
  roles?: UserRoleType
  authRole?: AuthRoleType
  authLevel?: AuthLevelType
  newShops?: CmoShop[]
  companyMail?: string
}

export enum CarNavTypeEnum {
  REGISTER = 'REGISTER',
  MODIFY = 'MODIFY',
}

/** 자동차 정보 */

export enum CarTypeEnum {
  SEDAN = 'sedan',
  JEEP = 'jeep',
  VAN = 'van',
  TAXI = 'taxi',
  MINI = 'mini',
}

export enum CarAttachEnum {
  FRONT_WIPER = 'front-wiper',
  CARRIER = 'carrier',
  MOZEN = 'mozen',
  ROOF_SPOILER = 'roof-spoiler',
  REAR_WIPER = 'rear-wiper',
  REAR_SPOILER = 'rear-spoiler',
  FENDER = 'fender',
  EMBLEM = 'emblem',
  BUMPER_GUARD = 'bumper-guard',
  SIDE_MIRROR = 'side-mirror',
  GAS_CAP = 'gas-cap',
  REAR_TIRE = 'rear-tire',

  REAR_MIRROR = 'rear-mirror',
  WAGON_MIRROR = 'wagon-mirror',
}

export class CmoCar extends CmoRsc {
  id?: string
  regNo?: string
  title?: string
  kind?: CarTypeEnum
  model?: string
  type?: string
  isDefault?: boolean
  opts?: string

  logs?: CmoWash[]
}

export class CmoCarAddOn extends CmoRsc {
  id?: string
  code?: string
  type?: string
  title?: string
  note?: string
}

/***********
 * Login
 */

export class CmoAuthToken {
  username?: string
  type?: UserTypeEnum
  name?: string

  email?: string

  token?: string
  tokenExpiredAt?: Date
  refreshToken?: string

  /* 23.12.5 추가 */
  roles?: UserRoleType
  authRole?: AuthRoleType
  authLevel?: AuthLevelType
}

/***********
 * SignUp
 */
export class CmoAuthSmsToken {
  phone?: string
  phoneToken?: string
}

/* 사용자 등록 */
export class CmoUserReg {
  constructor(pr?: Partial<CmoUserReg>) {
    Object.assign(this, pr)
  }
  username?: string
  type?: UserTypeEnum
  name?: string
  password?: string
  phone?: string
  email?: string
  imageUrl?: string

  coRegNo?: string
  coName?: string
  coReceiptEmail?: string
  coAddrCode?: string
  coAddrPrecede?: string
  coAddrDetail?: string

  coRegDocRefId?: string

  coSize?: string

  snsType?: string
  snsToken?: string
}

/* CmoLoc *********** */
export class CmoLoc {
  id?: string
  code?: string
  type?: string
  title?: string
  note?: string
}

/* Pay : 결제 *********** */
export class CmoPay extends CmoRsc {
  id?: string
  type?: string
  amount?: number
  unit?: string
  //refType?: string;
  //refId?: string;
  itemType?: string
  itemId?: string

  prepaidId?: string
  cardType?: string
  cardName?: string
  cardNo?: string
  cardHolder?: string
  cardTxId?: string
  reason?: string
  casherName?: string
  casherUid?: string
}

/**  PrepaidCash */
export class CmoPrepaidCash extends CmoRsc {
  id?: string
  type?: String
  amount?: number
  usedAmount?: number

  unit?: string
  payAmount?: number
  payUnit?: string
}

/* CreditCard : 신용카드 *********** */
export class CmoCreditCard extends CmoRsc {
  id?: string
  type?: string
  cardCo?: string
  cardNo?: string
  holderName?: string
  authNo?: string
  isDefault?: boolean
}

/* Rsv  예약 *********** */
export class CmoRsv extends CmoRsc {
  id?: string
  type?: string
  kind?: string
  title?: string

  schdBegAt?: Date
  schdEndAt?: Date

  shopId?: string
  shopTitle?: string

  carRegNo?: string

  taskId?: string
  washType?: string
  washOpts?: string

  amount?: number
  unit?: string
  payAmount?: number
  payUnit?: string

  payStat?: string

  cpnId?: string

  task?: CmoWashTask
  isToday?: boolean
  shop?: CmoShop
  car?: CmoCar
  code?: string

  coupon?: CmoCpn
}

/* CpnModel *********** */
export enum CmoCpnEnum {
  SEASON = 'season',
  ONETIME = 'onetime',
  NORMAL = 'normal',
  GIFT = 'gift',
}

export class CmoCpnModel extends CmoRsc {
  id?: string
  title?: string
  type?: string
  code?: string
  shopId?: string

  carType?: string
  amount?: number
  unit?: string

  washCourseId?: string
  washOptId?: string
  note?: string
  washPodModelNo?: string
  washPodModelName?: string
  actStat?: CmoCpnModelStat

  modelName?: string

  maxUsageCount?: number
  // currentUsageCount?: number
}

export class CmoCpnModelStat {
  buyCount?: number
  unusedCount?: number
}

/* Cpn 쿠폰( 정기권, 1회권, 일만쿠폰, 선물) *********** */
export class CmoCpn extends CmoRsc {
  id?: string
  modelId?: string

  code?: string
  type?: string
  title?: string

  usedCount?: number
  validCount?: number
  usedStat?: string

  loc?: string
  locTitle?: string
  shopTitle?: string
  shopId?: string

  carRegNo?: string
  carId?: string

  price?: number
  unit?: string
  payAmount?: number
  payUnit?: string

  washCourse?: string
  washOpts?: string
  nextPayDate?: Date

  note?: string
  pays?: CmoPay[]
  task?: CmoWashTask
  expiredAt?: Date
  cpnExpireDay?: number
}

/* GiftModel *********** */
export class CmoGiftModel extends CmoRsc {
  id?: string

  code?: string
  type?: string
  title?: string
  note?: string
}

/* Gift *********** */
export class CmoGift extends CmoRsc {
  id?: String
  code?: String
  type?: String
  title?: String
  cpnId?: String
  shopId?: String
  shopTitle?: String
  carRegNo?: String
  senderId?: String
  senderName?: String
  receiverPhone?: String
  note?: String
  // id?: string;
  // code?: String;
  // title?: string;
  // shopId?: string;
  // shopTitle?: string;
  // schdBegAt?: Date;
  // schdEndAt?: Date;
  // carId?: string;
  // carRegNo?: string;
  // washType?: string;
  // washOpts?: string;
  // washCourse?: string;
  // amount?: number;
  // unit?: string;
  // payAmount?: number;
  // payUnit?: string;
  // type?: string;

  // tgtType?: string;
  // tgtId?: string;
}

/** 세차장  */
export class CmoShop extends CmoRsc {
  id?: string

  loc?: string
  locTitle?: string
  title?: string

  type?: string

  phone?: string
  imageUrl?: string
  addrText?: string
  addrDetail?: string
  workTime?: string
  realPodCount?: string
  washEquipOpts?: string
  washEquipDetails?: string

  washValidOpts?: string
  washValidDetails?: string

  note?: string

  capa?: number
  score?: number
  scoreCount?: number
  podCount?: number
  inPodCount?: number

  lat?: number
  lng?: number

  myFavor?: boolean
  partner?: CmoUser
  workStat?: CmoShopStat
  reviews?: CmoBbsArticle[]
  equips?: CmoShopEquip[]
  washCourses?: CmoWashCourse[]
  washOpts?: CmoWashOpt[]
  shopInfo?: CmoShopInfo
  workTimes?: CmoWorkTime[]
  files?: CmoFileRef[]
  rsvCount?: number
  rsvDateAt?: Date

  settleSum4Dash?: SettleDashItem
  salesSum4Dash?: SalesDashItem
  washStat4Dash?: WashPodDashItem

  isRecommand?: boolean
  vendorId?: string
}

export class CmoWorkTime extends CmoRsc {
  id?: string
  shopId?: string
  type?: string
  week?: string
  title?: string
  begAt?: Date
  endAt?: Date
}

export class CmoShopStat extends CmoRsc {
  id?: string

  rsvCount?: number
  waitCount?: number
  limitCount?: number
  isWork?: boolean
}
export class CmoShopInfo extends CmoRsc {
  id?: string
  shopId?: string
  coRegNo?: string
  coPresident?: string
  tel?: string
  email?: string
  privacyAdmin?: string
  bizType?: string
  bizItem?: string

  coRegDocId?: string
  coRegDocTitle?: string
  coBankAccImageId?: string
  coBankAccImageTitle?: string
  coBankAccNo?: string
  coBankName?: string
  coBankHolderName?: string

  addrCode?: string
  addrText?: string
  addrDetail?: string
  note?: string

  vanOn?: string
  vanType?: string
  vanName?: string
  vanRegAt?: string

  /** PG 사 - kis, nice */
  pgName?: string

  /** Nice Van ID(오프라인ID) */
  vanMid?: string

  /** Nice PG ID(온라인ID) */
  coCardMid?: string

  /** Nice 비인증 PG ID(정기결제ID) */
  coCardMidNoAuth?: string

  /** 온라인 PG Key */
  vanMerchantKey?: string

  /** 오프라인 PG Key */
  vanMerchantKeyNoAuth?: string

  /** 정기결제 PG Key */
  regularPaymentMerchantKey?: string
}

/**********
 * CmoWashPod : 세차기
 */
export class CmoWashPod extends CmoRsc {
  id?: string
  shopId?: string

  type?: string
  model?: string
  modelName?: string
  title?: string

  authCode?: string
  regNo?: string
  note?: string
  validWashCount?: Number

  shopTitle?: string
  washPodId?: string
  orderType?: string
  orderRefId?: string
  payType?: string
  payId?: string
  payAmount?: number
  payUnit?: string
  paid?: string
  price?: number
  priceUnit?: string
  treatBegAt?: Date
  treatEndAt?: Date
  customerId?: string
  customerName?: string
  carRegNo?: string
  step?: string
  stepTotal?: string

  washType?: string
  washOpts?: CmoWashOpt[]
  washCourse?: string
  washCourses?: CmoWashCourse[]
  washCourseOpts?: string

  carTypes?: string
  carAttaches?: CmoWashOpt[]

  tempTitle?: string
}

export enum CmoWashStatEnum {
  SCHED = 'sched',
  OPEN = 'open',
  CLOSE = 'close',
  CANCEL = 'cancel',
}

/**
 *  CmoWash : 세차 작업
 */
export class CmoWash extends CmoRsc {
  id?: string
  // title?: string;

  // schdAt?: Date;
  shopId?: string
  shopTitle?: string

  washPodId?: string
  washPodTitle?: string
  washPodRegNo?: string

  orderType?: string
  orderRefId?: string
  payType?: string
  payId?: string
  payAmount?: number
  payUnit?: string
  paid?: string
  price?: number
  priceUnit?: string

  schdBegAt?: Date
  schdEndAt?: Date

  treatBegAt?: Date
  treatEndAt?: Date
  customerId?: string
  customerName?: string
  carRegNo?: string
  carId?: string

  step?: string
  stepTotal?: string
  note?: string

  washType?: string //@deprecated
  // washOpts?: string;  //@deprecated
  washCourse?: string //@deprecated
  washCourseOpts?: string //@deprecated

  settleId?: string

  rsv?: CmoRsv
  task?: CmoWashTask
  pays?: CmoPay[]
  car?: CmoCar
  shop?: CmoShop
}

export class CmoAddr {
  addrCode?: string
  addrPrecede?: string
  addrDetail?: string
}

/**********
 * Bbs
 */
export class CmoBbsBoard extends CmoRsc {}

export enum CmoBbsEnum {
  NOTICE = 'notice',
  NEWS = 'news',
  EVENT = 'event',
  FAQ = 'faq',
  QNA = 'qna',
  SHOP = 'shop',
}

export class CmoBbsArticle extends CmoRsc {
  id?: string
  bbsName?: string
  title?: string
  code?: string
  type?: string
  subType?: string
  body?: string
  writerName?: string

  refType?: string
  refId?: string

  score?: number
  likeCount?: number
  replyCount?: number
  myLike?: boolean
  myFavor?: boolean

  replies?: CmoBbsReply[]
  attaches?: CmoBbsAttach[]
  writer?: CmoUser
}

export class CmoBbsReply extends CmoRsc {
  id?: string

  likeCount?: number
  body?: string

  writerName?: string
  bbsName?: string
}

export class CmoBbsAttach extends CmoRsc {
  id?: string
  name?: string
  fileType?: string
  fileRefId?: string
  uri?: string
  srcId?: string
  filename?: string
  refId?: string
  refType?: string
  size?: number
  seq?: string
}

/**********
 * CmoNoti :
 */
export enum CmoNotiEnum {
  NOTICE = 'notice',
  EVENT = 'event',
  RSV = 'rsv',
  PAY = 'pay',
  SHOP = 'shop',
}

export class CmoNoti extends CmoRsc {
  pid?: string
  channels?: string
  id?: string
  code?: string
  type?: string
  subType?: string
  title?: string
  body?: string
  data?: string
  fromType?: string
  fromId?: string
  fromName?: string
  toType?: string
  toId?: string
  toName?: string
  toParam?: string
  refType?: string
  refId?: string
}

/*************
 *
 */

export class CmoWeather {
  date?: Date //날짜
  loc?: string //지역('서울특별시 중구' )
  locDetail?: string // ('용문동')
  degree?: string //온도
  sky?: string //상태 : 구름, 비,...
}

/**********
 * Noti
 */
export class CmoNotiMsg {}

/*********
 *
 */
export class CmoReview {
  id?: string
  bid?: string
  code?: string
  type?: string // washId
  subType?: string //course
  title?: string // shop name
  body?: string
  email?: string
  refType?: string //shop
  refId?: string //shopId
  ownerId?: string
  ownerName?: string
  createdAt?: Date
  createdBy?: string
  stat?: string
  score?: number
  hitCount?: number
  favorCount?: number
  likeCount?: number
  replyCount?: number
  myLike?: boolean
  myFavor?: boolean
  replies?: CmoBbsReply[]
  attaches?: CmoBbsAttach[]
  wash?: CmoWash
  shop?: CmoShop
  writerImageUrl?: String
}

export class CmoShopEquipIn {
  id?: string
  shopId?: string
  type?: string
  code?: string
  title?: string
  qty?: number
  unit?: string
  note?: string
  stat?: string
}

export class CmoShopEquip {
  id?: string
  shopId?: string
  type?: string
  code?: string
  title?: string
  qty?: number
  unit?: string
  note?: string
  stat?: string
}

/****
 *
 */

export class CmoWashCourse extends CmoRsc {
  id?: string
  pid?: string
  code?: string
  shopId?: String
  type?: string

  washPodId?: string
  washPodModel?: string

  title?: string
  note?: string
  price?: number
  childCodes?: string
}

export class CmoWashOpt extends CmoRsc {
  id?: string
  pid?: string
  code?: string

  washPodId?: string
  washPodModel?: string

  shopId?: string
  type?: string
  title?: string
  note?: string
  price?: number
  childCodes?: string
}
/****
 *
 */

export class CmoSettle extends CmoRsc {
  id?: string

  shopId?: String
  type?: string
  title?: string
  note?: string
  issuedAt?: Date
  settledAt?: Date
  rows?: CmoSettleRow[]
}

export class CmoSettleRow {
  id?: string
  type?: string
  shopId?: String
  washId?: String
  shopTitle?: string
  washTitle?: string

  amount?: number
  payAmount?: number
  settledAmount?: number
  settledAt?: Data
  settledStat?: string
  stat?: string
}

/**
 *  CmoWash : 세차 작업
 */
export class CmoWashTask extends CmoRsc {
  id?: string
  refType?: string //cpn, rsv, wash
  refId?: string

  shopId?: string
  shopTitle?: string
  title?: string
  schdBegAt?: Date
  schdEndAt?: Date
  carId?: string
  carType?: string
  carModel?: string //no used
  carTitle?: string
  carRegNo?: string
  carOptCodes?: string

  washType?: string
  washCourseId?: string
  washCourseTitle?: string
  washCoursePrice?: number

  // washOptIds?:string;
  // washOptTitles?:string;
  // washOptPrices?:string;
  washOpts?: CmoWashTaskOpt[]
}

export class CmoWashTaskOpt extends CmoRsc {
  id?: string
  taskId?: string
  washOptId?: string
  washOptTitle?: string
  washOptPrice?: number
}

/*********
 * CmoStat
 */

export class CmoStatEnum {
  static DASH_SHOP_TODAY = 'dash.shop.today'
  static DASH_SHOP_WASH_LOG = 'dash.shop.washLog'
  static DASH_SHOP_SALES_LOG = 'dash.shop.salesLog'
  static DASH_SHOP_REVIEW = 'dash.shop.review'
  static DASH_SHOP_SALES_LOG_MONTH = 'dash.shop.salesLogMonthly'

  static DASH_SALES_SETTLE = 'dash.sales.settle'
  static DASH_SALES_WASH = 'dash.sales.wash'
  static DASH_SETTLE_AMOUNT = 'dash.settle.amount'
}

export class CmoStat {
  id?: string
  shopId?: string
  code?: string
  type?: string
  title?: string
  issuedAt?: Date
  issuedBy?: string
  stat?: string
  rows?: CmoStatRow[]
}

export class CmoStatRow {
  id?: string
  statId?: string
  seq?: number
  kind?: string
  subKind?: string
  loc1?: string
  loc2?: string
  loc3?: string
  title?: string

  valType?: string
  value?: number
  refValue?: string
  stat?: string
}
