export interface RzLogDef {
    d: boolean;
    t: boolean;
    i: boolean;

    setDbg(isDbg: boolean, isTrc?: boolean, isInf?: boolean): void;
}


class RzLogDefImp implements RzLogDef{
    d: boolean;
    t: boolean;
    i: boolean;
    defOns:{[k:string]:boolean|null}={'all':false};

    constructor(isDbg?: boolean, isTrc?: boolean, isInf?: boolean){
        this.d = isDbg || false;
        this.t = isTrc || false;
        this.i = isInf || true;
    }

    setDbg(isDbg?: boolean, isTrc?: boolean, isInf?: boolean){
        this.d = isDbg || false;
        this.t = isTrc || false;
        this.i = isInf || true;
    }

    setDefOn(ty:string, b:boolean){
        this.defOns[ty]=b;
    }

    isDefOn(ty:string):boolean|null{
        let isAll=this.defOns['all'];

        if(isAll!==null ){
            if(isAll) return isAll;
            else return isAll;
        }

        return this.defOns[ty]
    }   
}


class RzLog {

    trace(fmt: string,...args: any[]) {
        console.log("RZTRC: "+fmt, ...args);
    }
    debug(fmt: string, ...args: any[]){
        console.log("RZDBG: "+fmt, ...args);
    }
    info(fmt: string,...args: any[]) {
        console.log("RZINF: "+fmt, ...args);
    }
    error(fmt: string,...args: any[]){
        console.error("RZERR: "+fmt, ...args);
    }

    makeDefs(isDbg?: boolean, isTrc?: boolean, isInf?: boolean) {
        const dbg = new RzLogDefImp(isDbg, isTrc, isInf);
        return dbg;
    }
}

/**************/
var _isRzLogEnabled=true;

export const setRzLogEnabled=(b:boolean)=>{
  _isRzLogEnabled=b;
  _initRzLog(b)
}

function makeDefs(isDbg?: boolean, isTrc?: boolean, isInf?: boolean) {
    const dbg = new RzLogDefImp(isDbg, isTrc, isInf);
    return dbg;
}
  
const _initRzLog=(isValid:boolean)=>{
    if(isValid){
        //globalThis.debug=window.console.log.bind(window.console, 'RZDBG:');
      (window as any)['debug']=window.console.log.bind(window.console, 'RZDBG:');
      (window as any)['info']=window.console.log.bind(window.console, 'RZINF:');
      (window as any)['error']=window.console.log.bind(window.console, 'RZERR:');
      (window as any)['makeDefs']=makeDefs
    } else {
        (window as any)['debug']=()=>{}; 
        (window as any)['info']=()=>{};
        (window as any)['error']=()=>{};
        (window as any)['makeDefs']=makeDefs;
    }
}

const isWindowLog=false;
const _getRzLog=()=>{
    if(isWindowLog) {
        _initRzLog(_isRzLogEnabled)
        return window;
    }

    return new RzLog();
 }
  
/**********************/
  export const rzlog =  new RzLog();

//export const rzlog = 
