import { Component, createRef } from "react";
import { rzlog } from "./inc";
import { RzViewProp } from "./rzview";

const rzIs=rzlog.makeDefs()

/*****************************
 * 
 */
export type RzChartType='bar'|'line'|'5angle'|'box'|'circle'|'10angle'


export interface RzChartOpt {
    title?:string;
    rules?:any[];
}

export interface RzChartProp extends RzViewProp{
    type?:RzChartType
    
    xoff?:number;
    yoff?:number;

    width?:number;
    height?:number;
    paddingRight?:number;


    option?:RzChartOpt;
    items?:any[];
    items2?:any[];

    selectedId?:string;
    selectedStyle?:any;
}

export class RzChart extends Component<RzChartProp> {

    canvasRef= createRef<HTMLCanvasElement>()
    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
        this.renderChart()
    }
    componentDidMount(): void {
        this.renderChart()
    }

    renderChart(){

        if(!this.canvasRef.current) return;

        let canvas=this.canvasRef.current;

        //rzlog.debug('=2d================: type=',this.props.type)
        let ctx2d=canvas.getContext('2d')
        if(!ctx2d) return;
        let w=this.props?.width||1000
        let h=this.props?.height||260        
        let g=this.newDrawer(ctx2d,this.props.type);

        g.setItems( this.props.items||[]);
        if(this.props.items2)g.setItem2s(this.props.items2);
        g.setChartProp(this.props);
        g.setOff(this.props.xoff||0,this.props.yoff||0);
        g.setSize(w,h)
        g.setPadding(0,this.props.paddingRight||0)
        g.draw();
    }

    newDrawer(ctx:CanvasRenderingContext2D,type?:RzChartType,items?:any[]):RzChartDraw{

        if(!type) return new RzChartDraw(ctx);
        else if(type==='line') return new RzLineChartDraw(ctx);
        else if(type==='5angle') return new RzAngleChartDraw(ctx);
        else if(type==='10angle') return new RzAngleChartDraw(ctx,10);
        else if(type==='box') return new RzBoxPropChartDraw(ctx);
        else if(type==='circle') return new RzCircleChartDraw(ctx);
        

        return new RzChartDraw(ctx);
    }



    render(): React.ReactNode {
        let w=this.props?.width||970
        let h=this.props?.height||260     
        return (<div style={{width:w, height:h}}>
                <canvas ref={this.canvasRef} width={w} height={h}/>
            </div>
        )
    }
}//class

/***************************
 * 
 */
class RzChartDraw {
    orgX=0
    orgY=0;
 
    w=970
    h=260
    bgColor='#F6F6F6'
    bgOutColor='#ffffff'
    btmHeight=50
    outLineClr='#C4C4C4'
    ctx:CanvasRenderingContext2D;
    items?:any[]
    
    items2?:any[]
    chartProp?: RzChartProp;
    paddingRight=0
    paddingLeft=0;

    constructor(ctx:CanvasRenderingContext2D){
        this.ctx=ctx
    }

    setChartProp(prop:RzChartProp){
        this.chartProp=prop;
    }

    setItems(items:any[]){
        this.items=items;
    }
    setItem2s(items:any[]){
        this.items2=items;
    }
    setOff(xoff:number,yoff :number){
        this.orgX=xoff;
        this.orgY=yoff;
    }

    setSize(w:number,h :number){
        this.w=w;
        this.h=h;
    }

    padRight=0
    padLeft=0

    setPadding(lpad:number, rpad:number){
        this.padRight=rpad;
        this.padLeft=lpad;
    }

    draw(){
            this.drawChartBox();
            this.drawTitles();
            this.drawCharts();
            if(this.items2)this.drawCharts2();
    }


    drawChartBox(){
        let {w,h}=this.calcRect();
        
        this.fillRect(0,0,w,h,this.bgColor);                // chart-box
        this.fillRect(0,h,w,this.btmHeight,this.bgOutColor);// bottom-axis-box
        this.drawRect(0,0,w,this.h,this.outLineClr);        // outbox
        this.drawLine(1,h, w,h,this.outLineClr)             // bottom-axis


        let dh= (h)/3;
        let th=dh;
        this.drawLine(1,dh, w-2,dh,this.outLineClr)
        dh+=th;
        this.drawLine(1,dh, w-2,dh,this.outLineClr)
        dh+=th;
        this.drawLine(1,dh, w-2,dh,this.outLineClr)

    }

    defaultWidth=20
    xAxisClr='#444444'
    yAxisClr='#444444'
    yAxisUnits=3;
    yAxisUnit=1;
    getItems(){ return this.items || [{value:10},{value:120},{value:40},{value:120},{value:120},{value:110},{value:70}]}
    getItems2(){ return this.items2 || [{value:10},{value:120},{value:40},{value:120},{value:120},{value:110},{value:70}]}

    drawTitles(){
        let items= this.getItems()
        let maxY=0;
        items.forEach(t=> { if(t.value>maxY) maxY=t.value;})
        let dh=(maxY*1.2)/this.yAxisUnits;
        this.yAxisUnit=dh;

        this.drawXAxis(items);
        this.drawYAxis(items)
    }

    drawXAxis(items:any[]){
        let dw=this.defaultWidth;
        items.forEach((t,i)=>{
            this.drawTitle(t,i,items.length,dw)
        })
    }

    drawYAxis(items:any[]){
        let {w,h}=this.calcRect()
        let dh=h/this.yAxisUnits
        
        for(let i=0;i < this.yAxisUnits;i++){
            this.drawLine(w,dh*i,w+10,dh*i, '#444444')
            this.fillRect(w+8,dh*i-2,22,22,'#ffffff');
            this.drawText(w+10,dh*i+2,""+((this.yAxisUnits - i)*this.yAxisUnit).toFixed(),this.xAxisClr,6);
        }
    }

    fontSize=9
    titleXOff= 0
    drawTitle(t:any,i:number,len:number,dw:number){
        let {w,h}=this.calcRect();

        if(rzIs.d)rzlog.debug('DRAW: fontsz=',this.fontSize,',titlexOff=',this.titleXOff, ', orgX=',this.orgX)
        let xoff=this.calcXPos(i,len,dw);
        let fontOff=  -((t.title.length*this.fontSize/4)); //this.titleXOff+this.paddingLeft;//-((t.title.length*this.fontSize/4)) +this.titleXOff

        this.ctx.strokeStyle=this.xAxisClr
        this.ctx.font = this.fontSize+"px";
        
        this.ctx.strokeText(t.title,this.orgX+ xoff+fontOff, this.orgY+h+18);
    }

    drawCharts(){
        let items = this.getItems()
       
        let {max, min}=this.calcMinMax(items);

        items.forEach((t,i)=>{
            if(  this.chartProp?.selectedId && t.title === this.chartProp?.selectedId ){
                let clr=this.chartProp.selectedStyle;
                //rzlog.debug("CHART1: clr=",clr)
                this.drawBar(t,i,items.length,max,min||0,clr); 
                return;
            }
            //rzlog.debug("CHART0: clr=",t,', pr=',this.chartProp);
            this.drawBar(t,i,items.length,max,min||0); 
        })
    }

    drawCharts2(){
        let items = this.getItems2();
       
        let {max, min}=this.calcMinMax(items);

        items.forEach((t,i)=>{ this.drawBar(t,i,items.length,max,min||0); })
    }

    calcSum(items:any[],len?:number){
        let tot=0
        items.forEach((t,i)=> { if(len && i>=len) return ;
                                else  tot=tot+t.value; })
        return tot
    }
    
    calcRect(){
        let w=this.w-this.padRight-this.orgX;
        let h=this.h-this.btmHeight-this.orgY;

        return {w:w,h:h}
    }

    calcMinMax(items:any[]){
        let tmax=0;
        let tmin:number|null=null;
        items.forEach(t=> {  
            if(t.value>tmax) tmax=t.value;
            if(tmin==null || t.value<tmin) tmin=t.value;
        })
        return {max:tmax, min:tmin}
    }

    calcPos(v:number, i:number, tot:number,tmx:number, tmn:number,tdw:number):Pos{
        let {w,h}=this.calcRect();

        let xoff=this.calcXPos(i,tot,tdw)

        let th = h;
        let vh= tmx;//-tmn;
        let dh= (th*0.8)/vh;
        let tv=dh*v
        let y0= th;

        let y=y0 - tv
        return {y:y,x:xoff , transVal:tv};
    }

    calcXPos(i:number, tot:number,tdw:number){
        let {w,h}=this.calcRect();

        let dw=tdw;
        let tw=(tot)*dw;
        let left=(w-tw)  // [ .... | left ] 

        let doff=left/(tot+1)
        let xoff=doff + i*(doff+dw);
        return xoff;
    }

    drawBar(it:any, i:number, tot:number,tmx:number, tmn:number,clr?:string){
        let dw=this.defaultWidth;

        let tp=this.calcPos(it.value, i, tot,tmx,tmn,dw);
        this.drawRect(tp.x, tp.y, dw, tp.transVal-1 ,clr||'#75BDFF',clr||'#75BDFF' )
    }

    drawLabels(){
        
    }

    drawLine( x:number,y:number,x2:number, y2:number,clr:string){
        this.ctx.strokeStyle=clr;
        this.ctx.lineWidth=1
        this.ctx.beginPath()
        this.ctx.moveTo(this.orgX+x,this.orgY+y);
        this.ctx.lineTo(this.orgX+x2,this.orgY+y2)
        this.ctx.closePath();
        this.ctx.stroke();
    }	

    drawCircleDot( x:number,y:number,w:number,clr:string){
        this.ctx.strokeStyle=clr;
        this.ctx.lineWidth=1
        this.ctx.beginPath()

        // 4. 원 모양 설정
        this.ctx.arc(this.orgX+x, this.orgY+y, w, 0, 2*Math.PI);
        
        // 5. 그리기
        this.ctx.stroke();

        // 6. 원 내부 색 채우기
        this.ctx.fillStyle = clr
        this.ctx.fill();        
        this.ctx.closePath();
        
    }	


    fillRect(x:number,y:number, w:number,h:number,clr:string){
        this.ctx.fillStyle=clr
        this.ctx.fillRect(this.orgX+x,this.orgY+y, w,h)
        this.ctx.fill();
    }

    drawRect(x:number,y:number, w:number,h:number,clr:string,fillClr?:string){
        if(fillClr){
            this.fillRect(x,y,w,h,fillClr);
        }

        this.ctx.strokeStyle=clr
        this.ctx.strokeRect(this.orgX+x,this.orgY+y, w,h)
        this.ctx.stroke();
    }

    drawText(x:number,y:number, txt:string,style:string,sz?:number){
            this.ctx.strokeStyle=style ;
        this.ctx.font = (sz||this.fontSize)+"px";
        
        this.ctx.strokeText(txt,this.orgX+ x, this.orgY+y+18);
     }
}//


interface Pos {
    x:number;
    y:number;
    transVal:number;
}
/*******************
 * 
 */
class RzLineChartDraw  extends RzChartDraw {
    constructor(ctx:CanvasRenderingContext2D){
        super(ctx);
        //this.defaultWidth=90;
    }


    drawCharts(){
        let items=this.items || [{value:10},{value:120},{value:40},{value:120},{value:120},{value:110},{value:70}]
     

        let {max, min}=this.calcMinMax(items);


        let points:Pos[]=[]
        items.forEach((t,i)=>{
            let tp=this.calcPos(t.value, i, items.length,max,min||0,2);
            points.push(tp)
        })

        let penWd=this.ctx.lineWidth
        let endP2:Pos|null=null;
        this.ctx.lineWidth=4
        let len=points.length
        points.forEach((tp,i)=>{    
            if(i+1>=points.length) {
                let ttp=points[i];
                this.drawCircleDot(ttp.x,ttp.y,5, '#EE9E52')
                return;
            }
            let p2=points[i+1];

        
            this.drawLine(tp.x,tp.y,p2.x,p2.y,'#EE9E52');
            this.drawCircleDot(tp.x,tp.y,5, '#EE9E52')
        })

        
        this.ctx.lineWidth=penWd;

    }

    drawCharts2(){
        let items=this.items2 ||[]////|| [{value:10},{value:120},{value:40},{value:120},{value:120},{value:110},{value:70}]
     

        let {max, min}=this.calcMinMax(items);


        let points:Pos[]=[]
        items.forEach((t,i)=>{
            let tp=this.calcPos(t.value, i, items.length,max,min||0,2);
            points.push(tp)
        })

        let penWd=this.ctx.lineWidth
        let endP2:Pos|null=null;
        this.ctx.lineWidth=4
        let len=points.length
        points.forEach((tp,i)=>{    
            if(i+1>=points.length) {
                let ttp=points[i];
                this.drawCircleDot(ttp.x,ttp.y,5, '#AE9E52')
                return;
            }
            let p2=points[i+1];

        
            this.drawLine(tp.x,tp.y,p2.x,p2.y,'#AE9E52');
            this.drawCircleDot(tp.x,tp.y,5, '#AE9E52')
        })

        
        this.ctx.lineWidth=penWd;

    }

    
    drawLineGraph(it:any, i:number, total:number, tmax:number, tmin:number): void {
    }

}//class


/*******************
 * 
 */
 class RzAngleChartDraw  extends RzChartDraw {
    constructor(ctx:CanvasRenderingContext2D,angleCount?:number){
        super(ctx);
        this.bgColor='white'
        this.btmHeight=0;
        this.angleCount=angleCount||5;
    }

    centerX=100;
    centerY=10;
    angleCount=5;
    levelCount=6;

    setAngleCount(agc:number){
        this.angleCount=agc;
    }
    
    calcCenter(){      
          let {w,h}=this.calcRect();
        let x=this.centerX;
        let y=this.centerY;
        let th=h*0.8
        let tw=w*0.8
        let wd=tw;
        let lvCnt=this.levelCount
       

        if(th>tw) {
            x= (w-tw)/2  - wd/2 ; 
            y=(h-tw)/2 - wd/2;
        } else {
            wd=th;
            x=  (w-th)/2 +th/2;
            y=  (h-th)/2+th/2;
        }
        let dh=wd/(2*lvCnt)

        return {x:x,y:y,dh:dh}
    }

    drawChartBox(){
        let {w,h}=this.calcRect();
    
        let {x,y,dh}=this.calcCenter()

        this.fillRect(0,0,w,h,this.bgColor);   


        let ag=this.angleCount
        let agv=ag+1
        let tv=dh
        let vs=new Array(agv).fill(tv) 
        this.drawAngle(x,y,vs,'lightgray',ag)
        
        for(let i=1;i<this.levelCount;i++){
            tv+=dh
            vs=new Array(agv).fill(tv) 
            this.drawAngle(x,y,vs,'lightgray',ag)
        }
    }  


    drawTitles(){

    }


    drawCharts(){
        let items=this.items || [];

        //[{value:10},{value:120},{value:40},{value:120},{value:120},{value:110}, {value:110},{value:110},{value:110} ,{value:110}]

        let {max}=this.calcMinMax(items)
        
        let {x,y,dh}=this.calcCenter()
        let dv=max/dh;
        let vs:number[]=[]
        items.forEach(t=> vs.push(t.value*dv))
        vs.push(vs[0]);

        this.drawAngle(x,y,vs,'orange',this.angleCount);
    }




    drawAngle(cx:number, cy:number,  vals:number[],clr?:string, dw?:number){
        var numberOfSides = dw||5;

        let tv=vals[0]
        this.ctx.strokeStyle=clr||'black'
        this.ctx.translate(cx,cy)
        
        
        this.ctx.rotate(54 * Math.PI / 180);
        let x=   vals[0] * Math.cos(0);//1
        let y=   vals[0] * Math.sin(0) //0
    
        //this.ctx.strokeRect(0,0,cx,cy)
        //this.ctx.strokeRect(0,0,5,5)
        
        this.ctx.beginPath();
        
        this.ctx.moveTo (x, y);          
      
        let clrs=['green','red','yellow','blue','orange']
        for (var i = 1; i <= numberOfSides;i += 1) {
            x=  vals[i] * Math.cos(i * 2 * Math.PI / numberOfSides);
            y= vals[i] * Math.sin(i * 2 * Math.PI / numberOfSides);
            this.ctx.lineTo (x,y );
        }

        this.ctx.lineWidth = 1;
        
        this.ctx.stroke();
        this.ctx.rotate( -(54 * Math.PI / 180));
        this.ctx.translate(-cx,-cy)
 
    }


}//class



/***
 * 
 */

class RzBoxPropChartDraw  extends RzChartDraw {
    constructor(ctx:CanvasRenderingContext2D){
        super(ctx);
        this.bgColor='white'
        this.btmHeight=0;
    }

    centerX=100;
    centerY=10;
    
    drawChartBox(){
        let {w,h}=this.calcRect();

        this.drawRect(0,0,w,h,'#ACACAC')
    }  
 
    drawTitles(){
    }



    drawCharts(){
        let items=this.items || [{value:10},{value:120},{value:40},{value:120},{value:120},{value:110} ]

        let total=this.calcSum(items,4)
        let gap=5
        let {w,h}=this.calcRect()
        let gmax=(w-gap*(items.length-1))*0.8;
        let dv= gmax/total
        let vs:number[]=[]
        items.forEach(t=> vs.push((t.value/total)*gmax))
 
        let clrs=['#3F8BC7','#75BDFF','#84AEDC','#B5D1EF',]
        //let clrs=['#0051B1','#1A66C0','#1A66C0','#347ACD','#5B96DC','#94BBEA','#94BBEA']
        let pv=0;
        //for(let i=0; i<vs.length;i++){
        for(let i=0; i<4;i++){
            let tg=(i>0)?gap:0

            this.drawBox(pv+tg,vs[i],clrs[i%clrs.length]);
            pv+=vs[i]+tg;
        }
    }


    drawBox( x:number, dw:number,clr?:string){
        let {w,h}=this.calcRect()

        let x0= (w-w*0.8)/2
        let th= h*0.6;
        let y0= (h-th)/2;
        this.drawRect(x0+x, y0,dw,th,clr||'blue',clr)
    }



}//class


/***********
 * 
 */

class RzCircleChartDraw  extends RzChartDraw {
    constructor(ctx:CanvasRenderingContext2D){
        super(ctx);
        this.bgColor='white'
        this.btmHeight=0;
    }

    centerX=160;
    centerY=160;
    circleRadius=75;
    drawChartBox(){
        let {w,h}=this.calcRect();
        this.ctx.beginPath (); 
        this.ctx.lineWidth=2;
        this.drawRect(0,0,w-1,h,'#ACACAC')
        this.ctx.closePath (); 
    }  
 
    drawTitles(){
    }



    drawCharts(){
        //let items=this.items || [{value:10},{value:120},{value:40},{value:120},{value:120},{value:110} ]
        let items= this.items||[{value:100} ]
        //let items= [{value:120},{value:20} ]

        let total=this.calcSum(items)
 
 
        let vs:number[]=[]
        items.forEach(t=> vs.push((t.value/total)*6.3 ))
 
        let clrs=['#373737','#00A5AF','#1A66C0','#347ACD','#5B96DC','#94BBEA','#94BBEA']
        let pv=0;
         
        for(let i=0; i<vs.length;i++){
          
            this.drawCircle(pv,vs[i],clrs[i%clrs.length]);
            pv+=vs[i];
        }
       
    }


    drawCircle( stx:number, dw:number,clr?:string){
        let {w,h}=this.calcRect()
        
        let cw=this.circleRadius;
        let x= (w- cw*2 )/2 + cw;
        let y= (h-h*0.2)/2 ;

      // this.ctx.save()
    
        var startAngle=stx;
        var endAngle=stx+dw;

      this.ctx.beginPath (); //빈경로 새롭게 시작
        //(100,100) 중심 , 반지름 20 , 3시 기준 , 90˚ 각도 , 시계방향
        this.ctx.arc (x , y ,cw, startAngle ,  endAngle, false );
            
        this.ctx.strokeStyle = clr ||'blue'; //선 색을 red로 지정
        this.ctx.lineWidth=20

   
        this.ctx.stroke (); //context의 경로에 있는 도형 그리기
        // this.ctx.fill();
        // 

        this.ctx.closePath();

    }



}//class
