import React, { Component } from 'react'
// import {View, Text, ViewStyle, TextStyle, StyleProp, Button,
//     Image, ImageSourcePropType, ImageStyle, TouchableNativeFeedback,
//     TouchableWithoutFeedbackProps, TouchableOpacity, TouchableHighlight } from "react-native";

import {
  View,
  ViewStyle,
  Text,
  TextStyle,
  Image,
  Button,
  TouchableOpacity,
  ImageSourcePropType,
  ImageStyle,
} from './react-components'

import { IC_CLOSE } from '../assets/'
import { rzlog } from '../rzcmn/rzlog'
import {
  RzBasMCmp,
  RzMCmpProp,
  RzTMCmp,
  RzLbCmp,
  RzMCmp,
  RzUiSize,
} from './rzcmp'
import { RzImg } from './rzimg'
import { toStyles } from './rzstyles'
import { RzLbTxt, RzTxt } from './rztxt'
import { RzCol, RzRow, RzView } from './rzview'
import { rzHasDisp } from './inc'
/***************/
const rzIs = rzlog.makeDefs()
/***************
 *
 */
interface RzTxtProp {
  style?: ViewStyle
  textStyle?: TextStyle
  text?: string
  inactive?: boolean
  disabled?: boolean
  className?: string
}

export interface RzBtnProp extends RzTxtProp {
  size?: RzUiSize
  underline?: boolean
  outline?: boolean
  fill?: boolean
  type?: string
  classBtn?: string

  //fill?:boolean;

  inStyle?: ViewStyle
  outStyle?: ViewStyle
  headIcon?: string | Image | ImageSourcePropType
  icon?: string | Image | ImageSourcePropType
  iconStyle?: ImageStyle

  tailIcon?: string | Image | ImageSourcePropType
  tailIconStyle?: ImageStyle
  tailIconPressable?: boolean
  onPress?: () => void
}

export class RzBtn extends Component<RzBtnProp> {
  constructor(pr: any) {
    super(pr)
  }

  public render(): React.ReactNode {
    if (rzIs.d)
      rzlog.debug(
        'RzBtn.pros.style=',
        this.props.style,
        ',textStyle=',
        this.props.textStyle,
      )
    //let outStyle=this.props.outStyle || {flexDirection:'row',borderWidth:0} as  StyleProp<ViewStyle>;
    // let infillSt = { width: '100%' } as ViewStyle
    
    let infillSt = { width: '100%' } as ViewStyle
    if (this.props.fill === false) {
      infillSt = { } as ViewStyle
    }
    let fillSt = {} as ViewStyle

    if (this.props.icon) {
      infillSt = {
        ...infillSt,
        flexDirection: 'row',
        alignItems: 'center',
        ...(this.props.inStyle ? this.props.inStyle : {}),
      }
    }

    if (this.props.fill === true) {
      fillSt = { ...fillSt, width: '100%' }
    }

    if (this.props.headIcon || this.props.tailIcon) {
      fillSt = { ...fillSt, flexDirection: 'row' }
    }
    let basAttr: ViewStyle = rzHasDisp
      ? { display: 'flex', flexDirection: 'row' }
      : {}

    let st = { ...fillSt, ...basAttr }
    if (
      this.props.style &&
      (this.props.style as any[]).length &&
      (this.props.style as any[]).length > 0
    ) {
      let sts: ViewStyle[] = this.props.style as ViewStyle[]
      sts.forEach((t) => (st = { ...st, ...t }))
      //st={...st,  ...this.props.style[0],...this.props.style[1]}
    } else if (this.props.style) st = { ...st, ...this.props.style }

    if (st.flexBasis !== undefined) delete st.flexBasis
    if (st.flexShrink !== undefined) delete st.flexShrink

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSty: TextStyle = { ...defAttr, ...this.props.textStyle }
    if (this.props.underline === true) txtSty.textDecorationLine = 'underline'

    if (rzIs.d) rzlog.debug('RzRndBtn:tx=', this.props.text, ',sty=', st)
    //{...st,width:'100%', justifyItems:'center'}
    return (
      <RzView style={st} className={this.props.className}>
        {this.props.headIcon && (
          <Image source={this.props.headIcon as ImageSourcePropType} />
        )}

        <TouchableOpacity
          onPress={() => {
            if (this.props.onPress && this.props.inactive !== true)
              this.props.onPress()
          }}
          style={{ ...infillSt }}
        >
          <RzRow style={{ justifyContent: 'center' }}>
            {this.props.icon && (
              <Image
                style={this.props.iconStyle}
                source={this.props.icon as ImageSourcePropType}
              />
            )}
            <Text style={txtSty}>{this.props.text}</Text>

            {Boolean(this.props.tailIconPressable) && this.props.tailIcon && (
              <Image
                style={this.props.tailIconStyle}
                source={this.props.tailIcon as ImageSourcePropType}
              />
            )}
          </RzRow>
        </TouchableOpacity>

        {!Boolean(this.props.tailIconPressable) && this.props.tailIcon && (
          <Image
            style={this.props.tailIconStyle}
            source={this.props.tailIcon as ImageSourcePropType}
          />
        )}
      </RzView>
    )
  }
}

export interface RzLbBtnProp extends RzMCmpProp {
  fill?: boolean
}

export class RzLbBtn extends RzTMCmp<RzLbBtnProp> {
  constructor(pr: any) {
    super(pr)
  }
  protected isPressOff(): boolean {
    return false
  }
  protected doRenderBody(): React.ReactNode {
    let textStyle = this.props.textStyle || ({} as TextStyle)
    return (
      <View style={this.props.bodyStyle}>
        <RzTxt textStyle={textStyle}>{this.props.text}</RzTxt>
      </View>
    )
  }
}

export class RzRndBtn extends Component<RzBtnProp> {
  constructor(pr: any) {
    super(pr)
  }
  public render(): React.ReactNode {
    return <Button title={this.props.text!} />
  }
}

export class RzSlotBtn extends Component<RzBtnProp> {
  constructor(pr: any) {
    super(pr)
  }
  public render(): React.ReactNode {
    return (
      <TouchableOpacity>
        <View></View>
      </TouchableOpacity>
    )
  }
}

export class RzFlatBtn extends Component<RzBtnProp> {
  constructor(pr: any) {
    super(pr)
  }
  public render(): React.ReactNode {
    return (
      <TouchableOpacity>
        <View></View>
      </TouchableOpacity>
    )
  }
}

/****************
 *
 */
const st_btns_box = toStyles(`
 flex:1; justify-content:space-around;
 alignItems:center;
 border-width:1px;
 border-color: #D5DBE7;
 border-radius: 10px;
 height:50px;
 `)

interface RzBtnDef {
  onPress?: () => void
  icon: any
  iconStyle?: any
  text: string
  textStyle?: TextStyle
}

interface Rz3BtnsProps {
  buttons: RzBtnDef[]
  // btn2:RzBtnDef;
  // btn3:RzBtnDef;
}

export class RzBtns extends Component<Rz3BtnsProps> {
  constructor(pr: any) {
    super(pr)
  }

  public render(): React.ReactNode {
    const st_btnIcon = { marginRight: 1, marginTop: 8 }
    const lastIx = this.props.buttons.length - 1

    //st_btnIcon
    return (
      <RzRow style={st_btns_box}>
        {this.props.buttons.map((t, ix) => (
          <>
            <RzView
              style={{
                height: '100%',
                borderColor: '#D5DBE7',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <RzLbTxt
                text={t.text}
                textStyle={t.textStyle}
                icon={t.icon}
                iconStyle={t.iconStyle || st_btnIcon}
              />
            </RzView>
            {ix !== lastIx && (
              <RzView
                style={{
                  height: '100%',
                  width: 1,
                  alignItems: 'center',
                  borderRightWidth: 1,
                  borderColor: '#D5DBE7',
                  justifyContent: 'center',
                }}
              />
            )}
          </>
        ))}
      </RzRow>
    )
  }
}

/*******************
 *
 */

const st_img_box = toStyles(`
 justify-content:center;
 align-items:center;
 height:80px;
 width:80px;
 border-width:1px;
 border-color:#CCCCCC;
 border-radius:5px;`)

/************* */
interface RzImagBtnProp {
  style?: ViewStyle
  imgStyle?: ImageStyle
  src?: any
  badgeOn?: boolean
  onPress?: () => void
  onBadgePress?: () => void
}

export class RzImgBtn extends Component<RzImagBtnProp> {
  constructor(pr: any) {
    super(pr)
  }

  public render(): React.ReactNode {
    // <RzCol style={st_img_box} >
    // 	<RzImg src={IC_BGPIC} />

    return (
      <TouchableOpacity onPress={this.props.onPress}>
        <RzCol style={{ ...st_img_box, ...this.props.style }}>
          <RzImg src={this.props.src} style={this.props.imgStyle} />
        </RzCol>
        {this.props.badgeOn && (
          <RzView style={{ position: 'absolute', top: 4, right: 4 }}>
            <TouchableOpacity onPress={this.props.onBadgePress}>
              <RzImg src={IC_CLOSE} style={{ width: 16, height: 16 }} />
            </TouchableOpacity>
          </RzView>
        )}
      </TouchableOpacity>
    )
  }
}
