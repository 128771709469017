
import { NewFail, NewOk, RzBoolRes, rzlog } from "./inc";
 
const rzIs=rzlog.makeDefs();
export const rzUiValid_setDbg=rzIs.setDbg;


export interface RzValidRule {
    required?:boolean;
    min?:number;
    max?:number;
    regEx?:RegExp;

    errNoData?:string;
    errNoMatch?:string;
    errOverLen?:string;
    errUnderLen?:string;   
}

export interface RzValidStat{
    rule:RzValidRule;
    valid:boolean;
    errCode?:string;
    err?:string;
    ok?:string;
    extra?:any;

    isSKipEmptyErr?:boolean;
    isEmptyOk?:boolean;
}

export interface RzFormValids {
	
	formValidAll?:RzValidStat;
}

export interface RzOnValidEvent {
    valids:RzFormValids, state:any, isValid:boolean, val?:string
}

//export type  RzOnValidEnd=( e:RzOnValidEvent)=>boolean
export type  RzOnValidEnd=( valid:RzValidStat ,e:RzOnValidEvent)=>RzValidStat

export interface RzFormField {
	rule?:RzValidRule;
    extra?:any;
    onValidEnd?:RzOnValidEnd;
    onFormEnd?:RzOnValidEnd;
    
}

/************** */
export type RzFormFieldsDef = {[id:string]: RzFormField}

/************** */

/************** */


export function rz_newValidStat<T>(pr:Partial<T>):T {
	let r=Object.assign({valid:true, isSKipEmptyErr:true,err:'' ,ok:''},pr) as T;
    if(rzIs.d) rzlog.debug('rz_newValidStat : r=',r,',pr=',pr);
	return r;
}

export const rz_isEmpty=(v?:any)=>{
    if(v===undefined || v===null )return true;
    if('string' === typeof v && v ==='') return true; 
    return false;
}

export const ERR_NO_DATA='ERR_NO_DATA'
export const ERR_NO_MATCH='ERR_NO_MATCH'
export const ERR_LEN_OVER='ERR_LEN_OVER'
export const ERR_LEN_UNDER='ERR_LEN_UNDER'

export const ERR_PWD_NO_EQUAL='ERR_PWD_NO_EQUAL'

const LEN_MIN=0
const LEN_MAX=100

export const rz_validEmptyAndLen=(def:RzValidStat, v?:string)=>{
    let err='';
    if(def.isSKipEmptyErr){
        if(v===undefined || v===null)	return {...def, valid:false,err:err,isEmptyOk:true };

    } else if(v===undefined || v===null|| v.length===0) return {...def, valid:false, errCode:ERR_NO_DATA, err:def?.rule.errNoData};
    let min=def.rule?.min||LEN_MIN
    let max=def.rule?.max||LEN_MAX
    if(v==null ||v.length < min )return {...def, valid:false,errCode:ERR_LEN_UNDER,  err:def?.rule.errUnderLen||`${min}자 미만입니다.`};
    if(v==null ||v.length > max )return {...def, valid:false,errCode:ERR_LEN_OVER,  err:def?.rule.errOverLen||`${max}자 이상입니다.`};

    return   {...def, valid:true,err:err };
}
 
const phoneReg=/^(\d{2,3})(\d{3,4})(\d{4})$/;
export const rz_validPhone=(def:RzValidStat, v?:string ):RzValidStat=>{
    return rz_validReg(def,v,phoneReg);
}

 

export const rz_validUnameReg=  /^(?=.*[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힇ㆍ ᆢ])[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힇ㆍ ᆢ]+$/;
const rz_isUsername=(def:RzValidStat, uname?:string ) : RzBoolRes => {

    let r=rz_validEmptyAndLen(def,uname); 
    if(!r.valid) return NewOk(r.valid);
    if(r.isEmptyOk) return NewOk(r.valid);

    let treg=def.rule?.regEx||rz_validUnameReg;
    let b=uname!.match(treg);
    if(!b) return NewFail("형식이 맞지 않습니다." );

    return NewOk(true);
}


export const rz_validUsername=(def:RzValidStat , v?:string ):RzValidStat=>{
    return rz_validReg(def,v,rz_validUnameReg);}

 

export const rz_validPwdReg=  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]*$/
export const rz_validPassword=(def:RzValidStat, v?:string ):RzValidStat=>{
    return rz_validReg(def,v,rz_validPwdReg);
}


export const rz_validPassword2=(def:RzValidStat,  v?:string,v2?:string ):RzValidStat=>{
    let r=rz_validEmptyAndLen(def,v2);
    if(!r.valid) return r;
    if(r.isEmptyOk) return r;

    let valid=(v===v2)

    let errCd=''
    let err=''
    if(!valid){errCd= ERR_PWD_NO_EQUAL; err='비밀번호가 일치하지 않습니다';}

    return  {...def,valid:valid,errCode:errCd,err:err};
}


export type RzValType=string|boolean|number
 
export const rz_validValue=(def:RzValidStat, v?:RzValType ):RzValidStat=>{
    //if(rzIs.d)  
    rzlog.debug('rz_validValue: v=',v,',def.rule.regEx=',def.rule)

    if(v===undefined || v===null || 'string' === typeof v) return rz_validReg(def,v);
    else if('boolean' === typeof v) {
        Object.assign(def,{ valid:v as boolean, err: def.rule.errNoMatch||''})
    }
    

    return def;
}

//@not implemented ========
export const rz_validEmail=(def:RzValidStat,  v?:string ):RzValidStat=>{
    return rz_validReg(def,v);
}


export const rz_validReg=(def:RzValidStat,  v?:string, defRegEx?:RegExp ):RzValidStat=>{
    let err='';
  
  let r=rz_validEmptyAndLen(def,v);
  rzlog.debug('rz_validReg.emptLen: r=',r)
  if(!r.valid) return r;

   
  let tregEx= def.rule?.regEx|| defRegEx ;
  let b= null;
  if(tregEx) b=v!.match(tregEx);
  else b=[]
  
  let errCd='';
  let valid=true;
  if(!b) { 
        valid=false;
        errCd=ERR_NO_MATCH; err=def.rule?.errNoMatch || '형식이 맞지 않습니다.'
    }

  //if(rzIs.d) 
  rzlog.debug('rz_validReg.RegEx=',(def.rule?.regEx||phoneReg),',b=',b)
  Object.assign(def,{ valid:valid, err:err, errCode:errCd})

  return def;
}



export const rzBindFormValids=(formDefs:RzFormFieldsDef,valids:RzFormValids,state:any,skipEmptyErr:boolean=true)=>{
    let fields=Reflect.ownKeys(formDefs).map(t=>t as string)
    let tvalids=valids as any;
    let validAll=true;
    let formOnEnds=[]
    for(let i in fields){
        let fd=fields[i]
        let formDef=formDefs[fd];
        tvalids[fd]=rz_newValidStat<RzValidStat>({rule:formDef.rule,extra:formDef.extra,isSKipEmptyErr:skipEmptyErr})

        let val= state[fd];
        tvalids[fd]=rz_validValue(tvalids[fd], val) ;
        let tvalid=tvalids[fd];
        
        rzlog.debug('formDefs[',fd,'].onValidEnd : valid=',tvalid.valid,', func=',formDef.onValidEnd,',formEnd=',formDef.onFormEnd)

        if(formDef.onValidEnd) {
            rzlog.debug('formDefs[',fd,'].onValidEnd : func=',formDef.onValidEnd,',formEnd=',formDef.onFormEnd)
            let evt:RzOnValidEvent={ valids:tvalids,state:state,isValid:tvalids[fd].valid,val:val};
            tvalids[fd]=formDefs[fd].onValidEnd!(tvalid,evt);
        }
        if(formDef.onFormEnd) formOnEnds.push(formDef);

        if(formDef.rule?.required!==false && !tvalids[fd].valid) validAll=false;
    }//for

    tvalids.formValidAll=rz_newValidStat<RzValidStat>({})
    let formValAll={valid:validAll} as RzValidStat;

    if(validAll && formOnEnds.length>0){
        let tvalid=tvalids.formValidAll;
        let evt:RzOnValidEvent={ valids:tvalids,state:state, isValid:validAll };
        formOnEnds.forEach(fn=> {formValAll = fn.onFormEnd!(tvalid,evt);} )
    }
    valids.formValidAll=formValAll;
}

