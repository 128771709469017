import React, { Component } from 'react'
//import { View, Text, ViewStyle, Image,ImageStyle, TextStyle, StyleProp, StyleSheet, TouchableOpacity} from "react-native";
import {
  View,
  Text,
  ViewStyle,
  Image,
  ImageStyle,
  TextStyle,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
} from './react-components'

import { RzRow, RzView } from './rzview'
import { RzImg } from './rzimg'
import { rzlog } from '../rzcmn/rzlog'
import { rzHasDisp } from './inc'
/*******/
const rzIs = rzlog.makeDefs()
export const RzCmp_setDbg = rzIs.setDbg
/*******
 *
 */

export type RzUiSize = 'ty' | 'sm' | 'md' | 'lg' | 'hd'

export interface RzCmpProp {
  cmt?: string
  text?: string
  style?: ViewStyle
  textStyle?: TextStyle
  children?: React.ReactNode
  draw?: (props?: any) => React.ReactNode
  className?: string
}

export interface RzMCmpProp extends RzCmpProp {
  inactive?: boolean
  disabled?: boolean
  fill?: boolean

  outStyle?: ViewStyle
  top?: RzMCmpProp | React.ReactNode
  bottom?: RzMCmpProp | React.ReactNode
  // ===========
  // Main
  mainStyle?: ViewStyle

  leftEl?: React.ReactNode
  left?: RzMCmpProp
  leftStyle?: ViewStyle
  leftTextStyle?: TextStyle

  required?: boolean
  titleStyle?: ViewStyle
  titleTextStyle?: TextStyle

  body?: RzMCmpProp
  bodyStyle?: ViewStyle
  bodyEl?: React.ReactNode

  rightEl?: React.ReactNode
  right?: RzMCmpProp
  rightStyle?: ViewStyle
  rightTextStyle?: TextStyle
  pressOff?: boolean
  onPress?: (e: any) => void
  //==============
  //top deco
  topTitle?: string
  topStyle?: ViewStyle
  topTextStyle?: TextStyle
  topIcon?: string | React.ReactNode
  topIconTextStyle?: TextStyle
  topTailDeco?: string | React.ReactNode
  topTailTextStyle?: TextStyle

  //==============
  // body deco
  icon?: string | React.ReactNode | Image
  iconStyle?: ImageStyle

  title?: string
  tail?: string | React.ReactNode

  tailTextStyle?: TextStyle
  tailStyle?: ViewStyle

  //==============
  //bottom message
  bottomIcon?: string | React.ReactNode
  bottomIconStyle?: ImageStyle
  help?: string
  warn?: string
  err?: string
  ok?: string

  bottomStyle?: ViewStyle
  helpTextStyle?: TextStyle
  warnTextStyle?: TextStyle
  errTextStyle?: TextStyle
  okTextStyle?: TextStyle
} //inter

export function isMCmpProp(obj: any): boolean {
  return (
    (obj as RzMCmpProp).title !== undefined ||
    (obj as RzMCmpProp).text !== undefined
  )
}

export class RzBasMCmp<T extends RzMCmpProp, S = {}> extends Component<T, S> {
  public render(): React.ReactNode {
    let { className, ...pr } = this.props
    let hasChild = this.props.children !== undefined
    let isDbg = false

    //let outStyle = StyleSheet.flatten([{flexDirection:'column'},this.props.outStyle]);//[,]

    let outStyle = {
      flexDirection: 'column',
      ...this.props.outStyle,
    } as ViewStyle

    let hasTop = (this.props.top || this.props.topTitle) !== undefined
    let hasBottom =
      this.props.warn || this.props.help || this.props.err || this.props.bottom

    if (this.props.fill === false && (outStyle as any).width == undefined)
      delete (outStyle as any).width

    if (rzIs.d)
      rzlog.debug(
        'RzBasMCmp:title=',
        this.props.title || this.props.text || this.props.topTitle,
        'hasChlds=',
        hasChild,
        ',fil=',
        this.props.fill,
        ',outSt=',
        outStyle,
        ',bodySt=',
        this.props.bodyStyle,
      )
    return (
      <RzView style={outStyle} className={className}>
        {hasChild && this.doRenderChilds(this.props.children)}
        {!hasChild && hasTop && this.doRenderTop()}
        {!hasChild && this.doRenderMain()}
        {!hasChild && hasBottom && this.doRenderBottom()}
      </RzView>
    )
  }

  protected doRenderChilds(childs?: React.ReactNode): React.ReactNode {
    if (childs) return <>{childs}</>
    return <></>
  }

  protected doRenderCmp(p: RzMCmpProp): React.ReactNode {
    return <RzBasMCmp {...p} />
  }
  protected isPressOff() {
    return true
  }
  protected doRenderMain(): React.ReactNode {
    if (this.props.bodyEl) return this.props.bodyEl

    let hasMiddle =
      (this.props.left ||
        this.props.right ||
        this.props.tail ||
        this.props.icon ||
        this.props.title) !== undefined
    let hasLeft =
      (this.props.left || this.props.title || this.props.icon) !== undefined
    //let hasBody=(this.props.body || this.props.text || this.props.tail)!==undefined;
    let hasRight = (this.props.right || this.props.tail) !== undefined

    if (rzIs.d)
      rzlog.debug(
        `RzBasMCmp.doRenderMain:tit=${
          this.props.title || this.props.text || this.props.topTitle
        },hasMiddle=`,
        hasMiddle,
        ',st=',
        this.props.style,
      )

    let mainStyle: ViewStyle = {}

    if (
      this.props.style &&
      (this.props.style as any[]).length !== undefined &&
      (this.props.style as any[]).length > 0
    ) {
      let sts = this.props.style as any[]
      sts.forEach((t) => (mainStyle = { ...mainStyle, ...t }))
    } else if (this.props.style) mainStyle = { ...this.props.style }

    mainStyle = { ...mainStyle } //borderWidth:0,paddingBottom:0, paddingTop:0
    if (rzIs.d)
      rzlog.debug(
        `RzBasMCmp.doRenderMain:tit=${
          this.props.title || this.props.text || this.props.topTitle
        },hasMiddle=`,
        hasMiddle,
        ',st=',
        mainStyle,
      )

    if (this.props.fill == false) {
      mainStyle.flexShrink = 1
    }

    //@research
    //let st_width=(this.props.fill!==false)? {width:'100%' }:{};
    let st_width = this.props.fill !== false ? {} : {}

    let hasDisp = true
    let attrs: ViewStyle = hasDisp ? { display: 'flex' } : {}

    let tmainStyle: ViewStyle = {
      flexDirection: 'row',
      ...attrs,
      ...(hasMiddle && hasRight ? { justifyContent: 'space-between' } : {}),
      ...(mainStyle ? mainStyle : {}),
      ...st_width,
      ...this.props.mainStyle,
    }

    if (rzIs.d)
      rzlog.debug(
        'RzBasMCmp.doRenderMain:hasMiddle=',
        hasMiddle,
        ',tmainStyle=',
        tmainStyle,
        ',titl=',
        this.props.topTitle,
        ',bdySt=',
        this.props.bodyStyle,
      )

    let isNoPress =
      this.props.pressOff === undefined
        ? this.isPressOff()
        : this.props.pressOff
    if (isNoPress) return this.doRenderMainView(tmainStyle, hasLeft, hasRight)

    let onPress = this.props.onPress || this.onPress
    return (
      <TouchableOpacity onPress={onPress}>
        {this.doRenderMainView(tmainStyle, hasLeft, hasRight)}
      </TouchableOpacity>
    )
  }

  onPress = (e: any) => {}

  doRenderMainView(tmainStyle: ViewStyle, hasLeft: boolean, hasRight: boolean) {
    let isDbg = false
    let defAttr = {
      borderWidth: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }
    let dbgMg = { borderWidth: 0 }
    return (
      <RzView style={{ ...defAttr, ...tmainStyle, ...(isDbg ? dbgMg : {}) }}>
        {hasLeft && this.doRenderLeft()}
        {this.doRenderBody()}
        {hasRight && this.doRenderRight()}
      </RzView>
    )
  }

  protected doRenderLeft() {
    let leftStyle =
      this.props.left?.style || this.props.titleStyle || this.props.leftStyle
    let textStyle = this.props.titleTextStyle
    let hasIcon = this.props.icon !== undefined // || this.props.checkIcon;

    let isIconStr = false
    if (hasIcon && 'string' === typeof this.props.icon) {
      isIconStr = true
      if (this.props.icon.startsWith('data:image')) isIconStr = false
    }

    //if(hasIcon) rzlog.debug('IsHcon ========== this.props.icon= ',typeof this.props.icon)
    let hasTitle = this.props.title !== undefined

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSt: TextStyle = { ...defAttr, ...textStyle }
    return (
      <RzView style={{ flexDirection: 'row', marginRight: 6, ...leftStyle }}>
        {hasIcon && this.doRenderLeftIcon(isIconStr)}
        {hasTitle && <Text style={txtSt}>{this.props.title}</Text>}
      </RzView>
    )
  }
  // 	return (<RzView style={StyleSheet.flatten([{flexDirection:'row'},{marginRight:6},leftStyle])}>
  // 	{(hasIcon)&&(this.doRenderLeftIcon(isIconStr))}
  // 	{hasTitle&&(<Text style={txtSt}>{this.props.title}</Text>)}
  // </RzView>) ;

  protected doRenderLeftIcon(isIconStr: boolean) {
    if (isIconStr) return <Text>{this.props.icon! as string}</Text>
    return <RzImg style={this.props.iconStyle} source={this.props.icon} />
  }

  protected doRenderRight() {
    if (rzIs.d) rzlog.debug('RzBasMCmp.doRenderRight: tail=', this.props.tail)
    if (this.props.tail && 'string' === typeof this.props.tail) {
      if (rzIs.d)
        rzlog.debug('RzBasMCmp.doRenderRight: tail.text=', this.props.tail)

      if (this.props.tail.startsWith('data:image')) {
        return <RzImg src={this.props.tail} />
      }

      let txtStyle = this.props.tailTextStyle
      let tailStyle = this.props.tailStyle

      let defAttr = rzHasDisp ? { marginTop: 0 } : {}
      let txtSt = { ...defAttr, ...txtStyle } as TextStyle
      //rzlog.debug('Top.txt st=',txtSt)

      return (
        <RzView style={tailStyle}>
          <Text style={txtSt}>{this.props.tail as string}</Text>
        </RzView>
      )
    } else if (this.props.tail && 'string' !== typeof this.props.tail) {
      if (rzIs.d)
        rzlog.debug('RzBasMCmp.doRenderRight: tail.comp=', this.props.tail)
      return this.props.tail
    } else if (this.props.right && isMCmpProp(this.props.right)) {
      return this.doRenderCmp(this.props.right)
    }
    return (
      <RzView>
        <Text></Text>
      </RzView>
    )
  }

  renderBody?: () => React.ReactNode
  protected doRenderBody(): React.ReactNode {
    if (rzIs.d)
      rzlog.debug(
        `RzBasCmp(ti=${this.props.title || this.props.text}).0:renderBody=`,
        this.renderBody,
      )

    let textStyle = this.props.textStyle || ({} as TextStyle)

    if (this.renderBody) return this.renderBody()

    if (this.props.body && isMCmpProp(this.props.body)) {
      return this.doRenderCmp(this.props.body as RzMCmpProp)
    } else if (this.props.body) {
      return this.props.body as React.ReactNode
    }

    if (rzIs.d)
      rzlog.debug(
        `RzBasCmp(ti=${this.props.title || this.props.text}):renderBody=`,
        this.renderBody,
      )

    //rzlog.debug('========================== bodyStyle='+this.props.bodyStyle)
    return (
      <RzView style={this.props.bodyStyle}>
        {this.doRenderTxt(textStyle)}
      </RzView>
    )
  }

  protected doRenderTxt(textStyle: TextStyle) {
    if (rzIs.d)
      rzlog.debug(
        `RzBasCmp(ti=${this.props.title || this.props.text}).doRenderTxt`,
      )
    let txts = this.props.text

    let defAttr = rzHasDisp ? { marginTop: 0, marginBottom: 0 } : {}
    let txtSt = { ...defAttr, ...textStyle } as TextStyle

    if (txts && txts.indexOf('\\n') >= 0) {
      let ts = txts.split('\\n')
      return (
        <Text style={textStyle}>
          {ts.map((t, i) => (
            <Text key={'cpmtxt-' + i} style={txtSt}>
              {t}
            </Text>
          ))}
        </Text>
      )
    }
    return <Text style={txtSt}>{this.props.text}</Text>
  }

  protected doRenderBottom(): React.ReactNode {
    if (this.props.bottom)
      return this.doRenderCmp(this.props.bottom as RzMCmpProp)

    let mesg =
      this.props.err || this.props.warn || this.props.ok || this.props.help
    let textStyle: TextStyle = {}
    if (this.props.err && this.props.errTextStyle)
      textStyle = this.props.errTextStyle
    else if (this.props.warn && this.props.warnTextStyle)
      textStyle = this.props.warnTextStyle
    else if (this.props.ok && this.props.okTextStyle)
      textStyle = this.props.okTextStyle
    else if (this.props.help && this.props.helpTextStyle)
      textStyle = this.props.helpTextStyle

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSt = { ...defAttr, ...textStyle } as TextStyle

    let btmStyle = this.props.bottomStyle
    let hasBottomIcon = this.props.bottomIcon !== undefined
    if (rzIs.d) rzlog.debug('BottomStyle=', btmStyle)
    return (
      <RzRow style={{ ...btmStyle }}>
        {hasBottomIcon && this.doRenderBottomIcon()}
        <Text style={txtSt}>{mesg}</Text>
      </RzRow>
    )
  }

  protected doRenderBottomIcon() {
    //let text:string=this.props.topIcon! as string;
    if (rzIs.d) rzlog.debug('renderBottomIcon :')

    return (
      <RzImg
        style={this.props.bottomIconStyle}
        source={this.props.bottomIcon}
      />
    )
  }

  protected doRenderTop(): React.ReactNode {
    if (this.props.top) return this.doRenderCmp(this.props.top as RzMCmpProp)

    let title = this.props.topTitle
    let topStyle = this.props.topStyle
    let topTxtStyle = this.props.topTextStyle
    let hasTopIcon = this.props.topIcon !== undefined
    let hasTail = this.props.required || this.props.topTailDeco !== undefined

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSt = { ...defAttr, ...topTxtStyle } as TextStyle

    return (
      <RzRow style={{ alignItems: 'center', ...topStyle }}>
        {hasTopIcon && this.doRenderTopIcon()}
        <Text style={txtSt}>{title}</Text>
        {hasTail && this.doRenderTopTail()}
      </RzRow>
    )
  }
  protected doRenderTopIcon() {
    let topIconStyle = this.props.topIconTextStyle
    let text: string = this.props.topIcon! as string

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSt = { ...defAttr, ...topIconStyle } as TextStyle

    return <Text style={txtSt}>{text}</Text>
  }

  protected doRenderTopTail() {
    let topTailTxtStyle = this.props.topTailTextStyle
    let text: string = (this.props.topTailDeco! as string) || '*'

    let defAttr = rzHasDisp ? { marginTop: 0 } : {}
    let txtSt = { ...defAttr, ...topTailTxtStyle } as TextStyle

    if (rzIs.d) rzlog.debug('doRenderTopTail:decoTxt=', this.props.topTailDeco)
    return <Text style={txtSt}>{text}</Text>
  }
} //class
/*******/

export class RzMCmp extends RzBasMCmp<RzMCmpProp> {
  constructor(pr: any) {
    try {
      super(pr)
    } catch (e) {
      rzlog.debug('e=', e)
    }
  }
}

export class RzTMCmp<T extends RzMCmpProp, S = {}> extends RzBasMCmp<T, S> {}

/*******/
export class RzBasCmp<T extends RzCmpProp> extends Component<T> {
  public render(): React.ReactNode {
    return <RzView>{this.props.children}</RzView>
  }
}

export class RzCmp extends RzBasCmp<RzCmpProp> {
  public render(): React.ReactNode {
    return <RzView></RzView>
  }
}
export class RzTCmp<T extends RzCmpProp> extends RzBasCmp<T> {
  constructor(pr: any) {
    try {
      super(pr)
    } catch (e) {
      rzlog.debug('e=', e)
    }
  }
}

/*******/

/**********
 *
 ******/
interface RzLbProp {
  title?: string
}

interface RzLbCmpProp extends RzCmpProp, RzLbProp {
  text?: string
}

export class RzLbCmp extends Component<RzLbCmpProp> {
  public render(): React.ReactNode {
    const lbStyle = {}

    return (
      <RzView style={lbStyle}>
        <Text>{this.props.title}</Text>
        <Text>{this.props.text}</Text>
      </RzView>
    )
  }
}

/*******/
interface RzSlotProp {}

interface RzSlotCmpProp extends RzSlotProp, RzCmpProp, RzLbProp {}

export class RzSlotCmp extends Component<RzSlotCmpProp> {
  public render(): React.ReactNode {
    return <RzView>{this.props.children}</RzView>
  }
}

/*******/
interface RzFlatProp {}

interface RzFlatCmpProp extends RzFlatProp, RzCmpProp {}

export class RzFlatCmp extends Component<RzSlotCmpProp> {
  public render(): React.ReactNode {
    return <RzView></RzView>
  }
}

/*******/
interface RzCardProp {}

interface RzCardCmpProp extends RzCardProp, RzCmpProp {}

export class RzCardCmp extends Component<RzCardCmpProp> {
  public render(): React.ReactNode {
    return <RzView>{this.props.children}</RzView>
  }
}
