import React, { Component, useState, ReactNode, createRef } from 'react'
import { rzlog } from '../rzcmn/rzlog'
//import styled, {css}  from './react-styled'
import { RzBtn } from './rzbtn'
import { toStyles } from './rzstyles'
import { IC_CLOSE } from '../assets/'
import {
  createStackNavigator,
  ParamListBase,
  StackNavigationOptions,
  StackNavigationProp,
} from './react-nav'
import {
  View,
  Button,
  KeyboardAvoidingView,
  TextStyle,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
  SafeAreaView,
  ScrollViewProp,
  Platform,
  Keyboard,
  ScrollView,
} from './react-components'

import { RzUiRepo } from './rzrepo'
import { RzLbTxt, RzTxt } from './rztxt'
/***************/
const rzIs = rzlog.makeDefs()

/***************/

export const rzDbgAttrs = (b: number, clr?: string) => {
  return { borderWidth: b, borderStyle: 'solid', borderColor: clr || 'black' }
}

export const RzAppCtx_makeCtx = (defVal: any, repo?: RzUiRepo) => {
  const [appCtx, setAppCtx] = useState(defVal)
  let r = {
    ...appCtx,
    setAppCtx: (ctx: any, k: string | any, v?: any) => {
      if ('string' === typeof k) setAppCtx({ ...ctx, [k]: v })
      else setAppCtx({ ...ctx, ...k })
    },
  }

  if (repo?.isLoaded()) repo.load(appCtx)

  return r
}

/***************
 *
 */
export interface RzViewProp {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
  cmt?: string
  className?: string
}

export class RzView extends Component<RzViewProp> {
  constructor(pr: any) {
    super(pr)
  }
  public render() {
    //rzlog.debug('view.style=',this.props.style)
    return (
      <View style={this.props.style} className={this.props.className}>
        {this.props.children}
      </View>
    )
  }
}

//export const RzView=styled.View<RzViewProp>``

export interface RzNavProp {
  navigation: StackNavigationProp<ParamListBase>
  route: any
}

export interface RzPageProp extends RzViewProp {
  //export interface RzPageProp<T extends ParamListBase & undefined> extends RzViewProp {
  awareKey?: boolean
  nav?: StackNavigationProp<ParamListBase>
  //ParamListBase
  //StackNavigationProp<ParamListBase, string, undefined>

  prevOn?: boolean
  prevTitle?: string
  prevId?: string
  prevEl?: React.ReactNode

  title?: string
  titleTextStyle?: TextStyle

  nextOn?: boolean
  nextId?: string
  nextTitle?: string
  nextTitle2?: string
  nextEl?: React.ReactNode

  marginOff?: boolean
}

interface RzPopupAttr {
  visible: boolean
  style: ViewStyle | null

  //popup: React.Component | (()=>React.Component) |null;//
  popup: ReactNode | (() => ReactNode) | null //
}

const rzPopup = {
  popupAttr: {
    visible: false,
    style: null,
    popup: null,
  } as RzPopupAttr,
  setPopupAttr: (attr: RzPopupAttr) => {},
}

export const RzPageCtx = React.createContext(rzPopup)

interface State {
  popupAttr: RzPopupAttr
  setPopupAttr: (attr: RzPopupAttr) => void
}
export class RzPage extends Component<RzPageProp, State> {
  constructor(pr: any) {
    super(pr)

    this.state = {
      popupAttr: {
        style: null,
        visible: false,
        popup: null,
      },

      setPopupAttr: (attr: RzPopupAttr) => {
        this.setState({ popupAttr: attr })
      },
    }
  }

  componentDidMount(): void {
    if (rzIs.d) rzlog.debug('RzPg:pp.title=', this.props.title) //,',nav=',this.props.nav
    let { awareKey, nav, ...leftNavProps } = this.props
    if (this.props.nav) RzNavTop_bind(this.props.nav, leftNavProps)
  }

  public render() {
    if (rzIs.d) rzlog.debug('RzPage.reander : awareKey=', this.props.awareKey)

    if (this.props.awareKey === true) {
      //if(rzIs.d)
      //rzlog.debug('=================RzPage.reander : awareKey=true, popVisibl=',typeof this.state.popupAttr.popup)

      //rzlog.debug('=============== txt=',tfn())

      return (
        <RzPageCtx.Provider value={this.state}>
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          >
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <SafeAreaView style={this.props.style}>
                {this.props.children}
                {this.state.popupAttr.visible && this.doRenderPopup()}
              </SafeAreaView>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </RzPageCtx.Provider>
      )
    }

    return (
      <RzPageCtx.Provider value={this.state}>
        <SafeAreaView style={this.props.style}>
          {this.props.children}
          {this.state.popupAttr.visible && this.doRenderPopup()}
        </SafeAreaView>
      </RzPageCtx.Provider>
    )
  }
  //<>	{this.state.popupAttr.visible&&

  doRenderPopup() {
    let isFunc = 'function' === typeof this.state.popupAttr.popup
    //rzlog.debug('============================= : popup.type=',typeof this.state.popupAttr.popup)
    let defFn = () => (
      <View>
        <RzTxt>in line </RzTxt>
      </View>
    )
    //@let fn=this.state.popupAttr.popup  as (()=>React.Component);
    let fn = this.state.popupAttr.popup as () => ReactNode

    return (
      <RzView
        style={{
          position: 'absolute',
          top: 100,
          width: 100,
          backgroundColor: 'red',
          ...this.state.popupAttr.style,
        }}
      >
        {isFunc ? fn() : <></>}

        {/* {isFuc && this.state.popupAttr.popup } */}
      </RzView>
    )
  }
}

/********** */

//export const WsScroll = styled.ScrollView<RzPageProp<ParamListBase & undefined>>``
export interface RzScrollProp extends ScrollViewProp {
  scrollOff?: boolean
  isTopBottomEndOn?: boolean
  onTopEnd?: (e?: any) => void
  onBottomEnd?: (e?: any) => void
  children?: ReactNode
}

export class RzScroll extends Component<RzScrollProp> {
  scrollRef = createRef<ScrollView>()

  public render() {
    if (this.props.scrollOff === true)
      return <View style={this.props.style}>{this.props.children}</View>

    let { scrollOff, isTopBottomEndOn, children, ...tprops } = this.props
    let extProps = {}
    if (this.props.onTopEnd || this.props.onBottomEnd || isTopBottomEndOn) {
      if (isTopBottomEndOn !== false)
        extProps = {
          onScroll: (e: any) => {
            this.doProcScroll(e)
          },
        }
    }

    return (
      <ScrollView
        ref={this.scrollRef}
        style={this.props.style}
        {...tprops}
        {...extProps}
      >
        {this.props.horizontal && <RzRow>{this.props.children}</RzRow>}
        {!Boolean(this.props.horizontal) && this.props.children}
      </ScrollView>
    )
  }

  public scrollToLeft(left: number) {
    //rzlog.debug('===================> RzView.scrollToLeft : left=',left)
    if (this.scrollRef.current) this.scrollRef.current.scrollToLeft(left)
  }

  doProcScroll(e: any) {
    let navEvt = e.nativeEvent
    if (this.isCloseToBottom(navEvt)) {
      if (this.props.onBottomEnd) this.props.onBottomEnd(e)
    }

    if (this.isCloseToTop(navEvt)) {
      if (this.props.onTopEnd) this.props.onTopEnd(e)
    }

    if (this.props.onScroll) this.props.onScroll(e)
  }

  isCloseToBottom(pr: any) {
    let { layoutMeasurement, contentOffset, contentSize } = pr
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 20
  }

  isCloseToTop(pr: any) {
    let { layoutMeasurement, contentOffset, contentSize } = pr
    return contentOffset.y == 0
  }
} //class

/***************
 *
 */
interface RzColProp extends RzViewProp {}

//export const RzCol=styled.View<RzColProp>`flex-direction:column;`

export class RzCol extends Component<RzColProp> {
  constructor(pr?: any) {
    super(pr)
  }

  public render(): ReactNode {
    if (rzIs.d) rzlog.debug('rzcol : sty=', this.props.style)
    return (
      <RzView
        className={this.props.className}
        style={{
          flexDirection: 'column',
          display: 'flex',
          ...this.props.style,
        }}
      >
        {this.props.children}
      </RzView>
    )
  }
}

export interface RzSpcProp extends RzColProp {
  size?: number
}
export class RzSpc extends Component<RzSpcProp> {
  constructor(pr?: any) {
    super(pr)
  }

  public render(): ReactNode {
    if (rzIs.d) rzlog.debug('rzcol : sty=', this.props.style)
    let w = this.props.size !== undefined ? { width: this.props.size } : {}
    return (
      <RzView
        style={{
          flexDirection: 'column',
          display: 'flex',
          ...this.props.style,
          ...w,
        }}
      >
        {this.props.children}
      </RzView>
    )
  }
}

/**************
 *
 */
export interface RzSplitProp extends RzViewProp {
  size?: number
  color?: string
}
// export const RzSplit=styled.View<RzSplitProp>` flex-direction:row;
// height:${(pr:RzSplitProp)=>(pr.size===undefined?2:pr.size)}px; background:#E9EEF8;`

export class RzSplit extends Component<RzSplitProp> {
  render(): React.ReactNode {
    let pr = this.props

    let style = { ...pr.style, height: pr.size || 2, backgroundColor: pr.color }
    let newPr = { ...pr, style: style }

    return <RzView {...newPr}>{this.props.children}</RzView>
  }
}

// =styled.View<RzSplitProp>` flex-direction:row;
// height:${(pr:RzSplitProp)=>(pr.size===undefined?2:pr.size)}px; background:#E9EEF8;`

/**************
 *
 */
interface RzTglProp extends RzViewProp {}
//@export const RzTgl=styled.View<RzTglProp>` flex-direction:row;`
/**************
 *
 */
export interface RzLbTglProp extends RzViewProp {}
//@export const RzLbTgl=styled.View<RzLbTglProp>` flex-direction:row;`

/***************
 *
 */

export interface RzRowProp extends RzViewProp {}

//export const RzRow=styled.View<RzRowProp>` flex-direction:row;`
export class RzRow extends Component<RzRowProp> {
  public render(): React.ReactNode {
    //rzlog.debug('RzRow : style=',this.props.style)
    let { style: st, ...pr } = this.props
    return (
      <RzView style={{ flexDirection: 'row', display: 'flex', ...st }} {...pr}>
        {this.props.children}
      </RzView>
    )
  }
}

export interface LbRowProp extends RzViewProp {
  title?: string
  titleStyle?: ViewStyle
  titleTextStyle?: ViewStyle
}

export class LbRow extends Component<LbRowProp> {
  public render(): JSX.Element {
    return (
      <RzRow
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          ...this.props.style,
        }}
      >
        <RzLbTxt
          text={this.props.title}
          style={{ width: 140, ...this.props.titleStyle }}
        />
        {this.props.children}
      </RzRow>
    )
  }
}

// export class RzRow extends Component<RzRowProp> {
// 	public render(): JSX.Element {
// 		if(rzIs.d)rzlog.debug('rzrow : sty=',this.props.style)
//         return (<View style={[{flexDirection:'row'},this.props.style]}>{this.props.children}</View>);
// 	}
// }

/***************
 *
 */

export interface RzBarProp extends RzViewProp {}

export class RzBar extends Component<RzBarProp> {
  public render(): JSX.Element {
    return (
      <RzView style={{ flexDirection: 'row', ...this.props.style }}>
        {this.props.children!}
      </RzView>
    )
  }
}

/***************
 *
 */
export interface RzSlotProp extends RzViewProp {}
export class RzSlot extends Component<RzSlotProp> {
  public render(): JSX.Element {
    return (
      <RzView style={{ flexDirection: 'row', ...this.props.style }}>
        {this.props.children!}
      </RzView>
    )
  }
}

/***************
 *
 */

export interface RzFlatProp extends RzViewProp {
  draw?: React.ReactNode
}

export class RzFlat<T extends RzFlatProp = {}, S = {}> extends Component<T, S> {
  public render(): ReactNode {
    return (
      <RzView
        style={{
          ...{ flexDirection: 'column' },
          ...(this.props.style ? this.props.style : {}),
        }}
      >
        {this.props.children}
      </RzView>
    )
  }
}

export interface RzLbFlatProp extends RzFlatProp {
  titleStyle?: ViewStyle
  titleTextStyle?: TextStyle
  title?: string
  topTitle?: string
  topTitleTextStyle?: TextStyle
  bodyStyle?: ViewStyle
  isMobile?: boolean
}

export class RzLbFlat extends Component<RzLbFlatProp> {
  public render(): ReactNode {
    let {
      style,
      topTitle,
      topTitleTextStyle,
      titleStyle,
      titleTextStyle,
      title,
      bodyStyle,
      ...pr
    } = this.props

    return (
      <RzView style={{ ...{ flexDirection: 'column' }, ...style }} {...pr}>
        {topTitle && (
          <RzCol style={titleStyle}>
            <RzTxt textStyle={topTitleTextStyle} text={topTitle} />
          </RzCol>
        )}
        {topTitle && <RzView style={bodyStyle}> {this.props.children}</RzView>}
        {!Boolean(topTitle) && (
          <RzRow>
            <RzCol style={titleStyle}>
              <RzTxt textStyle={titleTextStyle} text={title} />
            </RzCol>
            <RzView style={bodyStyle}>{this.props.children}</RzView>
          </RzRow>
        )}
      </RzView>
    )
  }
}

/***************
 *
 */

export interface RzCardProp extends RzViewProp {
  title?: string
  right?: string | React.ReactNode
}

export class RzCard extends Component<RzCardProp> {
  public render(): JSX.Element {
    return (
      <RzView style={{ flexDirection: 'column', ...this.props.style }}>
        {this.props.children!}
      </RzView>
    )
  }
}

export interface RzNavTopProp {
  title?: string
  titleTextStyle?: TextStyle
  titleStyle?: ViewStyle
  titleEl?: React.ReactNode

  prevOn?: boolean
  prevEl?: React.ReactNode
  prevId?: string
  prevTitle?: string
  prevStyle?: ViewStyle
  prevTextStyle?: TextStyle

  nextOn?: boolean
  nextEl?: React.ReactNode
  nextId?: string
  nextTitle?: string
  nextStyle?: ViewStyle
  nextTextStyle?: TextStyle
}

export function RzNavTop_bind<T extends ParamListBase>(
  navigation: StackNavigationProp<T>,
  tprop: RzNavTopProp,
) {
  let prevTitle: any = { text: tprop.prevTitle || '<' }
  const nextTitle = tprop.nextTitle || '>'
  const title = tprop.title || ''
  let prevIcon = { icon: IC_CLOSE }

  if (prevIcon !== undefined) prevTitle = {}

  let hdLeft: any = () => (
    <RzBtn
      {...prevTitle}
      {...prevIcon}
      textStyle={tprop.prevTextStyle}
      style={tprop.prevStyle}
      onPress={() => {
        navigation.goBack()
      }}
    />
  )
  let hdRight: any = () => (
    <RzBtn
      text={nextTitle}
      textStyle={tprop.nextTextStyle}
      style={tprop.nextStyle}
      onPress={() => {
        navigation.push(tprop.nextId)
      }}
    />
  )

  if (tprop.prevEl) hdLeft = () => tprop.prevEl
  if (tprop.nextEl) hdRight = () => tprop.nextEl

  let hdlineOn = false
  let isDbg = false
  let opt: StackNavigationOptions = {
    title: title,
    headerTitleAlign: 'center',

    headerLeftContainerStyle: { paddingLeft: 20 },
    headerRightContainerStyle: { paddingRight: 20 },

    headerTitleStyle:
      isDbg == false
        ? { ...tprop.titleStyle, lineHeight: 30 }
        : { ...tprop.titleStyle, backgroundColor: 'red' },
    headerStyle:
      isDbg == false
        ? {}
        : {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'blue',
            alignSelf: 'center',
            alignContent: 'center',
            borderWidth: 1,
          },
    //cardStyle:{borderWidth:1},
    headerLeft: tprop.prevOn == false ? () => <></> : hdLeft,
    headerRight: tprop.nextOn == false ? () => <></> : hdRight,
    headerShadowVisible: hdlineOn,
  }
  navigation.setOptions(opt)
}

/*** */
export interface RzTabItem {
  id?: object | string
  title?: string
  draw?: React.ReactNode
  onDraw?: (pr?: any) => React.ReactNode
}

export interface RzTabViewProp {
  style?: ViewStyle
  topStyle?: ViewStyle
  bodyStyle?: ViewStyle
  onChange?: (tgt: string | object) => void
  items?: RzTabItem[]
  selected?: object | string
  params?: any
}

const st_tabview_body = toStyles(`
	width:100%;
`)
const def_tabview_tabbtn = `
border-bottom-width:2px;
	width:50%;
	align-items:center;
	margin-bottom:0px;
	`
const st_tabview_tabbtn = toStyles(
  def_tabview_tabbtn +
    `

	border-color:#DDDDDD;
`,
)
const st_tabview_tabbtn_sel = toStyles(
  def_tabview_tabbtn +
    `

	border-color:#4D93FD;
`,
)
const st_tabview_tabbtn_txt = toStyles(`
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;

text-align: center;

color: #AAAAAA;
`)

const st_tabview_tabbtn_txt_sel = toStyles(`
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;

text-align: center;

color: #4D93FD;`)

export class RzTabView extends Component<RzTabViewProp> {
  public render() {
    const sels = this.props.items?.filter((it) => it.id == this.props.selected)

    //sels[0].onDraw(this.props.params)

    return (
      <View style={{ flexDirection: 'column', ...this.props.style }}>
        <RzRow style={{ justifyContent: 'space-around' }}>
          {this.props.items?.map((t, ix) => (
            <RzCol
              key={'rtv-' + ix}
              style={{
                ...(this.props.selected === t.id
                  ? st_tabview_tabbtn_sel
                  : st_tabview_tabbtn),
                ...this.props.topStyle,
              }}
            >
              <RzBtn
                textStyle={
                  this.props.selected === t.id
                    ? st_tabview_tabbtn_txt_sel
                    : st_tabview_tabbtn_txt
                }
                onPress={() => {
                  if (this.props.onChange) this.props.onChange(t.id!)
                }}
                key={ix}
                text={t.title}
              />
            </RzCol>
          ))}
        </RzRow>

        <RzCol style={this.props.bodyStyle}>
          {sels && sels.length > 0 && sels[0].draw}
        </RzCol>
      </View>
    )
  }
}
