/* tslint:disable no-var-requires */

export const IC_ADD_BOLDER_BLUE = require('./icons/ic_add_border_blue.png')
export const IC_DELETE = require('./icons/ic_delete.png')
export const IC_BLACK_ARROW_DOWN = require('./icons/ic_black_arrow_down.png')
export const IC_BLACK_ARROW_UP  = require('./icons/ic_black_arrow_up.png')


export const IC_TOP_LOGO = require('./icons/ic_logo.png')
export const IC_TOP_TITLE = require('./icons/ic_cotitle.png')

export const IMG_LOGO = require('./images/caspa_logo.png')
export const IMG_LOGO_WHITE = require('./images/logo_white.png')
export const IMG_HOME_LOGO = require('./images/home_logo.png')
export const IMG_HOME_LOGO_SM = require('./images/home_logo_sm.png')
export const IMG_HOME_BANN02 = require('./images/home_banner02.png')
export const IMG_HOME_BANN03 = require('./images/home_banner03.png')
export const IMG_HOME_BANN01 = require('./images/banner01.png') // test
export const IMG_TEST = require('./images/test_img.png') // test
export const IMG_TEST_DETAIL = require('./images/test_detail_img.png') // test
export const IMG_MOBILE_LOGO = require('./images/mo_logo.png')

export const IMG_SPLASH = require('./images/splash.png')

export const IMG_HELP_TST1 = require('./images/help_test_img1.png') // test
export const IMG_HELP_TST2 = require('./images/help_test_img2.png') // test
export const IMG_BACK_BTN = require('./images/back_btn.png') // test

export const IC_REMOVE = require('./icons/ic_remove.png')
export const IC_PLUS = require('./icons/ic_plus.png')
export const IC_HOME_LT = require('./icons/ic_home_lt.png')
export const IC_STORE_LT = require('./icons/ic_store_lt.png')
export const IC_RESERVATION_LT = require('./icons/ic_reservation_lt.png')
export const IC_HELP_LT = require('./icons/ic_help_lt.png')
export const IC_MYPAGE_LT = require('./icons/ic_myPage_lt.png')

export const IC_HOME_DK = require('./icons/ic_home_dk.png')
export const IC_STORE_DK = require('./icons/ic_store_dk.png')
export const IC_RESERVATION_DK = require('./icons/ic_reservation_dk.png')
export const IC_HELP_DK = require('./icons/ic_help_dk.png')
export const IC_MYPAGE_DK = require('./icons/ic_myPage_dk.png')

export const IC_S_TICKET = require('./icons/ic_season_ticket.png')
export const IC_O_TICKET = require('./icons/ic_one_ticket.png')
export const IC_OWN_COUPON = require('./icons/ic_own_coupon.png')
export const IC_GIFT = require('./icons/ic_gift.png')

export const IC_ARROW_R = require('./icons/ic_arrow_right.png')
export const IC_ARROW_D_LT = require('./icons/ic_arrow_down_lt.png')
export const IC_ARROW_DOWN = require('./icons/ic_arrow_down.png')
export const IC_ARROW_DOWN_SM = require('./icons/ic_arrow_down_sm.png')
export const IC_ARROW_UP = require('./icons/ic_arrow_up.png')
export const IC_SPAKLES = require('./icons/ic_sparkles.png')
export const IC_H_TICKET = require('./icons/ic_h_ticket.png')
//export const IC_CLOUD = require('./icons/ic_cloud.png');
export const IC_BOOKMARK = require('./icons/ic_bookmark.png')
export const IC_BOOKMARK_BL = require('./icons/ic_bookmark_bl.png')
export const IC_BOOKMARK_FILL = require('./icons/ic_bookmark_fill.png')
export const IC_TELEPHONE = require('./icons/ic_telephone.png')
export const IC_BELL_DK = require('./icons/ic_bell_dk.png')
export const IC_BELL_LT_TRUE = require('./icons/ic_bell_lt_true.png')

export const IC_AVATAR = require('./icons/ic_avatar.png')
export const IC_DECLARATION = require('./icons/ic_declaration.png')
export const IC_COIN = require('./icons/ic_coin.png')

export const IC_X = require('./icons/ic_x.png')
export const IC_X_LT = require('./icons/ic_x_lt.png')

// round ic
export const IC_ROUND_9612 = require('./icons/ic_round_9612.png') // 경정비
export const IC_ROUND_9613 = require('./icons/ic_round_9613.png') // 개수대
export const IC_ROUND_9614 = require('./icons/ic_round_9614.png') // 매트세척기
export const IC_ROUND_9615 = require('./icons/ic_round_9615.png') // 에어컨
export const IC_ROUND_9616 = require('./icons/ic_round_9616.png') // 청소기
export const IC_ROUND_9617 = require('./icons/ic_round_9617.png') // 휴게실
export const IC_ROUND_9618 = require('./icons/ic_round_9618.png') // 편의점
export const IC_ROUND_9619 = require('./icons/ic_round_9619.png') // 카페
export const IC_ROUND_9620 = require('./icons/ic_round_9620.png') // 용품샵


export const IC_ROUND_9612_OFF = require('./icons/Group 9654.png') // 경정비
export const IC_ROUND_9613_OFF = require('./icons/Group 9653.png') // 개수대
export const IC_ROUND_9614_OFF = require('./icons/Group 9652.png') // 매트세척기
export const IC_ROUND_9615_OFF = require('./icons/Group 9651.png') // 에어컨
export const IC_ROUND_9616_OFF = require('./icons/Group 9650.png') // 청소기
export const IC_ROUND_9617_OFF = require('./icons/Group 9655.png') // 휴게실
export const IC_ROUND_9618_OFF = require('./icons/Group 9662.png') // 편의점
export const IC_ROUND_9619_OFF = require('./icons/Group 9656.png') // 카페
export const IC_ROUND_9620_OFF = require('./icons/Group 9657.png') // 용품샵

export const Ellipseblue = require('./icons/Ellipseblue.png') // 카페
export const Ellipsered = require('./icons/Ellipsered.png') // 용품샵

export const IMG_BAR = require('./images/bar.png')

// export const MAIN_TAB_01_ON = require("./icons/ic_bottom_find_on.png");
// export const MAIN_TAB_01_OFF = require("./icons/ic_bottom_find.png");
// export const MAIN_TAB_02_ON = require("./icons/ic_bottom_sell_on.png");
// export const MAIN_TAB_02_OFF = require("./icons/ic_bottom_sell.png");
// export const MAIN_TAB_03_ON = require("./icons/ic_bottom_community_on.png");
// export const MAIN_TAB_03_OFF = require("./icons/ic_bottom_community.png");
// export const MAIN_TAB_04_ON = require("./icons/ic_bottom_mypage_on.png");
// export const MAIN_TAB_04_OFF = require("./icons/ic_bottom_mypage.png");

export const IC_OK = require('./icons/ic_3line.png')
export const IC_LOGO = require('./icons/ic_3line.png')

export const IC_USER = require('./images/user_icon.png')
export const IC_NOTIPI_ON = require('./images/notification on.png')
export const IC_NOTIPI = require('./images/notification.png')

export const Ellipse = require('./images/Ellipse.png')

export const IC_FILE_1 = require('./images/img_file1.png')
export const IC_FILE_2 = require('./images/img_file2.png')

// export const IC_COW_BLUE = require("./icons/ic_cow_blue.png");
// export const IC_COW_RED = require("./icons/ic_cow_red.png");
// export const IC_COW_ADD = require("./icons/ic_cow_add.png");

// export const IC_LOGIN_EMAIL= require("./icons/ic_login_email.png");
// export const IC_SNS_KAKAO= require("./icons/ic_check_on.png");
// export const IC_SNS_NAVER= require("./icons/ic_check_on.png");
// export const IC_SNS_APPLE= require("./icons/ic_check_on.png");

// export const IC_MEN = require("./icons/ic_man_w.png");

// export const IC_BACK_BTN = require("./icons/ic_back_btn.png");

// export const IC_BAR_MAIN_OFF = require("./icons/ic_bar_main_off.png");
// export const IC_BAR_MAIN_ON = require("./icons/ic_bar_main_on.png");
// export const IC_BAR_PASTURE_ON = require("./icons/ic_bar_pasture_on.png");
// export const IC_BAR_PASTURE_OFF = require("./icons/ic_bar_pasture.png");
// export const IC_BAR_CATT_ON = require("./icons/ic_bar_catt_on.png");
// export const IC_BAR_CATT_OFF = require("./icons/ic_bar_cattle_off.png");
// export const IC_BAR_MYPAGE_ON = require("./icons/ic_bar_mypage_on.png");
// export const IC_BAR_MYPAGE_OFF = require("./icons/ic_bar_mypage_off.png");
// export const IC_BAR_COMM_ON = require("./icons/ic_bar_comm_on.png");
// export const IC_BAR_COMM_OFF = require("./icons/ic_bar_comm_off.png");

// export const IC_CHECK_ON = require("./icons/ic_check_on.png");
// export const IC_CHECK_OFF = require("./icons/ic_check_off.png");
// export const IC_CHECK_SMALL_ON = require("./icons/ic_check_sm_on.png");
// export const IC_CHECK_SMALL_OFF = require("./icons/ic_check_sm_off.png");

// export const IC_ERR = require("./icons/ic_err.png");

// export const IC_JOIN_OK = require("./icons/ic_join_ok.png");

// export const IC_BANNER_01 = require("./images/banner01.png");
// export const IC_BANNER_02 = require("./images/banner02.png");

// export const IC_FILE_ATTACH = require("./icons/ic_file_attach.png");
// export const IC_EXCLAMATION = require("./icons/ic_exclamation.png");

// export const IC_ARROW_DOWN = require("./icons/ic_arrow_down.png");
// export const IC_ARROW_DOWN_B = require("./icons/ic_arrow_down_b.png");

// export const IC_ARROW_UP_B = require("./icons/ic_arrow_up_b.png");
// export const IC_ARROW_LEFT = require("./icons/ic_arrow_left.png");

// export const IC_DOWN_INDI = require("./icons/ic_down_indi.png");

// export const IC_ORDER_TY_BLUE = require("./icons/ic_order_ty_blue.png");
// export const IC_CALENDAR_TY = require("./icons/ic_calendar_ty.png");
// export const IC_FILTER_TY  = require("./icons/ic_filter_ty.png");

export const IC_CLOSE = require('./icons/ic_3line.png')
export const IC_CLOSE_BADGE = require('./icons/ic_3line.png')

// export const IC_3LINE = require("./icons/ic_3line.png");

// export const IC_TASK_SCHD = require("./icons/ic_task_schd.png");
// export const IC_TASK_DONE = require("./icons/ic_task_done.png");

// export const IC_CALENDAR = require("./icons/ic_calendar.png");
// export const IC_CALENDAR_BLUE = require("./icons/ic_calendar_blue.png");

// export const IC_MEMO_PEN = require("./icons/ic_memo_pen.png");
// export const IC_TODO_LIST = require("./icons/ic_todo_list.png");

// export const IC_FIND = require("./icons/ic_find.png");

// export const IC_VERT_3DOT = require("./icons/ic_vert_3dot.png");
// export const IC_ENVELOP = require("./icons/ic_envelop.png");
// export const IC_PHOTO = require("./icons/ic_photo.png");
// export const IC_EMAIL = require("./icons/ic_email.png");

// export const IC_LIKE = require("./icons/ic_like.png");
// export const IC_LIKE_RED = require("./icons/ic_like_red.png");
// export const IC_FAVOR = require("./icons/ic_favor.png");
// export const IC_FAVOR_ORANGE = require("./icons/ic_favor_orange.png");
// export const IC_REPLY = require("./icons/ic_reply.png");

// export const IC_HUMAN = require("./icons/ic_human.png");
// export const IC_BGPIC = require("./icons/ic_bgpic.png");

// export const IC_REFRESH = require("./icons/ic_refresh.png");

// export const IC_LOCATION = require("./icons/ic_location.png");
// export const IC_GPS = require("./icons/ic_gps.png");
// export const IC_NOTI_SPEAK = require("./icons/ic_noti_speak.png");
// export const IC_CIR_MORE = require("./icons/ic_cir_more.png");
// export const IC_HOUSE = require("./icons/ic_house.png");
// export const IC_ADD_BLUE = require("./icons/ic_add_blue.png");
// export const IC_UD_BAR = require("./icons/ic_ud_bar.png");
// export const IC_BT_TRASH_RED = require("./icons/ic_bt_trash_red.png");
// export const IC_BT_PEN = require("./icons/ic_bt_pen.png");
// export const IC_CLOSE_MD = require("./icons/ic_close_md.png");

// export const IC_WTH_CLOUD = require("./icons/ic_wth_cloud.png");
// export const IC_WTH_SUNNY = require("./icons/ic_wth_sunny.png");
// export const IC_WTH_RAIN = require("./icons/ic_wth_rain.png");
// export const IC_WTH_GLOOMY = require("./icons/ic_wth_gloomy.png");
// export const IC_WTH_STORM = require("./icons/ic_wth_storm.png");
// export const IC_WTH_SNOW = require("./icons/ic_wth_snow.png");
// export const IC_WTH_THUNDER = require("./icons/ic_wth_thunder.png");

//ic_file_attach
