// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/ic_arrow_down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select {
    display: inline-block;
    
    border: 0px solid #999;
  }



select .selected {
    display: flex;
    justify-content: space-between;
    padding: 8px 5px;
  }
  
  .select .selected .selected-value {
    max-width: 90px;
  }
  
  .select .selected .arrow {
    width: 24px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 50% 50%;
  }
  
  .select ul {
    width: 120px;
    border: 0px solid #999;
    position: absolute;
    background: #fff;
    border-top: none;
    margin: 1px 0 0 -1px;
    cursor: pointer;
  }
  
  .select ul li {
    padding: 8px 5px;
  }

  .select ul li,
  .select .selected .selected-value {
    white-space: nowrap; /* 줄바꿈 안함 */
    overflow: hidden;
    text-overflow: ellipsis; /* 말줄임 적용 */
  }



  .select ul li:hover {
    background: rgba(168, 156, 235, 0.35)
  }


  .select ul {
    display: none;
  }
  
  .select.active ul {
    display: initial;
  }`, "",{"version":3,"sources":["webpack://./src/rzcmnui/styles/select.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;;IAErB,sBAAsB;EACxB;;;;AAIF;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,yDAA6D;IAC7D,wBAAwB;EAC1B;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;;IAEE,mBAAmB,EAAE,WAAW;IAChC,gBAAgB;IAChB,uBAAuB,EAAE,WAAW;EACtC;;;;EAIA;IACE;EACF;;;EAGA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".select {\n    display: inline-block;\n    \n    border: 0px solid #999;\n  }\n\n\n\nselect .selected {\n    display: flex;\n    justify-content: space-between;\n    padding: 8px 5px;\n  }\n  \n  .select .selected .selected-value {\n    max-width: 90px;\n  }\n  \n  .select .selected .arrow {\n    width: 24px;\n    background-image: url(\"../../assets/icons/ic_arrow_down.png\");\n    background-size: 50% 50%;\n  }\n  \n  .select ul {\n    width: 120px;\n    border: 0px solid #999;\n    position: absolute;\n    background: #fff;\n    border-top: none;\n    margin: 1px 0 0 -1px;\n    cursor: pointer;\n  }\n  \n  .select ul li {\n    padding: 8px 5px;\n  }\n\n  .select ul li,\n  .select .selected .selected-value {\n    white-space: nowrap; /* 줄바꿈 안함 */\n    overflow: hidden;\n    text-overflow: ellipsis; /* 말줄임 적용 */\n  }\n\n\n\n  .select ul li:hover {\n    background: rgba(168, 156, 235, 0.35)\n  }\n\n\n  .select ul {\n    display: none;\n  }\n  \n  .select.active ul {\n    display: initial;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
