import { rzlog } from '../../rzcmn';

//import styled, {css}  from 'styled-components/native'
//import styled, {css}  from 'styled-components'

import styled, {css} from '../react-styled'
import {Component} from 'react';

import {RzBtn,RzBtnProp } from '../rzbtn'
import {toStyles } from '../rzstyles'
import {defBtnFont,defSlotHeight, defTxtFont} from './consts'
import { ReactNode } from 'react';
import { ViewStyle } from '../react-components';

export interface RsRndBtnProp extends RzBtnProp{
        size?:"sm"|"md";
        underline?:boolean;
        fill?:boolean;
}
//font-family: Spoqa Han Sans Neo;

const textStyle=`        
font-family: '${defBtnFont}';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 26px;
text-align: center;
`

//export const WsRndBtn = styled(RzBtn)<RzBtnProp>
// export const RsRndBtn = styled(RzBtn).attrs<RsRndBtnProp,RsRndBtnProp>(
//                 (pr:RsRndBtnProp)=>{return {...pr, 
//                         textStyle:toStyles(textStyle+((pr.outline===false)?'color:#4D93FD;':'color:white;'))}})`
//         flex:1;
//         background: ${(pr)=>{return pr.outline==false? '#ffffff':(pr.inactive==true?'#8D8D8D':'#4D93FD')}};
//         border-radius: 10px;
//         justify-content: center;
//         border-width:${(pr)=>pr.outline===false?1:0}px;
//         border-color:${(pr)=>pr.inactive==true?'#8D8D8D':'#4D93FD'};
//         height:${defSlotHeight}px;
// `
export class RsRndBtn extends Component<RsRndBtnProp>{
        render(): ReactNode {
                let pr=this.props;
                let style:ViewStyle={ 
                                color: (pr.outline===false)?'#4D93FD;':'white',
                                flex:1,
                                backgroundColor:pr.outline==false? '#ffffff':(pr.inactive==true?'#8D8D8D':'#4D93FD')

                        }
                let newProps={...this.props, style:style};
            return (<RzBtn {...newProps}/>)
        }
}


const txtInMdTailTextStyle=toStyles(`
font-family: '${defTxtFont}';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 26px;
color: #4D93FD;

text-align: right;
`)

const txtInSmTailTextStyle=toStyles(`
font-family: '${defTxtFont}';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;
color: #666666;
margin-top:2px;

text-align: right;
`)
const txtInUnderlie=`text-decoration-line: underline;`

// export const RsInBtn = styled(RzBtn).attrs<RsRndBtnProp,RsRndBtnProp>(
//         (pr)=>{return {...pr, 
//                 textStyle:toStyles(
//                          (pr.size==="sm"?txtInSmTailTextStyle:txtInMdTailTextStyle)
//                          +((pr.underline===true)?txtInUnderlie:'')
//                       )}})`
// justify-content: center;
// `

export class RzInBtn extends Component<RsRndBtnProp> {
        public render(): ReactNode {
                let pr=this.props;
               let newProp={...pr, ...(pr.size==='sm'? txtInSmTailTextStyle: txtInMdTailTextStyle)}

            return (<RzBtn {...newProp}/>)
        }
}

// export const RsInBtn = styled(RzBtn).attrs<RsRndBtnProp,RsRndBtnProp>(
//         (pr)=>{return {...pr, 
//                 textStyle:toStyles(
//                          (pr.size==="sm"?txtInSmTailTextStyle:txtInMdTailTextStyle)
//                          +((pr.underline===true)?txtInUnderlie:'')
//                       )}})`
// justify-content: center;
// `