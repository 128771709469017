import { rzlog } from "./inc";
const crypto=require("crypto-js")


export class RzStoreConf {
    storeSecretKey?:string;
}

let _storeConf= {};
export const rzStore_setConf=(conf:RzStoreConf)=>{
    _storeConf={...conf}
}

let _ctx:any|null=null;
let _map:any={}
export class RzStore {

  storeConf?:RzStoreConf;

  getCtx(){return _ctx||undefined;}
  setCtx(ctx:any){ _ctx=ctx;}

  getAttr(k:string):any|undefined{
    return _map[k];
  }

  setAttr(k:string, v:any){
    _map[k]=v;
  }


  async setItem(key:string, val:any) {

    _map[key]=val;
    rzlog.debug("encode. k=",key,',v=',val,',typeof=',typeof val);
     let v=null;
     if('string' === typeof val){
       v=val;
     } else {
        v='@json:' + JSON.stringify(val);
     }
     

    let skey=this.storeConf?.storeSecretKey||'testkey';
    var data = crypto.AES.encrypt(v, skey).toString();
    rzlog.debug("encode.data=",data)
    // window.localStorage.setItem(key, data);
    window.sessionStorage.setItem(key, data);
  }

  async getItem(key:string) {
    // var ciphertext = window.localStorage.getItem(key);
    var ciphertext = window.sessionStorage.getItem(key);
    if (!ciphertext || ciphertext === 'null') return undefined;
    
    var data = '';
    
    try {
            rzlog.debug("getItem k=",key,", cipher=",ciphertext);
            let skey=this.storeConf?.storeSecretKey||'testkey';
            data = crypto.AES.decrypt(ciphertext, skey).toString(crypto.enc.Utf8)
            let ix=data.indexOf('@json:');
            rzlog.debug("decode.data=",data,', json.at=',ix);
            
            if(ix >=0 ){
              data=data.substring(6);
              rzlog.debug("jsonStr=",data)
              data = JSON.parse(data);
            }
            
            return data;
    } catch (e) {
       rzlog.error("RzStore : getItem k=",key,", error = ",e);
        //console.error(e);

        return undefined;
    }

     
  }
  
 async removeItem(key:string) {
    // window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
    if(_map[key] !==undefined ) _map[key]=undefined;
  }
} //class

export const storage = new RzStore();