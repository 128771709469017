import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, redirect } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { RzAppCtx_makeCtx } from './rzcmnui'
import {
  CmAppCtx,
  CmAppCtxHolder,
  CmAppCtx_New,
  CmAppCtx_getClientConf,
} from './contexts'
import { CmRepo_getRepo } from './repo'
import { rzClient_init } from './rzcmnui/rzclient'

import { RequireAuth } from './components/RequireAuth'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/guest/Login'))
// const Login = React.lazy(() => import('./screen/mobile/MoLoginScreen'))

// class App extends Component {

//   render(): JSX.Element {
const App = () => {
  let cliConf = CmAppCtx_getClientConf()
  rzClient_init(cliConf)

  let cmrepo = CmRepo_getRepo(cliConf)
  cmrepo.isDefOn = false

  let appName = 'caspa.admin'
  let ctx = CmAppCtx_New(appName, cmrepo, cliConf)

  // let navParam:CmNavParam ={}
  // if(RzTest_initReady(ctx!,navParam)) return RzTest_Show();
  // if(rzIs.d) rzlog.debug('APP: app.ctx=',ctx,',repo=', (cmrepo!==undefined),' ============================================');
  return (
    <CmAppCtxHolder.Provider value={ctx}>
      <HashRouter>
        <Suspense
          fallback={
            <div className="spinner-container">
              <CSpinner color="primary" />
            </div>
          }
        >
          <Routes>
            <Route
              path="*"
              element={
                <RequireAuth>
                  <DefaultLayout />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </CmAppCtxHolder.Provider>
  )
}
//}

export default App
