import { CmRepo } from './cmrepo'
import {
  LoginRes,
  LoginReq,
  LogoutReq,
  LogoutRes,
  AuthSmsReq,
  AuthSmsRes,
  UserDupReq,
  UserRegReq,
  UserRes,
  BbsArticleFindReq,
  BbsArticleRsRes,
  BbsFavorReq,
  BbsFavorRes,
  BbsArticleReq,
  BbsArticleRes,
  BbsReplyFindReq,
  BbsReplyMutReq,
  BbsReplyRes,
  BbsReplyRsRes,
  WeatherReq,
  WeatherRes,
  UserReq,
  CmWashPodFindReq,
  CmWashPodReq,
  CmWashPodMutReq,
  CmWashPodRsRes,
  CmWashPodRes,
  CmWashReq,
  CmWashRes,
  CmWashFindReq,
  CmWashRsRes,
  CmShopReq,
  CmShopRes,
  CmCpnFindReq,
  CmCpnModelFindReq,
  CmCpnModelReq,
  CmCpnModelRes,
  CmCpnModelRsRes,
  CmCpnReq,
  CmCpnRes,
  CmCpnRsRes,
  CmGiftFindReq,
  CmGiftModelFindReq,
  CmGiftModelReq,
  CmGiftModelRes,
  CmGiftModelRsRes,
  CmGiftReq,
  CmGiftRes,
  CmGiftRsRes,
  CmPayFindReq,
  CmPayReq,
  CmPayRes,
  CmPayRsRes,
  CmRsvFindReq,
  CmRsvReq,
  CmRsvRes,
  CmRsvRsRes,
  CmCarFindReq,
  CmCarReq,
  CmCarRes,
  CmCarRsRes,
  WeatherFindReq,
  WeatherRsRes,
  CmNotiReq,
  CmNotiRes,
  CmNotiFindReq,
  CmNotiRsRes,
  CmCreditCardFindReq,
  // CmCmCreditCardReq,
  // CmCmCreditCardRes,
  CmCreditCardRsRes,
  CmCreditCardReq,
  CmCreditCardRes,
  CmLocFindReq,
  CmLocRsRes,
  CmCarAddOnFindReq,
  CmCarAddOnRsRes,
  CmReviewFindReq,
  CmReviewRsRes,
  CmShopEquipFindReq,
  CmShopEquipMutReq,
  CmShopEquipRes,
  CmWashCourseFindReq,
  CmWashCourseRsRes,
  CmWashCourseReq,
  CmWashCourseRes,
  CmWashOptRes,
  CmWashOptReq,
  CmWashOptFindReq,
  CmWashOptRsRes,
  CmSettleFindReq,
  CmSettleRsRes,
  CmSettleRes,
  CmSettleReq,
  CmSettleRowFindReq,
  CmSettleRowRsRes,
  CmSettleRowReq,
  CmSettleRowRes,
  CmCashReq,
  CmCashRes,
  CmCashFindReq,
  CmCashRsRes,
  CmShopFindReq,
  CmShopRsRes,
  CmStatReq,
  CmStatRes,
  CmStatFindReq,
  CmStatRsRes,
  CmShopInfoMutReq,
  CmShopInfoRes,
  FileRefReq,
  FileRefRes,
  BbsAttachReq,
  BbsAttachRes,
  UserRsRes,
} from './msg'
import { IsFail, NewFail, NewOk, RzBoolRes, rzlog, RzRes } from '../rzcmn/'
import { RzAppCtx, RzBasUiRepo } from '../rzcmnui/'
import {
  CmoBbsArticle,
  CmoBbsAttach,
  CmoBbsReply,
  CmoUser,
  CmoWeather,
  CmoAuthToken,
  CmoWashPod,
  CmoWash,
  CmoShop,
  CmoRsv,
  CmoCar,
  CmoCpn,
  CmoCpnEnum,
  CmoNoti,
  CmoCreditCard,
  CmoLoc,
  CmoCarAddOn,
  CmoWashCourse,
  CmoWashOpt,
  CmoSettle,
  CmoSettleRow,
  CarTypeEnum,
  CmoFileRef,
  CmoStat,
  CmoShopEquip,
  CmoShopInfo,
  CarAttachEnum,
} from './dto'
import {
  DefArticles,
  DefArticles_get,
  DefShops,
  DefBanners,
  DefReplies,
  DefWashPods,
  DefWeather,
  DefCar,
} from './defs'
import { CmRepo4Rmt } from './cmrepo.rmt'
import { Dispatch, SetStateAction } from 'react'
import { CmAppCtx } from '../contexts'

const rzIs = rzlog.makeDefs()
export const CmRepoMem_setDbg = rzIs.setDbg

const locDefs = [
  { id: '10', code: '서울', title: '서울' },
  { id: '1', code: '경기', title: '경기' },
  { id: '2', code: '강원', title: '강원' },
  { id: '3', code: '충북', title: '충북' },
  { id: '4', code: '충남', title: '충남' },
  { id: '5', code: '전북', title: '전북' },
  { id: '6', code: '전남', title: '전남' },
  { id: '7', code: '경북', title: '경북' },
  { id: '8', code: '경남', title: '경남' },
  { id: '9', code: '제주', title: '제주' },

  { id: '11', code: '인천', title: '인천' },
  { id: '12', code: '부산', title: '부산' },
  { id: '13', code: '대구', title: '대구' },
  { id: '14', code: '대전', title: '대전' },
  { id: '15', code: '광주', title: '광주' },
  { id: '16', code: '울산', title: '울산' },
] as CmoLoc[]

/**
 *
 */
export class CmRepo4Mem extends RzBasUiRepo implements CmRepo {
  rmtRepo?: CmRepo

  constructor(conf?: any) {
    super()
    this.rmtRepo = new CmRepo4Rmt(conf)
    this.isDefOn = true
  }
  // findArticle(rq: BbsArticleFindReq): Promise<BbsArticleRsRes> {
  //   throw new Error('Method not implemented.');
  // }

  setCtx(ctx: any) {
    this.ctx = ctx
  }
  /* load ***************/
  async load(
    ctx?: RzAppCtx,
    setState?: Dispatch<SetStateAction<any>>,
  ): Promise<boolean> {
    let r = await super.load(ctx, setState)
    if (!r) return r

    let uctx = ctx as CmAppCtx
    if (!uctx.userInfo && this.token) {
      rzlog.debug('LOAD : loginByToken ======== ')
      let tkn = this.token
      let rq = { token: tkn } as LoginReq
      let r1 = await this.rmtRepo!.loginByToken(rq)

      if (!IsFail(r1)) {
        let tkn0 = r1!.data!.token
        let tknExp = r1?.data?.tokenExpiredAt
        let rtkn = r1?.data?.refreshToken
        if (tkn0) this.saveTokens(tkn0, tknExp, rtkn)
      }
    }

    return r
  }

  defCarOpts = [
    {
      id: '1',
      code: CarAttachEnum.FRONT_WIPER,
      title: '앞면와이퍼',
      note: '앰블램등',
    },
    { id: '2', code: CarAttachEnum.CARRIER, title: '캐리어', note: '앰블램등' },
    { id: '3', code: CarAttachEnum.MOZEN, title: '모젠', note: '앰블램등' },
    {
      id: '4',
      code: CarAttachEnum.ROOF_SPOILER,
      title: '루프 스포일러',
      note: '앰블램등',
    },

    {
      id: '5',
      code: CarAttachEnum.REAR_WIPER,
      title: '뒷면와이퍼',
      note: '앰블램등',
    },
    {
      id: '6',
      code: CarAttachEnum.ROOF_SPOILER,
      title: '뒷편 스포일러',
      note: '앰블램등',
    },
    { id: '7', code: CarAttachEnum.FENDER, title: '폴/펜더', note: '앰블램등' },
    { id: '8', code: CarAttachEnum.EMBLEM, title: '엠블렘', note: '앰블램등' },

    {
      id: '9',
      code: CarAttachEnum.BUMPER_GUARD,
      title: '범퍼가드',
      note: '앰블램등',
    },
    {
      id: '10',
      code: CarAttachEnum.SIDE_MIRROR,
      title: '사이드미러',
      note: '앰블램등',
    },
    {
      id: '11',
      code: CarAttachEnum.GAS_CAP,
      title: '주유캡',
      note: '앰블램등',
    },
    {
      id: '12',
      code: CarAttachEnum.REAR_TIRE,
      title: '뒷면 타이어',
      note: '앰블램등',
    },
  ]

  async findCarAddOn(rq: CmCarAddOnFindReq): Promise<CmCarAddOnRsRes> {
    if (rq.data?.type == CarTypeEnum.VAN) return NewOk(this.defCarOpts)
    else if (rq.data?.type == CarTypeEnum.JEEP) return NewOk(this.defCarOpts)
    else if (rq.data?.type == CarTypeEnum.TAXI) return NewOk(this.defCarOpts)
    return NewOk(this.defCarOpts)
  }

  async uploadFile(
    fileObj: any,
    isPrivate?: boolean | undefined,
    uri?: string | undefined,
  ): Promise<RzRes<CmoFileRef>> {
    if (!this.rmtRepo) return NewFail('no rmt repo')

    return this.rmtRepo!.uploadFile(fileObj, isPrivate, uri)
  }

  async putFile(rq: FileRefReq): Promise<FileRefRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putFile(_rq!)
      },
      def,
      this.isDefWeather,
    )
  }

  async delFile(rq: FileRefReq): Promise<FileRefRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delFile(_rq!)
      },
      def,
      this.isDefWeather,
    )
  }

  /* Weather *************/

  defWeather = DefWeather
  isDefWeather = false
  async getWeather(rq: WeatherReq): Promise<WeatherRes> {
    let def = NewOk(this.defWeather)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getWeather(_rq!)
      },
      def,
      this.isDefWeather,
    )
  }
  async findWeather(rq: WeatherFindReq): Promise<WeatherRsRes> {
    let dt = [this.defWeather]
    let def = NewOk(dt)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findWeather(_rq!)
      },
      def,
      this.isDefWeather,
    )
  }

  /* CmoLoc ***********/
  async findLoc(rq: CmLocFindReq): Promise<CmLocRsRes> {
    const code = rq.data?.code

    let dt = locDefs

    if (code) {
      dt = dt.filter((it) => it.code === code)
    }
    return NewOk(dt)
  }

  /* BbsReply ************/
  defCards = [] as CmoCreditCard[]
  async findCreditCard(rq: CmCreditCardFindReq): Promise<CmCreditCardRsRes> {
    let def = NewOk(this.defCards)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findCreditCard(_rq!)
      },
      def,
      false,
    )
  }

  async putCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putCreditCard(_rq!)
      },
      def,
      false,
    )
  }

  async delCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delCreditCard(_rq!)
      },
      def,
      false,
    )
  }

  async addCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addCreditCard(_rq!)
      },
      def,
      false,
    )
  }
  async getCreditCard(rq: CmCreditCardReq): Promise<CmCreditCardRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getCreditCard(_rq!)
      },
      def,
      false,
    )
  }
  /* BbsReply ************/
  defReplies: CmoBbsReply[] = DefReplies
  defReply: CmoBbsReply = this.defReplies[0]

  async findBbsReply(rq: BbsReplyFindReq): Promise<BbsReplyRsRes> {
    let def = NewOk(this.defReplies)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findBbsReply(_rq!)
      },
      def,
      false,
    )
  }

  async putBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putBbsReply(_rq!)
      },
      def,
      false,
    )
  }

  async delBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delBbsReply(_rq!)
      },
      def,
      false,
    )
  }

  async addBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addBbsReply(_rq!)
      },
      def,
      false,
    )
  }
  async getBbsReply(rq: BbsReplyMutReq): Promise<BbsReplyRes> {
    let def = NewOk(this.defReply)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getBbsReply(_rq!)
      },
      def,
      false,
    )
  }

  //===================
  id?: string
  name?: string
  fileType?: string
  uri?: string

  defArticles = DefArticles
  defArticle = this.defArticles[0]

  isDefArticle = false

  async findArticle(rq: BbsArticleFindReq): Promise<BbsArticleRsRes> {
    if (rzIs.d) rzlog.debug('>>>>> findBbsArticle : rq=', rq)
    console.log('cmrepo.mem - findArticle')
    let defOn = true
    let defs: CmoBbsArticle[] = []
    if (defOn) {
      if (rq.data?.code === 'banner') return NewOk(DefBanners)
      defs = DefArticles_get(rq)
    }

    let data = defs
    let def = NewOk(data)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findArticle(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  async findBbsArticle(rq: BbsArticleFindReq): Promise<BbsArticleRsRes> {
    if (rzIs.d) rzlog.debug('>>>>> findBbsArticle : rq=', rq)
    console.log(`cmrepo.mem findBbsArticle: ${this.isDefArticle}`)

    let defOn = true
    let defs: CmoBbsArticle[] = []
    if (defOn) {
      if (rq.data?.code === 'banner') return NewOk(DefBanners)
      defs = DefArticles_get(rq)
    }

    let data = defs
    let def = NewOk(data)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findBbsArticle(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  async getBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getBbsArticle(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }
  async addBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addBbsArticle(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }
  async putBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putBbsArticle(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  async delBbsArticle(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delBbsArticle(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  async addFavor(rq: BbsFavorReq): Promise<BbsFavorRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addFavor(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }
  async putBbsLike(rq: BbsArticleReq): Promise<BbsArticleRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putBbsLike(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }
  async putBbsFavor(rq: BbsFavorReq): Promise<BbsFavorRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putBbsFavor(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  async addAttach(rq: BbsAttachReq): Promise<BbsAttachRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addAttach(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }
  async putAttach(rq: BbsAttachReq): Promise<BbsAttachRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putAttach(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  async delAttach(rq: BbsAttachReq): Promise<BbsAttachRes> {
    let def = NewOk(this.defArticle)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delAttach(_rq!)
      },
      def,
      this.isDefArticle,
    )
  }

  /****************************
   *  Noti
   */
  isDefNoti = false
  defNoti = {}
  async findNoti(rq: CmNotiFindReq): Promise<CmNotiRsRes> {
    if (rzIs.d) rzlog.debug('>>>>> findCmNoti : rq=', rq)

    let defOn = true
    let defs: CmoNoti[] = []

    let data = defs
    let def = NewOk(data)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findNoti(_rq!)
      },
      def,
      this.isDefNoti,
    )
  }

  async getNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let def = NewOk(this.defNoti)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getNoti(_rq!)
      },
      def,
      this.isDefNoti,
    )
  }
  async addNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let def = NewOk(this.defNoti)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addNoti(_rq!)
      },
      def,
      this.isDefNoti,
    )
  }
  async sendNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let def = NewOk(this.defNoti)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.sendNoti(_rq!)
      },
      def,
      this.isDefNoti,
    )
  }
  async putNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let def = NewOk(this.defNoti)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putNoti(_rq!)
      },
      def,
      this.isDefNoti,
    )
  }

  async delNoti(rq: CmNotiReq): Promise<CmNotiRes> {
    let def = NewOk(this.defNoti)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delNoti(_rq!)
      },
      def,
      this.isDefNoti,
    )
  }

  /************************
   *
   */
  isRsvDef = false
  defRsvs = [
    {
      id: '1',
      schdBegAt: new Date(),
      amount: 100,
      shopId: '1',
      shop: DefShops[0],
      carRegNo: '12두 1111',
      car: { regNo: '12두 1111' },
      isToday: true,
      coupons: [{ id: '1', type: CmoCpnEnum.SEASON }],
    },
    {
      id: '2',
      schdBegAt: new Date(),
      amount: 100,
      shopId: '1',
      shop: DefShops[0],
      carRegNo: '12두 1111',
      car: { regNo: '12두 1111' },
      isToday: false,
      coupons: [{ id: '2', type: CmoCpnEnum.GIFT }],
    },
    ,
    {
      id: '3',
      schdBegAt: new Date(),
      amount: 100,
      shopId: '1',
      shop: DefShops[0],
      carRegNo: '12두 1111',
      car: { regNo: '12두 1111' },
      isToday: false,
      coupons: [{ id: '3', type: CmoCpnEnum.ONETIME }],
    },
  ]

  findRsv(rq: CmRsvFindReq): Promise<CmRsvRsRes> {
    let def = NewOk(this.defRsvs as CmoRsv[])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findRsv(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }
  getRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getRsv(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }
  addRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addRsv(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }
  putRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putRsv(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }
  delRsv(rq: CmRsvReq): Promise<CmRsvRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delRsv(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }

  /** PAY */
  isPayDef = false
  findPay(rq: CmPayFindReq): Promise<CmPayRsRes> {
    let def = NewOk([{}])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findPay(_rq!)
      },
      def,
      false,
    )
  }
  getPay(rq: CmPayReq): Promise<CmPayRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getPay(_rq!)
      },
      def,
      this.isPayDef,
    )
  }
  addPay(rq: CmPayReq): Promise<CmPayRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addPay(_rq!)
      },
      def,
      this.isPayDef,
    )
  }
  putPay(rq: CmPayReq): Promise<CmPayRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putPay(_rq!)
      },
      def,
      this.isPayDef,
    )
  }
  delPay(rq: CmPayReq): Promise<CmPayRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delPay(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }

  /** Cash */
  isCashDef = false
  findCash(rq: CmCashFindReq): Promise<CmCashRsRes> {
    let def = NewOk([{}])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findCash(_rq!)
      },
      def,
      false,
    )
  }
  getCash(rq: CmCashReq): Promise<CmCashRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getCash(_rq!)
      },
      def,
      this.isCashDef,
    )
  }
  addCash(rq: CmCashReq): Promise<CmCashRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addCash(_rq!)
      },
      def,
      this.isCashDef,
    )
  }
  putCash(rq: CmCashReq): Promise<CmCashRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putCash(_rq!)
      },
      def,
      this.isCashDef,
    )
  }
  delCash(rq: CmCashReq): Promise<CmCashRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delCash(_rq!)
      },
      def,
      this.isRsvDef,
    )
  }

  /** CPN */
  isCpnDef = false
  defSessions = [
    {
      id: '1',
      title: '카스파 올코스 정기권',
      nextPayDate: new Date(),
    } as CmoCpn,
  ]
  defOnetimes = [
    { id: '2', title: '카스파 1회권', nextPayDate: new Date() } as CmoCpn,
  ]
  defNormals = [
    { id: '3', title: '카스파 쿠폰', nextPayDate: new Date() } as CmoCpn,
  ]
  defGifts = [
    {
      id: '4',
      title: '카스파 세차1회 선물권',
      nextPayDate: new Date(),
    } as CmoCpn,
  ]
  lightFindCpn(rq: CmCpnFindReq): Promise<CmCpnRsRes> {
    let items =
      rq.data?.code === 'season'
        ? this.defSessions
        : rq.data?.code === 'gift'
        ? this.defGifts
        : rq.data?.code === 'normal'
        ? this.defNormals
        : this.defOnetimes

    if (rzIs.d) rzlog.debug('lightFindCpn')
    let def = NewOk(items)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.lightFindCpn(_rq!)
      },
      def,
      this.isCpnDef,
    )
  }
  findCpn(rq: CmCpnFindReq): Promise<CmCpnRsRes> {
    let items =
      rq.data?.code === 'season'
        ? this.defSessions
        : rq.data?.code === 'gift'
        ? this.defGifts
        : rq.data?.code === 'normal'
        ? this.defNormals
        : this.defOnetimes

    if (rzIs.d) rzlog.debug('findCpn.ITEMS=', items)
    let def = NewOk(items)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findCpn(_rq!)
      },
      def,
      this.isCpnDef,
    )
  }
  getCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getCpn(_rq!)
      },
      def,
      this.isCpnDef,
    )
  }
  addCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addCpn(_rq!)
      },
      def,
      this.isCpnDef,
    )
  }
  putCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putCpn(_rq!)
      },
      def,
      this.isCpnDef,
    )
  }
  delCpn(rq: CmCpnReq): Promise<CmCpnRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delCpn(_rq!)
      },
      def,
      this.isCpnDef,
    )
  }

  /** CpnModel */
  isCpnModelDef = false
  findCpnModel(rq: CmCpnModelFindReq): Promise<CmCpnModelRsRes> {
    let def = NewOk([{}])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findCpnModel(_rq!)
      },
      def,
      false,
    )
  }
  getCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getCpnModel(_rq!)
      },
      def,
      this.isCpnModelDef,
    )
  }
  addCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addCpnModel(_rq!)
      },
      def,
      this.isCpnModelDef,
    )
  }
  putCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putCpnModel(_rq!)
      },
      def,
      this.isCpnModelDef,
    )
  }
  delCpnModel(rq: CmCpnModelReq): Promise<CmCpnModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delCpnModel(_rq!)
      },
      def,
      this.isCpnModelDef,
    )
  }

  /** Gift */
  isGiftDef = false
  findGift(rq: CmGiftFindReq): Promise<CmGiftRsRes> {
    let def = NewOk([{}])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findGift(_rq!)
      },
      def,
      this.isGiftDef,
    )
  }
  getGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getGift(_rq!)
      },
      def,
      this.isGiftDef,
    )
  }
  addGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addGift(_rq!)
      },
      def,
      this.isGiftDef,
    )
  }
  putGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putGift(_rq!)
      },
      def,
      this.isGiftDef,
    )
  }
  delGift(rq: CmGiftReq): Promise<CmGiftRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delGift(_rq!)
      },
      def,
      this.isGiftDef,
    )
  }

  /** GiftModel */
  isGiftModelDef = false
  findGiftModel(rq: CmGiftModelFindReq): Promise<CmGiftModelRsRes> {
    let def = NewOk([{}])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findGiftModel(_rq!)
      },
      def,
      false,
    )
  }
  getGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getGiftModel(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }
  addGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addGiftModel(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }
  putGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putGiftModel(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }
  delGiftModel(rq: CmGiftModelReq): Promise<CmGiftModelRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delGiftModel(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }

  defWashPods: CmoWashPod[] = DefWashPods // [{id:'1',title:'1111'},{id:'2',title:'1111'}];
  defCattle: CmoWashPod = DefWashPods[0] //{id:'1',title:'1111'};
  /** WashPod */
  isWashPodDef = false
  async findWashPod(rq: CmWashPodFindReq): Promise<CmWashPodRsRes> {
    let def = NewOk([{}])
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findWashPod(_rq!)
      },
      def,
      false,
    )
  }
  async getWashPod(rq: CmWashPodReq): Promise<CmWashPodRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getWashPod(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }

  async addWashPod(rq: CmWashPodReq): Promise<CmWashPodRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addWashPod(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }
  async putWashPod(rq: CmWashPodMutReq): Promise<CmWashPodRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putWashPod(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }
  async delWashPod(rq: CmWashPodMutReq): Promise<CmWashPodRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delWashPod(_rq!)
      },
      def,
      this.isGiftModelDef,
    )
  }

  /* AUTH ***************************** */
  async loginByToken(rq: LoginReq): Promise<LoginRes> {
    let auth: CmoAuthToken = { token: '11' }
    let def = NewOk(auth)
    let isDef = false

    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.loginByToken(_rq!)
      },
      def,
      isDef,
    )
  }

  async login(rq: LoginReq): Promise<LoginRes> {
    let auth: CmoAuthToken = { token: '11' }
    let def = NewOk(auth)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.login(_rq!)
      },
      def,
    )
  }
  async logout(rq: LogoutReq): Promise<LogoutRes> {
    let r = new LogoutRes()
    let def = r
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.logout(_rq!)
      },
      def,
    )
  }

  /* User ****************/
  async reqUserDup(rq: UserDupReq): Promise<RzBoolRes> {
    let def = NewOk(true)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.logout(_rq!)
      },
      def,
    )
  }

  defUser: CmoUser = {
    id: '1',
    name: 'test',
    username: 'mytest1',
    phone: '111',
    email: '3333@test.com',
  }

  async regUser(rq: UserRegReq): Promise<UserRes> {
    let r = new CmoUser()
    r.id = '1'
    r.name = rq.data?.name
    r.username = rq.data?.username
    r.phone = rq.data?.phone

    // let isFail=false;
    // if(this.isDefOn && isFail) return NewFail('no data');
    if (rzIs.d) rzlog.debug('cmRepoMem.regUser :req=', rq)
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.regUser(_rq!)
      },
      def,
      false,
    )
  }

  async sendAuthSms(rq: AuthSmsReq): Promise<AuthSmsRes> {
    let r = { isAllowed: true, phone: rq.phone, phoneToken: '111222' }
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.sendAuthSms(_rq!)
      },
      def,
    )
  }

  isUserDef = false
  async getUser(rq: UserReq): Promise<UserRes> {
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getUser(_rq!)
      },
      def,
      this.isUserDef,
    )
  }
  async findUser(rq: UserReq): Promise<UserRes> {
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findUser(_rq!)
      },
      def,
      this.isUserDef,
    )
  }
  async findUserRs(rq: UserReq): Promise<UserRsRes> {
    let r: CmoUser[] = [{}]
    let def = NewOk(r)

    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findUserRs(_rq!)
      },
      def,
      this.isUserDef,
    )
  }
  async addUser(rq: UserReq): Promise<UserRes> {
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addUser(_rq!)
      },
      def,
      this.isUserDef,
    )
  }
  async putUser(rq: UserReq): Promise<UserRes> {
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putUser(_rq!)
      },
      def,
      this.isUserDef,
    )
  }

  async delUser(rq: UserReq): Promise<UserRes> {
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delUser(_rq!)
      },
      def,
      this.isUserDef,
    )
  }

  async putUserPwd(rq: UserReq): Promise<UserRes> {
    let def = NewOk(this.defUser)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putUserPwd(_rq!)
      },
      def,
      this.isUserDef,
    )
  }

  /* CAR ************/
  isCarDef = false
  defCar = DefCar
  //  defCar={id:'10',title:'',regNo:'10두 1234'} as CmoCar
  async findCar(rq: CmCarFindReq): Promise<CmCarRsRes> {
    let r: CmoCar[] = this.defCar
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findCar(_rq!)
      },
      def,
      this.isCarDef,
    )
  }

  async getCar(rq: CmCarReq): Promise<CmCarRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getCar(_rq!)
      },
      def,
      this.isCarDef,
    )
  }

  async addCar(rq: CmCarReq): Promise<CmCarRes> {
    let r: CmoCar = this.defCar[0]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addCar(_rq!)
      },
      def,
      this.isCarDef,
    )
  }

  async putCar(rq: CmCarReq): Promise<CmCarRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putCar(_rq!)
      },
      def,
      this.isCarDef,
    )
  }
  async delCar(rq: CmCarReq): Promise<CmCarRes> {
    let def = NewOk({})
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delCar(_rq!)
      },
      def,
      this.isCarDef,
    )
  }

  /******************
   * Shop
   */
  isDefShop = false
  async getShop(rq: CmShopReq): Promise<CmShopRes> {
    let r: CmoShop = this.defShops[0]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getShop(_rq!)
      },
      def,
      this.isDefShop,
    )
  }
  async addShop(rq: CmShopReq): Promise<CmShopRes> {
    let r: CmoShop = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addShop(_rq!)
      },
      def,
      this.isDefShop,
    )
  }
  async putShop(rq: CmShopReq): Promise<CmShopRes> {
    let r: CmoShop = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putShop(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async addShopInfo(rq: CmShopInfoMutReq): Promise<CmShopInfoRes> {
    let r: CmoShopInfo[] = []
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addShopInfo(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async putShopInfo(rq: CmShopInfoMutReq): Promise<CmShopInfoRes> {
    let r: CmoShopInfo[] = []
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putShopInfo(_rq!)
      },
      def,
      this.isDefShop,
    )
  }
  async delShop(rq: CmShopReq): Promise<CmShopRes> {
    let r: CmoShop = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delShop(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  defShops = DefShops

  async findShop(rq: CmShopFindReq): Promise<CmShopRsRes> {
    let r: CmoShop[] = this.defShops
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findShop(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async findReview(rq: CmReviewFindReq): Promise<CmReviewRsRes> {
    let r: CmoShop[] = this.defShops
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findReview(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async findShopEquip(rq: CmShopEquipFindReq): Promise<CmShopEquipRes> {
    let r: CmoShopEquip[] = []
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findShopEquip(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async addShopEquip(rq: CmShopEquipMutReq): Promise<CmShopEquipRes> {
    let r: CmoShopEquip[] = []
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addShopEquip(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async putShopEquip(rq: CmShopEquipMutReq): Promise<CmShopEquipRes> {
    let r: CmoShopEquip[] = []
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putShopEquip(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  async delShopEquip(rq: CmShopEquipMutReq): Promise<CmShopEquipRes> {
    let r: CmoShopEquip[] = []
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delShopEquip(_rq!)
      },
      def,
      this.isDefShop,
    )
  }

  /******************
   * Wash
   */
  isDefWash = false
  async getWash(rq: CmWashReq): Promise<CmWashRes> {
    let r: CmoWash = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getWash(_rq!)
      },
      def,
      this.isDefWash,
    )
  }
  async addWash(rq: CmWashReq): Promise<CmWashRes> {
    let r: CmoWash = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addWash(_rq!)
      },
      def,
      this.isDefWash,
    )
  }
  async putWash(rq: CmWashReq): Promise<CmWashRes> {
    let r: CmoWash = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putWash(_rq!)
      },
      def,
      this.isDefWash,
    )
  }
  async delWash(rq: CmWashReq): Promise<CmWashRes> {
    let r: CmoWash = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delWash(_rq!)
      },
      def,
      this.isDefWash,
    )
  }

  async findWash(rq: CmWashFindReq): Promise<CmWashRsRes> {
    let r: CmoWash[] = [{}]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findWash(_rq!)
      },
      def,
      this.isDefWash,
    )
  }

  /* WashCourseCourse *********** */
  isDefWashCourse = false
  async getWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r: CmoWashCourse = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getWashCourse(_rq!)
      },
      def,
      this.isDefWashCourse,
    )
  }
  async addWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r: CmoWashCourse = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addWashCourse(_rq!)
      },
      def,
      this.isDefWashCourse,
    )
  }
  async putWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r: CmoWashCourse = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putWashCourse(_rq!)
      },
      def,
      this.isDefWashCourse,
    )
  }
  async delWashCourse(rq: CmWashCourseReq): Promise<CmWashCourseRes> {
    let r: CmoWashCourse = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delWashCourse(_rq!)
      },
      def,
      this.isDefWashCourse,
    )
  }

  async findWashCourse(rq: CmWashCourseFindReq): Promise<CmWashCourseRsRes> {
    let r: CmoWashCourse[] = [{}]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findWashCourse(_rq!)
      },
      def,
      this.isDefWashCourse,
    )
  }

  /* WashOpt *********** */
  isDefWashOpt = false
  async getWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
    let r: CmoWashOpt = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getWashOpt(_rq!)
      },
      def,
      this.isDefWashOpt,
    )
  }
  async addWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
    let r: CmoWashOpt = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addWashOpt(_rq!)
      },
      def,
      this.isDefWashOpt,
    )
  }
  async putWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
    let r: CmoWashOpt = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putWashOpt(_rq!)
      },
      def,
      this.isDefWashOpt,
    )
  }
  async delWashOpt(rq: CmWashOptReq): Promise<CmWashOptRes> {
    let r: CmoWashOpt = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delWashOpt(_rq!)
      },
      def,
      this.isDefWashOpt,
    )
  }

  async findWashOpt(rq: CmWashOptFindReq): Promise<CmWashOptRsRes> {
    let r: CmoWashOpt[] = [{}]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findWashOpt(_rq!)
      },
      def,
      this.isDefWashOpt,
    )
  }
  /* Settle *********** */
  isDefSettle = false
  async getSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r: CmoSettle = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getSettle(_rq!)
      },
      def,
      this.isDefSettle,
    )
  }
  async addSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r: CmoSettle = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addSettle(_rq!)
      },
      def,
      this.isDefSettle,
    )
  }
  async putSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r: CmoSettle = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putSettle(_rq!)
      },
      def,
      this.isDefSettle,
    )
  }
  async delSettle(rq: CmSettleReq): Promise<CmSettleRes> {
    let r: CmoSettle = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delSettle(_rq!)
      },
      def,
      this.isDefSettle,
    )
  }

  async findSettle(rq: CmSettleFindReq): Promise<CmSettleRsRes> {
    let r: CmoSettle[] = [{}]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findSettle(_rq!)
      },
      def,
      this.isDefSettle,
    )
  }

  /* SettleRow *********** */
  isDefSettleRow = false
  async getSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r: CmoSettleRow = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getSettleRow(_rq!)
      },
      def,
      this.isDefSettleRow,
    )
  }
  async addSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r: CmoSettleRow = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addSettleRow(_rq!)
      },
      def,
      this.isDefSettleRow,
    )
  }
  async putSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r: CmoSettleRow = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putSettleRow(_rq!)
      },
      def,
      this.isDefSettleRow,
    )
  }
  async delSettleRow(rq: CmSettleRowReq): Promise<CmSettleRowRes> {
    let r: CmoSettleRow = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delSettleRow(_rq!)
      },
      def,
      this.isDefSettleRow,
    )
  }

  async findSettleRow(rq: CmSettleRowFindReq): Promise<CmSettleRowRsRes> {
    let r: CmoSettleRow[] = [{}]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findSettleRow(_rq!)
      },
      def,
      this.isDefSettleRow,
    )
  }

  /*********
   *
   **/
  isDefStat = false
  async getStat(rq: CmStatReq): Promise<CmStatRes> {
    let r: CmoStat = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.getStat(_rq!)
      },
      def,
      this.isDefStat,
    )
  }
  async addStat(rq: CmStatReq): Promise<CmStatRes> {
    let r: CmoStat = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.addStat(_rq!)
      },
      def,
      this.isDefStat,
    )
  }
  async putStat(rq: CmStatReq): Promise<CmStatRes> {
    let r: CmoStat = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.putStat(_rq!)
      },
      def,
      this.isDefStat,
    )
  }
  async delStat(rq: CmStatReq): Promise<CmStatRes> {
    let r: CmoStat = {}
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.delStat(_rq!)
      },
      def,
      this.isDefStat,
    )
  }

  async findStat(rq: CmStatFindReq): Promise<CmStatRsRes> {
    let r: CmoStat[] = [{}]
    let def = NewOk(r)
    return this.callRepo(
      this.rmtRepo!,
      rq,
      (rp, _rq) => {
        return rp.findStat(_rq!)
      },
      def,
      this.isDefStat,
    )
  }
} //class
