import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  ApolloError,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from 'apollo-utilities'
import axios from 'axios'
import {
  DEF_TOKEN,
  DEF_USERINFO,
  NewFail,
  NewOk,
  RzRes,
  RzRsRes,
  rzlog,
} from './inc'
import { ApolloLink, DocumentNode, split } from '@apollo/client/link/core'
import { storage } from './rzstore'
import { DEF_API_URL, DEF_GQL_URL, DEF_WS_URL } from '../consts'

const rzIs = rzlog.makeDefs()
export const RzClient_setDbg = rzIs.setDbg

/*********** */
export interface RzClientConf {
  gqlUrl?: string
  wsUrl?: string
  apiUrl?: string
  storeTokenKey?: string
  storeUserKey?: string
  defaultToken?: string
  isWsOn?: boolean
}

const newWsLink = (conf?: RzClientConf) => {
  const getTkn = (): string => {
    let tkn = storage.getAttr(DEF_TOKEN)
    return tkn || ''
    //@ return storage.getItem(conf?.storeTokenKey||DEF_TOKEN) || conf?.defaultToken||'';
  }

  return new GraphQLWsLink(
    createClient({
      url: conf?.wsUrl || DEF_WS_URL, //'ws://localhost:3001/graphql',
      connectionParams: () => ({
        authorization: `Bearer ${getTkn()}`,
      }),
    }),
  )
}

const newHttpLink = (conf?: RzClientConf) => {
  return new HttpLink({
    uri: conf?.gqlUrl || DEF_GQL_URL,
  })
}

const newSplitLink = (conf?: RzClientConf) => {
  let httpLink = newHttpLink(conf)
  let wsLink = newWsLink(conf)

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )
}

const getTkn = (conf?: any): string => {
  let tkn = storage.getAttr(DEF_TOKEN)
  //rzlog.debug("getTkn: tkn=",tkn);
  return tkn || ''
}

function newMiddleLink(conf?: RzClientConf) {
  var r = new ApolloLink((operation, forward) => {
    var hd = {
      Authorization: 'Bearer ' + getTkn(conf),
    }

    operation.setContext({
      headers: hd,
    })
    return forward(operation)
  })
  return r
}

export class RzClient {
  client?: ApolloClient<NormalizedCacheObject>
  conf?: RzClientConf
  token?: string

  constructor(conf?: RzClientConf) {
    this.conf = conf
    this.token = getTkn(conf)
    this.initConf(conf)
  }

  initConf(conf?: RzClientConf) {
    const middleLink = newMiddleLink(conf)
    // let links = (conf?.isWsOn)? [ middleLink, newSplitLink(conf ),    ]
    //     : [ middleLink,  newHttpLink(conf), ]

    let links = [middleLink, newHttpLink(conf)]

    let client = new ApolloClient({
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
      },
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link: ApolloLink.from(links),
      connectToDevTools: true,

      //... ((conf?.isWsOn==false)?{disableWebSocket:false}:{})
    })
    client.disableNetworkFetches = true
    return client
  }

  getApiClient() {
    let otkn = getTkn(this.conf)

    if (rzIs.d)
      rzlog.debug(
        '>>>>>>>>>>>>> getTkn Token : oldTkn=',
        otkn,
        ',conf=',
        this.conf,
      )
    //if(this.token!== otkn)
    this.client = this.initConf(this.conf)
    return this.client!
  }

  async query(q: DocumentNode, req?: any, reqFn?: string): Promise<RzRes<any>> {
    if (rzIs.d) rzlog.debug('reqFn=', reqFn, ', vars=', req)

    try {
      const { data } = await this.getApiClient().query({
        query: q,
        variables: { req: req },
      })

      let dt = reqFn && data && data[reqFn] ? data[reqFn] : data
      //if(dt.data) dt=dt.data;

      if (rzIs.d) rzlog.debug('reqFn=', reqFn, ', data=', dt)
      return dt as RzRes<any>
    } catch (e) {
      this.procError(e)
      rzlog.debug('query:', q)
      return NewFail('' + (reqFn || '') + ':' + e)
    }
  }
  async queryRs(
    q: DocumentNode,
    req?: any,
    reqFn?: string,
  ): Promise<RzRsRes<any>> {
    if (rzIs.d) rzlog.debug('reqFn=', reqFn, ', vars=', req)

    try {
      const { data } = await this.getApiClient().query({
        query: q,
        variables: { req: req },
      })

      let dt = reqFn && data && data[reqFn] ? data[reqFn] : data
      //if(dt.data) dt=dt.data;

      //        if(rzIs.d)
      rzlog.debug('reqFn=', reqFn, ', data=', dt)
      return dt as RzRsRes<any>
    } catch (e) {
      this.procError(e)
      rzlog.debug('query:', q)
      return NewFail('' + (reqFn || '') + ':' + e)
    }
  }

  async mutate(
    m: DocumentNode,
    req?: any,
    reqFn?: string,
  ): Promise<RzRes<any>> {
    if (rzIs.d) rzlog.debug('reqFn=', reqFn, ', req=', req)

    let r = { loading: false, error: Error('unkown'), data: null }

    try {
      const tr = await this.getApiClient().mutate({
        mutation: m,
        variables: { req: req },
      })

      let { data } = tr
      let dt = reqFn && data && data[reqFn] ? data[reqFn] : data
      // if(dt.data) dt=dt.data;
      if (rzIs.d) rzlog.debug('>>>>>> reset : mutate - dt=', dt)
      return dt as RzRes<any>
    } catch (e) {
      this.procError(e)
      rzlog.debug('mutate:', m)
      return NewFail('' + (reqFn || '') + ':' + e)
    }
  }

  procError(e: any) {
    //err : 'name', 'graphQLErrors', 'clientErrors', 'networkError', 'message', 'extraInfo'
    //if(rzIs.d)
    rzlog.debug(
      'Gql Error:',
      e.clientErrors,
      ';',
      e.graphQLErrors,
      ',net=',
      e.networkError,
      ',nm=',
      e.name,
    )

    let tokenErr = false
    if (e.name && e.name === 'ApolloError') {
      if (e.networkError) {
        if (('' + e.networkError).indexOf('403') > 0) tokenErr = true
      }
    }
    rzlog.error('RzClient.Gql Error: e=', e.code, ',msg=', e.message)
    //alert('GraphQl Error :'+e)
    //r.error=e;

    if ((e.message && e.message.indexOf('Unauthorized') >= 0) || tokenErr) {
      this.logout()

      rzlog.error('session expired ============================')
      //window.location='/'
      //window.location.reload()
    }
  }

  async subscribe(
    q: DocumentNode,
    vars: any,
    reqFn: string,
    cbFn: (dt: any) => void,
  ) {
    if (rzIs.d) rzlog.debug('reqFn=', reqFn, ', vars=', vars)

    try {
      const observ = this.getApiClient().subscribe({
        query: q,
        variables: vars,
      })
      observ.subscribe({
        next: (data: any) => {
          let dt =
            reqFn && data && data.data && data.data[reqFn]
              ? data.data[reqFn]
              : data
          if (rzIs.d) rzlog.debug('subscrib.onNext : data=', data, 'dt=', dt)
          cbFn(dt)
        },
      })
    } catch (e) {
      this.procError(e)
    }
  }

  logout() {
    storage.removeItem(this.conf?.storeTokenKey || DEF_TOKEN)
    storage.removeItem(this.conf?.storeUserKey || DEF_USERINFO)
  }

  saveUser(token: string, userInfo: any) {
    storage.setItem(this.conf?.storeTokenKey || DEF_TOKEN, token)
    storage.setItem(this.conf?.storeUserKey || DEF_USERINFO, userInfo)
  }

  getUserInfo() {
    let uinfo = storage.getItem(this.conf?.storeUserKey || DEF_USERINFO)
    // rzlog.debug('userInfo=',uinfo)
    return uinfo
  }

  isLogin() {
    let ctx = storage.getCtx()
    return ctx?.userInfo ? true : false
    //@return storage.getItem(this.conf?.storeTokenKey||'TOKEN') ? true:false
  }

  async uploadFile(fileObj: any, isPrivate?: boolean, uri?: string) {
    let turl = (this.conf?.apiUrl || DEF_API_URL) + (uri || 'upload')

    const formData = new FormData()
    formData.append('file', fileObj)
    if (rzIs.d) rzlog.debug('turl=', turl)

    let tkn = getTkn() //storage.getItem(this.conf?.storeTokenKey||'TOKEN') ||  this.conf?.defaultToken ||''
    if (rzIs.d) rzlog.debug('uploadFile : tkn=', tkn)
    let authTok = isPrivate ? { Authorization: 'Bearer ' + tkn } : {}

    let r = await axios.post(turl, formData, {
      headers: {
        'Content-Type': 'application/octet-stream',
        ...authTok,
      },
    })
    //if(rzIs.d)
    rzlog.debug('upload : r.data=', r.data)
    if (r.data?.data) return r.data
    return r.data
  }
} //class

let _rzClient: RzClient | null = null

export function rzClient_init(opt?: any) {
  _rzClient = new RzClient(opt)
  return _rzClient
}

export function rzClient_get(opt?: any, ctx?: any) {
  if (!_rzClient) return rzClient_init(opt)
  return _rzClient
}

export const rzClient = rzClient_get
export const importRzClient = rzClient_get
