import {
  Platform,
  ViewStyle,
  StyleProp,
  SafeAreaView,
  TextStyle,
  Text,
  StyleSheet,
  RzTbColDef,
  RzTbPage
} from './react-components'
//import { Platform, ViewStyle, StyleProp, PressableStateCallbackType, SafeAreaView, TextStyle, Text, FlatListProps, StyleSheet } from "react-native";
import React, { Component, useState } from 'react'
//import { View,Button,FlatList } from 'react-native';
import { View, Button, FlatList } from './react-components'
import { rzlog } from '../rzcmn/rzlog'
import { RzCmpProp } from './rzcmp'
import { RzView } from './rzview'

/***************/
const rzIs = rzlog.makeDefs()

//export type RzDrawCb=(pr: any, ix: number | undefined) => React.ReactNode;
export type RzDrawCb = (pr: any, ix: number | undefined) => JSX.Element
export type RzDrawType = RzDrawCb
// | React.ReactNode
/***************
 *
 */
export interface RzFlatListProp extends RzCmpProp {
  children?: React.ReactNode

  headEl?: React.ReactNode
  bottomEl?: React.ReactNode
  noItems?: React.ReactNode

  listStyle?: ViewStyle
  cols?:string|string[]|RzTbColDef[];
  tableHeadStyle?:ViewStyle;

  items?: any[]
  itemCount?: number
  isLoading?: boolean
  drawItem?: RzDrawType

  toKey?: (it?: any, ix?: number) => any
  onNextMore?: () => void
  nextThreshold?: number

  onRefresh?: () => void
  refreshing?: boolean
  
  page?: RzTbPage
  headChecked?:boolean
  isMobile?:boolean

  loading?: boolean
}

export class RzFlatList extends Component<RzFlatListProp> {
  public render(): React.ReactNode {
    let styles = this.props.style;
    let hasNoItem = Boolean(this.props.noItems) &&
        (this.props.items == undefined || this.props.items.length === 0)

    return (
      <RzView
            style={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              borderTopWidth: 0,
              ...styles,
            }} >
        {this.props.headEl && this.props.headEl}

        {hasNoItem && this.props.noItems}

        {!hasNoItem && (
          <FlatList
            headStyle={this.props.tableHeadStyle}
            cols={this.props.cols}
            data={this.props.items}
            keyExtractor={this.props.toKey}
            onEndReached={this.props.onNextMore}
            onEndReachedThreshold={this.props.nextThreshold}
            refreshing={this.props.refreshing}
            onRefresh={this.props.onRefresh}
            ListHeaderComponent={() =>
              this.props.items && this.props.items?.length > 0 ? null : (
                <Text>The list is empty</Text>
              )
            }
            renderItem={({ item, index }) => {
              if (this.props.drawItem)
                return this.props.drawItem(item, index) as JSX.Element
              return <View>{item}</View>
            }}
            page={this.props.page}
            headChecked={this.props.headChecked}
            isMobile={this.props.isMobile}
            loading={this.props.loading}
          />
        )}
      </RzView>
    )
  }
}
