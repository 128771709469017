import React, { Component } from 'react';
// import {   Animated, Image, ImageSourcePropType, ImageStyle, ScrollView, StyleSheet, ViewStyle} from "react-native";
// import { PanGestureHandler } from 'react-native-gesture-handler';
import { PanGestureHandler,  Animated, Image, ImageSourcePropType, ImageStyle, ScrollView, StyleSheet, ViewStyle} from "./react-components";


import { RzCol, RzRow, RzView } from './rzview';

export interface RzImgProp{
    source? :string|any;
    src? :string|any;
   
    style?:ImageStyle;
	onPress?: () => void
}

export class RzImg extends Component<RzImgProp> { 
	public render(): JSX.Element { 
        let src=this.props.source||this.props.src;
        return (<Image  style={this.props.style} source={src} onPress={this.props.onPress}/>);
	}
}







/*****************
 * 
 * 
 */

export interface RzIndiProp {
	color?:string;
	selColor?:string;

	count?:number;
	selected?:number;
	size?:number; 
	selSize?:number;
	margin?:number;
	style?:ViewStyle;
}

export class RzIndi extends Component<RzIndiProp> {
	public render(): React.ReactNode {
		let arrs= new Array(this.props.count||4).fill(1)
		//rzlog.debug('ARR++++++++++++++++++++',arrs)
		let sel=this.props.selected||0
		let clr=this.props.color||'#F47704'
		let selClr=this.props.selColor||'#F47704'
		let selSize=this.props.selSize||19
		let size=this.props.size||6
		let mg=this.props.margin||8

		return (
			<RzRow style={{height:20, width:'100%',justifyContent:'center', ...this.props.style}}>
				{arrs.map((t,ix)=>{
					//rzlog.debug("xxxxxx: ix=",ix)
					return (<RzCol style={{width:ix===sel?selSize:size, height:size, 
						borderRadius:100, borderWidth:0, 
							backgroundColor: ix===sel?selClr:clr, marginRight:mg}}/>)
				})}
			</RzRow>
		)
	}
}

/*****************
 * 
 * 
 * 
 * 
 */

export interface  RzBanner{
    uri?:string
}
 interface RzBannerProp {
	banners?:RzBanner[]
    width:number;
    height:number;
}

const styles = {
	square: {
	  width: 150,
	  height: 150,
	  backgroundColor: '#28b5b5',
	  marginTop: 22,
	},
  };


export class RzBannerSlot extends Component<RzBannerProp> { 
	    translateX = new Animated.Value(0);
  	    translateY = new Animated.Value(0);

		public render() { 

			// let images= [
			// 	"https://source.unsplash.com/1024x768/?nature",
			// 	"https://source.unsplash.com/1024x768/?water",
			// 	"https://source.unsplash.com/1024x768/?girl",
			// 	"https://source.unsplash.com/1024x768/?tree", // Network image
			//   ]
			// return (<SliderBox images={images} />)

			//rzlog.debug('WsBannerSlot : bnn.len=', this.props.banners?.length)
			
			let images=this.props.banners;
			//rzlog.debug('WsBannerSlot : img=', img)
			const onTouchStart=(e:any)=>{
				//rzlog.debug('TouchStart:x=',e.translateX)

			}
			const onTouchEnd=(e:any)=>{
				//rzlog.debug('TouchEnd:',e)
			}
			let cnt=images?.length
			return (
				<RzView style={{height:140}}> 
					<ScrollView horizontal={true}  style={{height:140}}
							onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} >
								{images?.map((t,ix)=>(<RzImg key={'wmbn-'+ix} style={{ width:this.props.width,height:this.props.height, resizeMode: 'stretch'}} source={t}/>))}
								{/* <RzImg style={{ width:300,height:140, resizeMode: 'stretch'}} source={img}/>
								<RzImg style={{  width:300,height:140, resizeMode: 'stretch'}} source={img}/> */}
						
					</ScrollView>
					<RzIndi 	selColor={'#4D93FD'} color='#ffffff' size={10} selSize={20} count={cnt}
								style={{ position:'absolute', marginLeft:20, top:120, borderWidth:0, borderColor:'red',
					 					justifyContent:'flex-start',					height:10,width:'100%'}} />
 
				</RzView>
			)
		}
		

		doRenader(){return( <PanGestureHandler onGestureEvent={this.onPanGestureEvent}>
				<Animated.View
							style={[
								styles.square,
									{
										transform: [
										{
											translateX: this.translateX,
										},
										{
											translateY: this.translateY,
										},
										],
									},
							]}
						/>

			</PanGestureHandler>)
		}


		onPanGestureEvent = Animated.event(
					[	{
							nativeEvent: {
							translationX: this.translateX,
							translationY: this.translateY,
							},
						},
					],
				{ useNativeDriver: true }
		);

}//class

 
