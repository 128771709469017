//import { StyleSheet  } from "react-native";

export enum RzStyleEnum {
    TXT,
    TXT_IN,
    BTN,
    SEL,

    LB,
    LBCMP,
    LBTXT,
    LBTXT_IN,
    LBBTN,
}

 



function toCamel(k:string){
    let pos=0
    let ix=k.indexOf('-',pos);
    if(ix<0) return k
    let r=''
    let tv=k.substring(pos,ix);
    r+=tv;

    do{
            pos=ix+1;
            ix=k.indexOf('-',pos);
            if(ix<0){ 
                    tv=k.substring(pos);
                    tv= tv.charAt(0).toUpperCase() + tv.slice(1);

                    r+=tv;        
                    break;
            }

            tv=k.substring(pos,ix);
            tv= tv.charAt(0).toUpperCase() + tv.slice(1);

            r+=tv;

    }while(ix>=0);
    //rzlog.debug('toCamel:r=',r);
    return r;
}
function toVal(v:string,vtype?:string){
    let r:any= v!==undefined ? v.trim():null;
    if(r){
            if("string" === vtype) r=v;
            else if(r.indexOf('em')>=0) r=parseInt(r.replace('em',''));
            else if(r.indexOf('px')>=0) r=parseInt(r.replace('px',''));
            else if(vtype==='num') r=parseInt(r);
            else if(vtype==='float') r=parseFloat(r);
            else if(r.indexOf('\'')>=0) r=r.replaceAll("'","");
    }
    return r;
}

export function toStyles(cssStr:string){
    let vs=cssStr.split(';');
    let r:any={}
    vs.forEach(v=>{
            if(v==undefined || v.length===0) return 
            let tv=v.split(':')
            
            let k=tv[0];
            if(k!==undefined) k=k.trim();
            if(k==undefined || k.length===0) return 
            let nk=toCamel(k)
            //rzlog.debug('toStyles:r=',k,',v=',tv[1]);

            let vtype='';
            if(k==="flex")vtype='num';
            else if(k==='flex-shrink')vtype='num';
            else if(k==='flex-grow')vtype='num';
            else if(k==='shadow-opacity') vtype='float';
            else if(k==='elevation') vtype='float';
            else if(k==='shadow-radius') vtype='float'; 
            else if(k==='shadow-offset') vtype='string'; 
            else if(k==='box-shadow') vtype='string'; 
            else if(k==='z-index') vtype='num'; 
            else if(k==='border') vtype='string'; 
            else if(k==='transform') vtype='string'; 
            else if(k==='border-radius') vtype='string'; 
            else if(k==='padding') vtype='string'; 
            
            
            let v0=toVal(tv[1],vtype);
            //if(nk==='fontWeight') v0=parseInt(v0);
            r[nk]=v0;
    })
    //rzlog.debug('toStyles:r=',r);
    return r
}
