import { RzDlg } from 'rzcmnui'

export class Nil {}

export enum RzResEnum {
  UNKNOWN = 1,
  NO_LOGIN = 2,
  NO_EXIST = 3,
}

export class RzRes<T> {
  status?: number
  message?: string
  data?: T
  constructor(st?: number, msg?: string, dt?: T) {
    this.status = st || 0
    this.message = msg || 'OK'
    if (dt) this.data = dt
  }
}

export class RzRsRes<T> {
  status?: number
  message?: string
  total?: number
  page?: RzoPage

  data?: T[]
  constructor(st?: number, msg?: string, dt?: T[]) {
    this.status = st || 0
    this.message = msg || 'OK'
    if (dt) this.data = dt
  }
}

export class RzNilRes extends RzRes<Nil> {}

export class RzReq<T> {
  data?: T
  option?: RzReqOpt
}

export class RzMutReq<T> extends RzReq<T> {}

export interface RzoPage {
  size?: number
  skip?: number
}

export interface RzReqOpt {
  select?: string
  filter?: string
  exclude?: string
  include?: string
  extend?: string
  order?: string
  values?: string
}

/**
    select?:string;     // select='a, b, c, d'
    filter?:string;     // like java : filter ='(a==1&&b==2)||(a=d)'
    exclude?:string;    // ex='detail '
    extend?:string;     // extends='detail, company'
    order?:string;      // order='a desc, b'
    values?:string;     //'a=1;b=c,c="ddd"'
 */
export interface RzFindOpt extends RzReqOpt {}

export class RzFindReq<T> {
  data?: T
  option?: RzFindOpt
  page?: RzoPage
  total?: number
  target?: string
  keyword?: string
}

/********************* */
export type RzErrHdr = (st: number, msg: string) => void

let _defErrHdr: RzErrHdr | null = null

export const RzSetErrHdr = (hdr: RzErrHdr) => {
  _defErrHdr = hdr
}

const callDefHdr = (st: number, msg: string) => {
  if (_defErrHdr) _defErrHdr(st, msg)
}

export function NewOk<T>(dt?: T): RzRes<T> {
  return new RzRes<T>(0, 'OK', dt)
}

export function NewFail<T>(msg?: string, st?: number): RzRes<T> {
  return new RzRes<T>(st || 1, msg || 'unknown error')
}

export function IsFail<T>(r: RzRes<T>, msg?: string, log?: any) {
  if (!r) {
    let m = `${msg || ''} > no res`
    if (log?.error) log.error(m)
    else console.error(`RZERR : ${m}`)
    callDefHdr(1, m)
    return true
  }

  if (r && r.status! > 0) {
    let m = `${msg || ''}`
    if (log?.error) log.error(`${m} > ${r.message}`)
    else console.error(`RZERR : ${m} > ${r.message}`)

    callDefHdr(r.status || 1, m)
    return true
  }

  return false
}

export function IsFailDlg<T>(r: RzRes<T>, msg?: string, log?: any) {
  if (!r) {
    let m = `${msg || ''} > no res`
    if (log?.error) log.error(m)
    else console.error(`RZERR : ${m}`)
    callDefHdr(1, m)

    return true
  }

  if (r && r.status! > 0) {
    let m = `${msg || ''} > ${r.message}`
    if (log?.error) log.error(m)
    else console.error(`RZERR : ${m}`)
    callDefHdr(r.status || 1, m)
    return true
  }

  return false
}

export function IsFailLog<T>(r: RzRes<T>, msg?: string, ...args: any[]) {
  if (!r) {
    let m = `${msg || ''} > no res`
    console.error(`RZERR : ${m}`)
    callDefHdr(1, m)
    return true
  }

  if (r && r.status! > 0) {
    let m = `${msg || ''} > ${r.message}`
    console.error(`RZERR : ${m}`)
    callDefHdr(r.status || 1, m)
    return true
  }

  return false
}

export class RzBoolRes extends RzRes<boolean> {}

export class RzStrRes extends RzRes<string> {}

///import { Dimensions,ScrollView } from 'react-native';

class Dimensions {
  static get(k: string) {
    return { width: 1, height: 1 }
  }
}

const windowWidth = Dimensions.get('window').width

export function useSwipe(
  onSwipeLeft?: any,
  onSwipeRight?: any,
  rangeOffset = 4,
) {
  let firstTouch = 0

  // set user touch start position
  function onTouchStart(e: any) {
    firstTouch = e.nativeEvent.pageX
  }

  // when touch ends check for swipe directions
  function onTouchEnd(e: any) {
    // get touch position and screen size
    const positionX = e.nativeEvent.pageX
    const range = windowWidth / rangeOffset

    // check if position is growing positively and has reached specified range
    if (positionX - firstTouch > range) {
      onSwipeRight && onSwipeRight()
    }
    // check if position is growing negatively and has reached specified range
    else if (firstTouch - positionX > range) {
      onSwipeLeft && onSwipeLeft()
    }
  }

  return { onTouchStart, onTouchEnd }
}

export function rzBindAttrs(obj: any, src: any, fields?: string[]) {
  if (fields) {
    for (let ix in fields) {
      let f = fields[ix]
      let v = src[f]
      if (v) obj[f] = v
    }
  } else Object.assign(obj, src)

  return obj
}
