 
import { rzlog } from "../rzcmn"
import { CmoBbsArticle, CmoBbsReply,  CmoShop,  CmoWashPod, CmoWeather, CmoCar } from "./dto"
import { BbsArticleFindReq } from "./msg";
 

export const DefPastureInfo={ title:'성원세차1', 
addrText:"경기도1 몽몽시 몽몽군 몽몽로", 
addrDetail:'124-4'}

 
 //export const DefLastTask=	{id:'tk1', note:'테스트메모 \nghghhg\ndkjalksf\n', schdAt:new Date()} as CmoTask



 
/************
 * CmoTask
 */
     

 
 /******* */

 export const DefWashPods:CmoWashPod[]=[]

 /********* */
 export const DefArticles:CmoBbsArticle[]=[
    {id:'1',title:'공지사항입니다.', code:'noti'}
 ]

 export const DefArticles_get=(rq:BbsArticleFindReq):CmoBbsArticle[]=>{
   if(rq.data?.code==='notice'){
      return [ {id:'1',title:'공지사항1.', code:'notice', type:'가산 세자장', body:'공지내용입니다1'} ,
               {id:'2',title:'공지사항2.', code:'notice', type:'가산 세자장', body:'공지내용입니다2'}, ] as CmoBbsArticle[]
   } else if(rq.data?.code==='news'){
         return [ {id:'3',title:'여의도 여의나루점', myFavor:true, code:'news', body:'신규 세차기 20대 구비완료'},
         {id:'4',title:'여의도 여의나루점2', myFavor:false, code:'news', body:'신규 세차기 20대 구비완료2'},
         {id:'5',title:'여의도 여의나루점3', myFavor:false, code:'news', body:'신규 세차기 20대 구비완료3'},
      ] as CmoBbsArticle[]

   } else if(rq.data?.code==='event'){
      return [ {id:'3',title:'10월 오픈이벤트',  code:'event', body:'신규 세차기 20대 구비완료'},
            {id:'4',title:'11월 오픈이벤트',  code:'event', body:'신규 세차기 20대 구비완료2'},
            {id:'5',title:'12월 오픈이벤트',  code:'event', body:'신규 세차기 20대 구비완료3'},
         ]  as CmoBbsArticle[]

   } else if(rq.data?.code==='faq'){
      //Alert.alert('ddd fq=',''+rq.data?.code+',ty='+rq.data?.type)
     if(rq.data?.type==='login') {
            return [ {id:'13',title:'로그인 사용법1',  code:'faq', type:'login', body:'로그인 20대 구비완료'},
                     {id:'14',title:'로그인 사용법2',  code:'faq', type:'login', body:'로그인 20대 구비완료2'},
                     {id:'15',title:'로그인 사용법3',  code:'faq', type:'login', body:'로그인 20대 구비완료3'},
               ] as CmoBbsArticle[]
      } else if(rq.data?.type==='season') {
         return [ {id:'23',title:'정기권 사용법1',  code:'faq', type:'season', body:'정기권 20대 구비완료'},
               {id:'24',title:'정기권 사용법2',  code:'faq', type:'season', body:'정기권 20대 구비완료2'},
               {id:'25',title:'정기권 사용법3',  code:'faq', type:'season', body:'정기권 20대 구비완료3'},
         ] as CmoBbsArticle[]
      } else if(rq.data?.type==='pay') {
         return [ {id:'33',title:'정기권 사용법1',  code:'faq', type:'pay', body:'신규 세차기 20대 구비완료'},
               {id:'34',title:'정기권 사용법2',  code:'faq', type:'pay', body:'신규 세차기 20대 구비완료2'},
               {id:'35',title:'정기권 사용법3',  code:'faq', type:'pay', body:'신규 세차기 20대 구비완료3'},
         ] as CmoBbsArticle[]
      } else if(rq.data?.type==='gift') {
         return [ {id:'43',title:'선물 사용법1',  code:'faq', type:'gift', body:'선물 세차기 20대 구비완료'},
               {id:'44',title:'선물 사용법2',  code:'faq', type:'gift', body:'선물 세차기 20대 구비완료2'},
               {id:'45',title:'선물 사용법3',  code:'faq', type:'gift', body:'선물 세차기 20대 구비완료3'},
         ] as CmoBbsArticle[]

      } else if(rq.data?.type==='cash') {
         return [ {id:'43',title:'캐시 사용법1',  code:'faq', type:'gift', body:'캐시 세차기 20대 구비완료'},
               {id:'44',title:'캐시 사용법2',  code:'faq', type:'gift', body:'캐시 세차기 20대 구비완료2'},
               {id:'45',title:'캐시 사용법3',  code:'faq', type:'gift', body:'캐시 세차기 20대 구비완료3'},
         ] as CmoBbsArticle[]

      } else if(rq.data?.type==='event') {
         return [ {id:'53',title:'이벤트 사용법1',  code:'faq', type:'event', body:'이벤트 세차기 20대 구비완료'},
               {id:'54',title:'이벤트 사용법2',  code:'faq', type:'event', body:'이벤트 세차기 20대 구비완료2'},
               {id:'55',title:'이벤트 사용법3',  code:'faq', type:'event', body:'이벤트 세차기 20대 구비완료3'},
         ] as CmoBbsArticle[]

      } else {
         return [ {id:'43',title:'선물 사용법1',  code:'faq', type:'gift', body:'선물 세차기 20대 구비완료'},
               {id:'44',title:'선물 사용법2',  code:'faq', type:'gift', body:'선물 세차기 20대 구비완료2'},
               {id:'45',title:'선물 사용법3',  code:'faq', type:'gift', body:'선물 세차기 20대 구비완료3'},
         ] as CmoBbsArticle[]
      }

     
   } 
   return []  as CmoBbsArticle[]
 }//method


export const DefShops
    = [ {  id:'1', loc:'홍대', title:'홍대점', workTime:'10:00-23:00', addrText:'서울시 홍대', 
           capa:100, phone:'1112223333', myFavor:true, 
           score:40, scoreCount:10, podCount:10, inPodCount:3, 
           imageUrl:'https://reactnative.dev/img/tiny_logo.png',
           reviews:[{id:'1',subType:'거품세차/하부세차', writerName:'테스1', 
                     title:'홍대점',body:'방문할때마다', score:10 }, 
                     {id:'2',subType:'거품세차/하부세차', writerName:'테스2', 
                     title:'홍대점',body:'방문할때마다', score:20 }
                  
                  ],
           workStat:{   isWork:true, waitCount:10,rsvCount:10} as CmoShop},

        {  id:'2', loc:'구로', title:'구로점', workTime:'10:00-23:00', addrText:'서울시 금천구 사산동',
           capa:100, phone:'1112223333', myFavor:false, 
           score:30, scoreCount:10, podCount:20,inPodCount:5, 
           workStat:{ waitCount:10,  isWork:false,  rsvCount:10},}] as CmoShop[]

 

 export const DefBanners:CmoBbsArticle[]=[]
 export const DefReplies:CmoBbsReply[]=[]

 export const DefWeather:CmoWeather={}
 export const DefCar:CmoCar[]=[]