export * from '../rzcmn/';

export interface RzInProp{
	id?:string;
	size?:"sm"|"md"|"lg";
	required?:boolean;
	className?:string;
	onChangeText?:(txt:string)=>void;
	onSubmit?:()=>void;
}


export const rzHasDisp=true;

export const DEF_TOKEN='token'
export const DEF_REFRESHTOKEN='refreshToken'
export const DEF_TOKENEXPIREDAT='tokenExpiredAt'
export const DEF_USERINFO='userInfo'
