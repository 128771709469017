import React, { Component } from 'react';
import {RzAlert,View, Text, ViewStyle, TextStyle,   StyleSheet } from "./react-components";
import { rzlog } from './inc';
import { RzBtn } from './rzbtn';
import { RzSelItem } from './rzsel';
import { toStyles } from './rzstyles';
import { RzView } from './rzview';

export interface RzDlgProp {
  children?: React.ReactNode
  style?: ViewStyle
}

export class RzDlg extends Component<RzDlgProp> {
  render(): React.ReactNode {
    return <RzView style={this.props.style}>{this.props.children}</RzView>
  }

  static showDlg(title: string) {
    RzAlert.alert(title)
  }

  static showDlgOk(title:string, onOk:(v?:any)=>void){
      let answer = window.confirm(title);
      if(onOk)onOk(answer);
  }
}

/****************************** */
const st_pop_btn = toStyles(`
	height:50px; 
	justify-content:center;
	padding-left:20px;
	
`)
const st_popdlg_btn_txt = toStyles(`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */


color: #555555;
`)

/********************* */

export interface RzPopListProp {
  tid?: string
  style?: ViewStyle
  items?: RzSelItem[]
  selectedId?: string
  visible?: boolean
  onPress?: (it: RzSelItem) => void
  onDraw?: (it: RzSelItem) => React.ReactNode
  onClose?: () => void
  dlgRef?: React.RefObject<View>
}

export class RzPopListDlg extends Component<RzPopListProp> {
  public render(): React.ReactNode {
    let styles = StyleSheet.flatten([
      { position: 'absolute', zIndex: 999 } as ViewStyle,
      this.props.style,
    ])
    let items = this.props.items || [
      { id: '1', title: '판매' } as RzSelItem,
      { id: '2', title: '소통' } as RzSelItem,
    ]

    // return (<FlatList
    // 	data={items}
    // 	renderItem={(it)=>(<View><RzTxt>111</RzTxt></View>)}
    // />)
    if (this.props.visible === false) return <></>
    return (
      <View style={styles}>
        {items && items.map((it, ix) => this.doDraw(it, ix))}
      </View>
    )
  }

  doDraw(it: RzSelItem, ix: number) {
    if (this.props.onDraw) return this.props.onDraw(it)

    // return ( <View><Button title={it.title} onPress={()=>{
    // 	if(this.props.onPress)this.props.onPress(it);
    // }}/></View>)
    let isSelected = this.props.selectedId === it.id
    let sel = isSelected ? { backgroundColor: '#F0F6FF' } : {}

    return (
      <RzBtn
        key={'ix-' + this.props.tid + ix}
        style={{ ...st_pop_btn, ...sel }}
        textStyle={st_popdlg_btn_txt}
        text={it.title}
        onPress={() => {
          //rzlog.debug('OnPress:>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
          if (this.props.onPress) this.props.onPress(it)
          //if(this.props.onClose)this.props.onClose();
        }}
      />
    )
  }
}
