import React, { ReactNode, Component, CSSProperties, createRef } from 'react'
import { rzHasDisp, rzlog } from './inc'
import { RzViewProp } from './rzview'
import './styles/select.css'
import { rzToDateFormat } from './rzfmt'
import { RzCmpProp } from './rzcmp'
import { TextStyle, ViewStyle } from './react-components'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
} from '@coreui/react-pro'
import { RzSelImpProp, RzSelOpProp, TxtInInf, TxtInProp } from './rzcmn-inf'

const rzIs = rzlog.makeDefs()

/*****************
 *
 */

export class RzSelCoImp extends Component<RzSelImpProp> {
  render(): React.ReactNode {
    let phd = this.props.selected?.title || this.props.pholder
    rzlog.debug('RzSelColImp : sel=', this.props.selected, ',phd-', phd)

    let sz = ''
    if (this.props.size == 'sm') sz = 'sm'

    // 23.3.14 select stype 추가
    const st = this.props.style as React.CSSProperties

    return (
      <CDropdown className={this.props.className || ''}>
        <CDropdownToggle
          size={sz === 'sm' ? 'sm' : undefined}
          color="secondary"
          style={{ ...st }}
        >
          {phd}{' '}
        </CDropdownToggle>
        <CDropdownMenu>{this.props.children}</CDropdownMenu>
      </CDropdown>
    )
  }
}

export class RzSelCoOpImp extends Component<RzSelOpProp> {
  render(): React.ReactNode {
    return (
      <CDropdownItem
        style={
          this.props.childCodes === '' || this.props.childCodes
            ? { fontSize: 17, fontWeight: 600 }
            : {}
        }
        // active={this.props.selected}
        onClick={(e) => {
          rzlog.debug('RzSelCoOpImp.Idx=', this.props.index)
          if (this.props.onChange) {
            //if(this.props.index) e.currentTarget['selectedIndex']=this.props.index;

            this.props.onChange(e, this.props.index)
          }
        }}
        value={this.props.value}
      >
        {this.props.children}
        {this.props.childCodes && (
          <>
            {this.props.childCodes.split(',').map((el: any, idx: number) => (
              <p
                style={{ margin: 0, fontSize: 15, fontWeight: 500 }}
                key={Math.random() + idx}
              >
                · {el}
              </p>
            ))}
          </>
        )}
      </CDropdownItem>
    )
  }
}

/**************
 *
 */

export class TxtInCoImp extends Component<TxtInProp> implements TxtInInf {
  setValue(v: string) {
    if (this.inputElRef.current) this.inputElRef.current.value = v
  }
  inputElRef = createRef<HTMLInputElement>()
  render() {
    let { onKeyDown } = this.props
    return <CFormInput ref={this.inputElRef} {...this.props} />
  }
}
