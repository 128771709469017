import React, {
  ReactNode,
  Component,
  CSSProperties,
  createRef,
  HTMLInputTypeAttribute,
} from 'react'
import { rzHasDisp, rzlog } from './inc'
import { RzViewProp } from './rzview'
import './styles/select.css'
import { rzToDateFormat } from './rzfmt'
import { RzCmpProp } from './rzcmp'
import { RzSelCoImp, RzSelCoOpImp, TxtInCoImp } from './react-core'
import {
  RzSelImpProp,
  RzSelOpProp,
  SelectProp,
  TxtInInf,
  TxtInProp,
} from './rzcmn-inf'
import { RzChk } from './rztxt'
import { toStyles } from './rzstyles'
import { CSpinner } from '@coreui/react-pro'

const rzIs = rzlog.makeDefs()

/******************* */

//@import { Keyboard, KeyboardAvoidingView, TouchableWithoutFeedback,Platform, ViewStyle, StyleProp, PressableStateCallbackType, SafeAreaView, TextStyle, StyleSheet, ScrollView, ScrollViewProps } from "react-native";

export type FlexAlignType =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
export type FlexDirectionType = 'column' | 'row'

/****************************** */
//React.CSSProperties

export interface ViewStyle {
  top?: number
  left?: number
  right?: number
  bottom?: number

  position?: string
  zIndex?: number
  flexBasis?: string
  flexDirection?: FlexDirectionType
  flexShrink?: number
  alignItems?: FlexAlignType
  alignSelf?: FlexAlignType
  justifyContent?: FlexAlignType
  width?: number | string
  height?: number | string
  color?: string
  flex?: number
  backgroundColor?: string
  borderWidth?: number | string
  borderRightWidth?: number | string
  borderTopWidth?: number | string
  borderBottomWidth?: number | string
  borderColor?: string
  borderRadius?: number
  padding?: number | string
  marginLeft?: number | string
  marginRight?: number | string
  marginBottom?: number | string
  marginTop?: number | string
  paddingLeft?: number | string
  paddingRight?: number | string
  paddingBottom?: number | string
  paddingTop?: number | string

  margin?: number | string
  textIndent?: number | string
  background?: string
  borderStyle?: string
  display?: string
  boxShadow?: string
  cursor?: string
  overflow?: string
  opacity?: number
  outline?: string
  border?: string
  flexGrow?: number
  textAlign?: string
  verticalAlign?: string
  lineBreak?: string
}

export interface ViewProp {
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  children?: ReactNode
  className?: string
}

export class View extends Component<ViewProp> {
  constructor(pr: any) {
    super(pr)
  }

  public render(): ReactNode {
    let st: React.CSSProperties = { ...this.props.style } as React.CSSProperties

    return (
      <div style={st} className={this.props.className}>
        {this.props.children}
      </div>
    )
  }
}

/****************************** */
//import { View, Text, ViewStyle, Image,ImageStyle, TextStyle, StyleProp, StyleSheet, TouchableOpacity} from "react-native";
export interface ImageProp {
  source?: any | string
  style?: ImageStyle
  onPress?: (e: any) => void
}

export class Image extends Component<ImageProp> {
  render(): ReactNode {
    let src = this.props.source ? this.props.source : ''
    let st: React.CSSProperties = { ...this.props.style } as React.CSSProperties
    return <img src={src} style={st} onClick={this.props.onPress} />
  }
}
export interface ButtonProp {
  title?: string
  style?: ViewStyle
  onPress?: (e: any) => void
}
export class Button extends Component<ButtonProp> {
  render(): ReactNode {
    let st: React.CSSProperties = { ...this.props.style } as React.CSSProperties
    return (
      <button style={st} type="button" onClick={this.props.onPress}>
        {this.props.title}
      </button>
    )
  }
}

/*************
 *
 */
//export interface StyleProp<T> {}

type Falsy = undefined | null | false
interface RecursiveArray<T>
  extends Array<T | ReadonlyArray<T> | RecursiveArray<T>> {}
type RegisteredStyle<T> = number & { __registeredStyleBrand: T }
//export type StyleProp<T> = T | RegisteredStyle<T> | RecursiveArray<T | RegisteredStyle<T> | Falsy> | Falsy;
export type StyleProp<T> = T

/*************/

export interface TextStyle {
  textDecorationLine?: string
  backgroundColor?: string
  color?: string
  fontSize?: any
}

export interface TextProp {
  style?: TextStyle
  children?: ReactNode
  text?: string
}
//Text, ViewStyle, TextStyle, StyleProp, Button,
//     Image, ImageSourcePropType, ImageStyle, TouchableNativeFeedback,
//     TouchableWithoutFeedbackProps, TouchableOpacity, TouchableHighlight

export class Text extends Component<TextProp> {
  constructor(pr?: any) {
    super(pr)
  }

  public render(): ReactNode {
    let st: React.CSSProperties = {
      ...this.props.style,
      fontFamily: 'Spoqa Han Sans Neo',
      fontStyle: 'normal',
    } as React.CSSProperties
    return <p style={st}>{this.props.children} </p>
  }
}

/***** */
export interface CheckBoxProp extends RzCmpProp {
  checked?: boolean
  onChange?: () => void
}

export class CheckBox extends Component<CheckBoxProp> {
  constructor(pr?: any) {
    super(pr)
  }

  public render(): ReactNode {
    let { style, ...pr } = this.props
    let st: React.CSSProperties = { ...style } as React.CSSProperties

    return (
      <label>
        <input
          type="checkbox"
          onChange={this.props.onChange}
          style={st}
          {...pr}
        />
        {this.props.text}
      </label>
    )
  }
}

/***** */
interface TouchableOpacityProp extends ViewProp {
  onPress?: (e: any) => void
}

export class TouchableOpacity extends Component<TouchableOpacityProp> {
  public render(): ReactNode {
    let st: React.CSSProperties = { ...this.props.style } as React.CSSProperties
    return (
      <div style={st} onClick={this.props.onPress}>
        {this.props.children}
      </div>
    )
  }
}

export interface ImageSourcePropType {
  uri?: string
}

export interface ImageStyle {}
/***************** */
export interface ScrollViewProp extends ViewProp {
  horizontal?: boolean

  onScroll?: (e: any) => void
  onTouchStart?: (e: any) => void
  onTouchEnd?: (e: any) => void
}

// React.HTMLAttributes<HTMLDivElement>.style?: React.CSSProperties

export class ScrollView extends Component<ScrollViewProp> {
  scrollDivRef = createRef<HTMLDivElement>()

  public scrollToLeft(left: number) {
    if (this.scrollDivRef.current) this.scrollDivRef.current.scrollLeft = left
  }

  public render(): ReactNode {
    let st: React.CSSProperties = {
      overflow: 'scroll',
      flexDirection: 'column',
      ...this.props.style,
    } as React.CSSProperties
    if (this.props.horizontal)
      st = {
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollDirection: 'horizontal',
        flexDirection: 'row',
        ...this.props.style,
      } as React.CSSProperties
    return (
      <div ref={this.scrollDivRef} style={st}>
        {this.props.children}
      </div>
    )
  }
}

/****************** */
export class StyleSheet {
  static flatten(a?: any, v?: any): ViewStyle {
    let r = { ...(a ? a : {}), ...(v ? v : {}) }
    return {}
  }
}

export class RzAlert {
  static alert(msg?: string) {
    alert(msg)
  }
}

/************ */
export interface KeyboardAvoidingViewProp extends ViewProp {
  behavior?: string
}
export class KeyboardAvoidingView extends Component<KeyboardAvoidingViewProp> {}

export interface TouchableWithoutFeedbackViewProp extends ViewProp {
  onPress?: (e: any) => void
}

export class TouchableWithoutFeedback extends Component<TouchableWithoutFeedbackViewProp> {}

export class SafeAreaView extends Component<ViewProp> {}

/************ */

export class Platform {
  static OS = ''
}
export class Keyboard {
  static dismiss = () => {}
}

/*************** */

// style={{chevron:{borderWidth:0}}}
// onValueChange={(v:any,ix:number)=>{
//     if(this.props.onChange) this.props.onChange(v,ix);
// } }

// items={this.props.items||[]}

interface RNPickerSelectStyleProp {
  chevron?: StyleProp<ViewStyle>
}

export interface RNPickerSelectProp extends Omit<ViewProp, 'style'> {
  style?: RNPickerSelectStyleProp
  onValueChange: (v: any, ix: number) => void
  items?: any[]
}

export class RNPickerSelect extends Component<RNPickerSelectProp> {}

/************ */
interface ModalProp extends ViewProp {
  animationType?: 'fade'
  transparent?: boolean
  visible?: boolean
}

export class Modal extends Component<ModalProp> {}

/*************
 *
 */

interface FlatListProp {
  headStyle?: ViewStyle
  cols?: string | string[] | RzTbColDef[] //for web
  data?: any[]
  keyExtractor?: (t: any) => any

  loading?: boolean

  onEndReached?: (e: any) => void
  onEndReachedThreshold?: number
  refreshing?: boolean
  onRefresh?: (e: any) => void
  renderItem?: (t: any, i: number) => ReactNode
  ListHeaderComponent?: () => ReactNode

  page?: RzTbPage
  headChecked?: boolean
  isMobile?: boolean
}

type RzFmtFn = (v: any) => string
type RzTbDrawFn = (v?: any, row?: number, col?: number) => React.ReactNode
export interface RzTbColDef {
  id?: string
  title?: string
  type?: string
  fmt?: string | RzFmtFn
  draw?: React.ReactNode | RzTbDrawFn
  size?: number | string

  style?: ViewStyle
  textStyle?: TextStyle

  drawHead?: React.ReactNode | RzTbDrawFn
  headStyle?: ViewStyle
  headTextStyle?: TextStyle
  onChange?: (b?: boolean) => void
  isVisible?: boolean
}

export interface RzTbPage {
  total?: any | number
  skip?: any | number
  size?: any | number
}

const parseHeads = (cols: string) => {
  let tcols = cols.split(',')

  return tcols
}

interface FlatListStat {
  cols?: string[] | RzTbColDef[]
}

export class FlatList extends Component<FlatListProp, FlatListStat> {
  constructor(pr?: any) {
    super(pr)
    this.state = {}
  }

  componentDidMount(): void {
    CoFlatListImp._cnt++
    let thead = this.props.cols
    // rzlog.debug('CmFindListFalt.mount: thead=', thead, ',ty=', typeof thead)
    if ('string' === typeof thead) {
      let cols = parseHeads(thead)
      this.setState({ cols: cols })
    } //else if(thead ) this.setState({cols:thead});
  }

  componentDidUpdate(
    prevProps: Readonly<FlatListProp>,
    prevState: Readonly<FlatListStat>,
    snapshot?: any,
  ): void {
    if (prevProps.cols != this.props.cols) {
      let thead = this.props.cols
      rzlog.debug('CmFindListFalt.update: thead=', thead, ',ty=', typeof thead)
      if ('string' === typeof thead) {
        let cols = parseHeads(thead)
        this.setState({ cols: cols })
      } //else if(thead ) this.setState({cols:thead});
    }
  }

  public render(): React.ReactNode {
    if (!this.props.data) return <div> list not implements</div>
    let { cols, ...rest } = this.props
    if (this.state?.cols) cols = this.state.cols

    return <CoFlatListImp {...rest} cols={cols} />
  }
}

class CoFlatListImp extends Component<FlatListProp> {
  static _cnt = 0
  componentDidMount(): void {
    CoFlatListImp._cnt++
  }

  public render(): React.ReactNode {
    if (!this.props.data) return <div> list not implements</div>
    let data = this.props.data
    let cols: string[] | RzTbColDef[] | undefined = this.props.cols as
      | string[]
      | RzTbColDef[]
      | undefined
    rzlog.debug('FlatListImp : cols=', cols)

    let tbStyle
    if (this.props.isMobile) {
      tbStyle = toStyles(`
          table-layout: fixed;
          width:1024px;
        `)
    }
    return (
      <table className="table" style={tbStyle}>
        {cols && this.drawHead(cols)}
        <tbody>
          {this.props.loading && cols && (
            <tr>
              <td colSpan={cols?.length}>
                <div className="d-flex justify-content-center align-items-center">
                  <CSpinner color="primary" />
                </div>
              </td>
            </tr>
          )}

          {!this.props.loading &&
            data.map((t, ix) => {
              if (this.props.page) ix = this.props.page.skip + ix
              return this.drawRow(t, ix, cols)
            })}
        </tbody>
      </table>
    )
  }

  drawHead(cols: string[] | RzTbColDef[]) {
    let st = { ...this.props.headStyle } as React.CSSProperties
    return (
      <thead style={st}>
        <tr>
          {cols &&
            cols.map((t, ix) => {
              // rzlog.debug('drwaHd : t=', t, ',ty=', typeof t)
              if ('string' === typeof t)
                return (
                  <th key={'tb' + CoFlatListImp._cnt + '.hd' + ix} scope="col">
                    {t}
                  </th>
                )

              let { headStyle: hdSt, fmt } = t as RzTbColDef
              let tst = {
                ...hdSt,
                fontFamily: 'Spoqa Han Sans Neo',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: 15,
              } as React.CSSProperties
              if (fmt === '$row') tst = { ...tst, textAlign: 'center' }

              if (t.title === 'sel') {
                tst = { ...tst, textAlign: 'center', width: '50px' }
                return (
                  <th key={'tb' + CoFlatListImp._cnt + '.hd' + ix} style={tst}>
                    <RzChk
                      checked={this.props.headChecked}
                      onChange={t.onChange}
                    />
                  </th>
                )
              }

              return (
                <th key={'tb' + CoFlatListImp._cnt + '.hd' + ix} style={tst}>
                  {t.title}
                </th>
              )
            })}
        </tr>
      </thead>
    )
  }

  drawRow(v: any, row: number, cols?: any[]): React.ReactNode {
    if (cols) {
      return (
        <tr key={'tb-' + CoFlatListImp._cnt + '.r' + row}>
          {cols.map((t, ix) => {
            let k = 'tb-' + CoFlatListImp._cnt + '.r' + row + '.c' + ix
            // rzlog.debug('Cols.ix=', ix, ',t=', t)
            let st
            if (v.reportStyle && t.title === '리뷰일시') {
              st = v.reportStyle
            } else {
              st = t.style || {}
            }
            if (t?.fmt === '$row') st = { ...st, textAlign: 'center' }
            if (t?.draw) st = { ...st, textAlign: 'center', width: '50px' }
            st = {
              fontFamily: 'Spoqa Han Sans Neo',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: 14,
              ...st,
            }

            if ('string' === typeof t) return <td key={k}>{v}</td>
            if (t.onClick)
              return (
                <td key={k} style={st} onClick={() => t.onClick(v)}>
                  {toVal(t, v, row)}
                </td>
              )
            return (
              <td key={k} style={st}>
                {toVal(t, v, row)}
              </td>
            )
          })}
        </tr>
      )
    }
    return (
      <tr key={'tb-' + CoFlatListImp._cnt + '.r' + row}>
        <td>{'' + v}</td>
      </tr>
    )
  }
}

const fmtVal = (v: any, fmt: string, row?: number) => {
  if (!v) return ''
  if (fmt === '$row') {
    if (row !== undefined) return row + 1
    else return '1'
  }
  if (fmt === 'd') return parseInt(v)
  if (fmt.startsWith('@date=')) {
    let dfmt = fmt.substring(6)
    // 현재 gmt로 계산되는데 gmt 계산이 맞다면 v를 그대로 넘기고 전달받은 값 그대로 보여주려면 아래 항목으로
    //let dv = v.substring(0, v.length-1);
    return rzToDateFormat(dfmt, v)
  }

  return '' + v
}

const toVal = (colDef: any, v: any, row?: number) => {
  if (colDef?.draw) return colDef.draw(v, row)

  let tv = v
  if (colDef?.id) tv = v[colDef.id]
  if (colDef?.fmt && 'string' === typeof colDef.fmt) {
    tv = fmtVal(tv, colDef.fmt, row)
  }
  if (colDef?.fmt && 'string' !== typeof colDef.fmt) {
    let fmtCb = colDef.fmt as RzFmtFn
    tv = fmtCb(tv)
    return tv
  }
  if (tv === undefined || tv == null) return ''
  // rzlog.debug('ColDef=', colDef)
  return '' + tv
}

/***************** */
export namespace Animated {
  export class Value {
    constructor(v: number) {}
  }

  interface AniViewProp {}

  interface InViewProp extends Omit<ViewProp, 'style'> {
    style?: ViewProp | AniViewProp
  }

  export class View extends Component<InViewProp> {}

  export function event(vs: any[], t?: any): (e: any) => void {
    return (e: any) => {}
  }
} //class

/*********
 *
 */
//import {PanGestureHandler  } from 'react-native-gesture-handler';

export interface PanGestureHandlerProp extends ViewProp {
  onGestureEvent?: (e: any) => void
}

export class PanGestureHandler extends Component<PanGestureHandlerProp> {}

/*************
 *
 */

class TxtInSmpImp extends Component<TxtInProp> implements TxtInInf {
  setValue(v: string) {
    if (this.inputElRef.current) this.inputElRef.current.value = v
  }
  inputElRef = createRef<HTMLInputElement>()
  render() {
    return <input ref={this.inputElRef} {...this.props} />
  }
}

//const TxtInImp=TxtInSmpImp;

const TxtInImp = TxtInCoImp

/**************
 *
 * !! Important!!!!
 *
 * - TextInputProp.style => TextStyle
 * - outbox.style => viewStyle
 */

export interface TextInputProp extends ViewProp {
  editable?: boolean
  maxlength?: number
  keybordType?: string
  multiline?: boolean
  viewStyle?: ViewStyle
  placeholder?: string
  text?: string
  defaultText?: string
  disabled?: boolean
  type?: string
  onChangeText?: (txt: string) => void
  onSubmit?: () => void
  onFocus?: (e?: any) => void
}

interface TextInputStat {
  text?: string
  defaultText?: string
}

export class TextInput extends Component<TextInputProp, TextInputStat> {
  constructor(pr: any) {
    super(pr)
    this.state = {}
  }

  componentDidMount(): void {
    this.setState({
      text: this.props.text,
      defaultText: this.props.defaultText,
    })
  }

  componentDidUpdate(
    prevProps: Readonly<TextInputProp>,
    prevState: Readonly<TextInputStat>,
    snapshot?: any,
  ): void {
    if (this.props.defaultText !== prevProps.defaultText) {
      this.setState({
        text: this.props.text,
        defaultText: this.props.defaultText,
      })
    }
  }

  render(): React.ReactNode {
    // rzlog.debug('TextInput.reader.multilien = ',this.props.multiline)
    if (this.props.multiline) return this.doRenderTextArea()
    else return this.doRender1Line()
  }

  inputElRef = createRef<TxtInInf>()
  textAreaElRef = createRef<HTMLTextAreaElement>()

  clear() {
    if (this.inputElRef.current)
      (this.inputElRef.current as TxtInInf).setValue('') //value=''
    this.setState({ text: '' })
    if (this.textAreaElRef.current) this.textAreaElRef.current.value = ''
  }

  doRenderTextArea() {
    let height = this.props.viewStyle?.height
      ? this.props.viewStyle?.height
      : undefined
    let width = this.props.viewStyle?.width
      ? this.props.viewStyle?.width
      : undefined
    let pholder = this.props.placeholder
    let defaultText = this.props.defaultText
    let maxLength = this.props.maxlength

    let text = this.state.text || defaultText || ''

    if (height) height = 'string' === typeof height ? height : height - 4
    if (width) width = 'string' === typeof width ? width : width - 4
    // rzlog.debug('TextArewa w-',width)

    let st = {
      height: height,
      width: width,
      resize: 'none',
      outline: 'none',
      border: 'none',
    } as CSSProperties
    return (
      <textarea
        ref={this.textAreaElRef}
        value={text}
        style={st}
        placeholder={pholder}
        maxLength={maxLength}
        disabled={this.props.disabled}
        onInput={(e) => {
          if (this.props.onChangeText)
            this.props.onChangeText(e.currentTarget.value)
        }}
      />
    )
  }

  doRender1Line(): React.ReactNode {
    let height = this.props.viewStyle?.height
      ? this.props.viewStyle?.height
      : undefined
    let sheight: string | null = null

    if (height)
      sheight = 'string' === typeof height ? height : height - 2 + 'px'
    let lineHeight = {}

    if (rzHasDisp) {
      lineHeight = sheight ? { lineHeight: sheight } : {}
      let w = this.props.viewStyle?.width
      let sw = null
      if (w) sw = 'string' === typeof w ? w : w - 2 + 'px'

      lineHeight = { ...lineHeight, outline: 'none', width: sw || '98%' }
    }

    let pholder = `
        input::placeholder {
            color: red;
            font-style: italic;
          }        `

    let st = {
      borderWidth: 0,
      marginTop: 0,
      paddingTop: 0,
      ...lineHeight,
      ...this.props.style,
    } as CSSProperties

    let { defaultText, text } = this.props

    let inputType = this.props.keybordType || this.props.type || 'text'
    if (inputType === 'pwd') inputType = 'password'

    // if (this.props.text === undefined) {
    if (this.props.text === undefined || text === '0000' || text === 'NaNNaN') {
      text = this.state.text || defaultText || '' // 고쳐야됨
    } else {
      text = this.props.text
    }

    return (
      <TxtInImp
        ref={this.inputElRef as any}
        style={st}
        type={inputType}
        placeholder={this.props.placeholder}
        {...(this.props.editable == false ? { readOnly: true } : {})}
        value={text || ''}
        disabled={this.props.disabled}
        onKeyDown={(e: any) => {
          if (e.key == 'Enter') {
            // alert('enter')
            if (this.props.onSubmit) {
              this.props.onSubmit()
              e.stopPropagation()
              return
            }
          }
        }}
        onChange={(e) => {
          //for TxtInCoImp

          this.setState({ text: e.currentTarget.value })
          if (this.props.onChangeText)
            this.props.onChangeText(e.currentTarget.value)
        }}
        onFocus={this.props.onFocus}
        onInput={(e: any) => {
          this.setState({ text: e.currentTarget.value })
          if (this.props.onChangeText)
            this.props.onChangeText(e.currentTarget.value)
        }}
        maxLength={this.props.maxlength}
      />
    )
  }

  public blur() {}
} //class

export interface KeyboardTypeOptions {}

/*************
 *
 */

class RzSelSmpImp extends Component<RzSelImpProp> {
  render(): React.ReactNode {
    let { className, style, selected, onChange } = this.props
    //={this.props.className||'rzselect'  } style={st}  value={selItem?.id} onChange

    let st: React.CSSProperties = {
      ...this.props.style,
      ...this.props.textStyle,
    } as React.CSSProperties
    return (
      <select
        className={className}
        style={st}
        value={selected?.id}
        onChange={onChange}
      >
        {this.props.children}
      </select>
    )
  }
}
class RzSelSmpOpImp extends Component<RzSelOpProp> {
  render(): React.ReactNode {
    //let {textStyle}=this.props;
    let txtSt = { ...this.props.textStyle } as React.CSSProperties
    return (
      <option style={txtSt} value={this.props.value}>
        {this.props.children}
      </option>
    )
  }
}

// const RzSelImp=RzSelSmpImp
// const RzSelOpImp=RzSelSmpOpImp;

const RzSelImp = RzSelCoImp
const RzSelOpImp = RzSelCoOpImp

interface SelectStat {
  selectedId?: number
}

let _selId = 0
export class Select extends Component<SelectProp, SelectStat> {
  constructor(pr: any) {
    super(pr)
    this.selId = ++_selId
    this.state = {}
  }
  selId = 0

  render(): React.ReactNode {
    if (this.props.outline === false) return this.renderNoBox()
    else return this.renderBox()
  }

  renderNoBox() {
    return (
      <div className="select">
        <div className="selected">
          <div className="selected-value">none</div>
          <div className="arrow"></div>
        </div>
        <ul>
          <li className="option">none</li>
          <li className="option">option 1</li>
          <li className="option">option 2</li>
          <li className="option">option 3</li>
          <li className="option">loooooooooooooooooong text option</li>
        </ul>
      </div>
    )
  }

  renderBox(): React.ReactNode {
    let oitems = this.props.items || []
    let pitems = this.props.placeholder ? [this.props.placeholder] : []
    let isCore = true
    let items = isCore ? [...oitems] : [...pitems, ...oitems]
    let selItem = this.props.selected

    let k = 'sel-' + this.selId

    let st = this.props.style
    let txtSt = this.props.textStyle

    let tselId = this.state.selectedId || this.props.selected?.id
    rzlog.debug('renderBod :se-' + this.props.selected)
    return (
      <RzSelImp
        className={this.props.className || 'rzselect'}
        style={st}
        textStyle={txtSt}
        size={this.props.size}
        selected={this.props.selected}
        pholder={this.props.placeholder}
        onChange={(e) => {
          this.doChange(e, oitems)
        }}
      >
        {items.map((t, i) => {
          let id = t?.id ? t.id : t
          let v = t?.title ? t.title : t.constructor == Object ? '' : t
          // rzlog.debug(' SeldID=', tselId, ',id=', id, ',tselId', tselId)
          return (
            <RzSelOpImp
              childCodes={t.childCodes}
              textStyle={txtSt}
              key={k + '-' + i}
              index={i}
              value={id}
              selected={id === tselId}
              onChange={(e, ix) => {
                this.doChange(e, oitems, ix)
              }}
            >
              {v}
            </RzSelOpImp>
          )
        })}
      </RzSelImp>
    )
  }

  doChange(e: any, items: any[], ix?: number) {
    //rzlog.debug('doChange.e=', e, ',ix=', ix)

    let tit = ix !== undefined ? items[ix] : null

    if (ix === undefined) {
      let its = items.filter((t) => t.id === e.currentTarget.value)
      tit = its.length > 0 ? its[0] : undefined
      rzlog.debug(
        'doChange.Ites=',
        its,
        ',tit=',
        tit,
        ',e.curTg.val=',
        e.currentTarget.value,
      )
    }

    if (tit !== undefined) this.setState({ selectedId: tit.id })
    if (!this.props.onChange) return

    if (ix === undefined)
      this.props.onChange(tit, e.currentTarget.selectedIndex)
    else this.props.onChange(tit, ix)
  }
} //class
